.category-container {
  padding: 100px 0;
  .col-xl-2 {
    padding-right: 5px;
    padding-left: 5px;
  }
  .category-item {
    height: 160px;
    background: $white;
    box-shadow: 0 2px 14px 0 $boxshadow;
    margin: 19px 0;
    padding: 23px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    transition: all 0.3s ease-in-out;
    padding: 10px;
    &:hover {
      cursor: pointer;
      box-shadow: 0 2px 14px 0 $hover-boxshadow;
    }
    .category-title {
      font-family: "Montserrat";
      font-weight: 400;
      font-size: 18px;
      color: $dark;
      text-align: center;
      line-height: 27px;
      padding-top: 10px;
    }
    .category-img {
      width: 60px;
    }
  }
}

// Source Old
/* =============================================================== */
/* CATEGORY INTRO
/* =============================================================== */
.search-row-wrapper {
  background: url(/assets/images/bg.jpg) center;
  background-position: center;
  background-size: cover;
  height: auto;
  padding: 50px 0;
  transition: all 400ms cubic-bezier(0.25, 0.1, 0.25, 1) 0s;
  width: 100%;
  .btn-primary {
    line-height: 22px;
  }
}

@media screen and (min-width: 1024px) {
  /* FIXED IE OVERFLOW ISSUE */
  .search-row-wrapper {
    height: 140px;
    display: block;
  }
}

/* =============================================================== */
/* CATEGORY LIST
/* =============================================================== */

.mobile-filter-bar .dropdown-menu {
  > li {
    padding: 0px 18px;
    line-height: 28px;
    a {
      font-size: 11px;
      color: #333;
    }
  }
}

.col-thin-left {
  padding-left: 15px;
}

.col-thin-right {
  padding-right: 15px;
}

.adds-block {
  background-color: #fff;
  clear: both;
  display: block;
  height: auto;
  overflow: auto;
  width: 100%;
}

.item-list {
  border-bottom: $border;
  clear: both;
  padding: 10px;
  height: auto;
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  transition: background 0.25s ease-in;
  -webkit-transition: background 0.25s ease-in;
  border: 1px solid #c0c0c0 !important;
  border-radius: 5px !important;
  .row {
    margin: 0;
  }
}

.item-list:hover {
  background: #f6f6f5;
  cursor: pointer;
}

.adds-block .item-list:last-child {
  border-bottom: none;
}

.category-list {
  border-radius: 3px 3px;
  display: block;
  .adds-block {
    margin: 30px 0px 0px 0px !important;
    padding: 0px !important;
    border-radius: 5px !important;
  }
}

.add-image {
  position: relative;
}

.photo-count {
  background: #ccc;
  border: 0 none;
  border-radius: 2px;
  font-size: 12px;
  opacity: 0.75;
  padding: 0 3px;
  position: absolute;
  right: 4px;
  top: 5px;
}

.add-image a {
  display: block;
}

.add-image a img {
  width: 100%;
}

.add-title {
  padding-bottom: 7px;
}

.ads-details a {
  font-weight: bold;
}

.info-row {
  display: block;
  clear: both;
  font-size: 12px;
  color: #9a9a9a;
}

.business-ads {
  background: none repeat scroll 0 0 #dfdfdf;
  border: 1px solid #d1d1d1;
  border-radius: 50%;
  display: inline-block;
  height: 25px;
  line-height: 18px;
  padding: 3px;
  text-align: center;
  width: 25px;
}

.item-list {
  .btn-danger.make-favorite {
    color: #fff;
    text-transform: uppercase;
    transition: all 0.25s ease;
    font-family: $base-font-condensed;
  }
  .make-favorite span {
    width: 0;
    font-size: 0;
    transition: all 0.25s ease;
  }
  &:hover .make-favorite span {
    width: auto;
    font-size: 12px;
  }
}

.price-box {
  padding: 0;
}

@media (max-width: 991px) {
  .item-list .make-favorite {
    padding: 3px 5px;
  }
}

@media (max-width: 767px) {
  .price-box {
    clear: both;
    display: block;
    height: auto;
    padding: 10px 15px 0;
    text-align: left !important;
    width: 100%;
  }

  .item-list.make-list {
    text-align: left;
  }

  .item-list .ads-details {
    padding: 10px 0 0;
  }

  .item-list.make-compact .make-favorite {
    float: left;
    margin-left: 10px;
  }
}

/* List View Grid View Comact View */

.item-list.make-compact .photobox,
.item-list.make-compact .info-row .category {
  display: none;
}

.item-list.make-compact .add-title {
  float: left;
  clear: left;
  height: 19px;
  overflow: hidden;
  padding-bottom: 7px;
  width: 60%;
}

.item-list.make-compact .info-row {
  display: block;
  float: left;
  clear: none;
  font-size: 12px;
  width: 40%;
}

.item-list.make-compact h2.item-price {
  line-height: 20px;
}

@media (max-width: 1199px) {
  .item-list.make-compact .add-title,
  .item-list.make-compact .info-row {
    width: 100%;
  }

  .ads-details {
    padding: 0;
  }
}
.item-list {
  &.make-compact {
    .item-price {
      float: left;
      font-size: 20px;
      line-height: normal;
      margin-bottom: 0;
      padding-bottom: 0;
    }
    .make-favorite span {
      font-size: 0;
      transition: all 0.3s ease 0s;
      width: 0;
    }
  }
  &.make-list,
  &.make-compact {
    height: auto !important;
  }
  &.make-grid {
    width: 100%;
    .make-favorite {
      margin-bottom: 5px;
    }
  }
}

/* LIST */

/* GRID */

.adds-block .item-list.make-grid:nth-child(4n + 4) {
  border-right: none;
}

@media (max-width: 991px) {
  .item-list.make-grid {
    width: 33.33%;
  }

  .adds-block .item-list.make-grid:nth-child(4n + 4) {
    border-right: $border;
  }

  .adds-block .item-list.make-grid:nth-child(3n + 3) {
    border-right: none;
  }
}

@media (max-width: 778px) {
  .item-list.make-grid {
    width: 33.33%;
  }
}

@media (max-width: 540px) {
  .item-list.make-grid {
    width: 50%;
    border-right: $border !important;
  }

  .adds-block .item-list.make-grid:nth-child(2n + 2) {
    border-right: none !important;
  }
}

@media (max-width: 360px) {
  .item-list.make-grid {
    width: 100%;
    border-right: none;
  }
}

.item-list.make-grid .photobox,
.item-list.make-grid .add-desc-box,
.item-list.make-grid .price-box {
  width: 100%;
  clear: both;
  display: block !important;
  flex: 100%;
  max-width: 100%;
  .item-price {
    .item_price {
      .price-number {
        font-weight: 700 !important;
        font-size: 15px !important;
      }
      .text-red {
        .price-number {
          font-weight: 600 !important;
          font-size: 13px !important;
        }
      }
    }
  }
}

.item-list.make-grid .add-desc-box {
  padding: 10px 0;
}

.item-list.make-grid .price-box {
  text-align: left;
  padding-left: 0;
  padding-right: 0;
}

/* pagination */

.pagination-bar {
  margin-top: 35px;
  display: block;
}

.pagination-bar .pagination {
  margin-top: 0;
}

.pagination-bar .pagination li {
  margin: 0 5px;
}

.pagination-bar .pagination li a {
  color: #9a9a9a;
  border: none !important;
  background-color: transparent !important;
  border-radius: 100%;
}

.pagination-bar .pagination li:first-child a {
  border-radius: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #fff !important;
  border-radius: 100%;
  border: 1px solid #c8c8c8 !important;
  cursor: pointer;
  .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.pagination-bar .pagination li:last-child a {
  border-radius: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #fff !important;
  border-radius: 100%;
  border: 1px solid #c8c8c8 !important;
  cursor: pointer;
  .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.pagination-bar .pagination li a:hover {
  color: #000000;
}

.pagination-bar .pagination li:last-child a {
  color: #414141;
}

.pagination-bar .pagination li.active a {
  background-color: #f8f8f8;
  color: #000000;
  border-color: #cfcfcf;
  cursor: default;
  font-weight: bold;
}

/* post-promo  */

.post-promo {
  margin-top: 15px;
}

.post-promo h2 {
  padding-bottom: 10px;
}

/* Ribbons  */
.cornerRibbons {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  left: -8%;
  overflow: hidden;
  position: absolute;
  top: 20%;
  -ms-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 200px;
  z-index: 2;
  transition: all 0.3s ease 0s;
  min-height: 22px;
  line-height: 20px;
}

.cornerRibbons,
.cornerRibbons a {
  color: #fff;
  display: block;
  font-family: Montserrat Condensed, Helvetica, Arial, Montserrat;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  text-decoration: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
  text-transform: uppercase;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease 0s;
}
.cornerRibbons.text {
  font-size: 9px;
}

.cornerRibbons.topAds {
  background: #16a085;
}

.cornerRibbons.urgentAds {
  background: #cc3300;
}

.cornerRibbons.featured {
  background: #fb8d17;
}

.cornerRibbons.featured a {
  color: #fff;
}

.cornerRibbons.promotion {
  background: #B91617;
}

.cornerRibbons.promotion a {
  color: #fff;
}

.cornerRibbons.expired {
  background: #828282;
}

.cornerRibbons.expired a {
  color: #fff;
}

.item-list.make-grid .cornerRibbons {
  left: -37%;
  top: 7%;
}

.item-list.make-compact .cornerRibbons {
  left: -15%;
  text-indent: -99999px;
  top: 18%;
}

@media (max-width: 991px) {
  .cornerRibbons {
    left: -10%;
    top: 19%;
    width: 170px;
    z-index: 2;
  }

  .cornerRibbons {
    font-size: 11px;
  }

  .item-list.make-compact .cornerRibbons {
    left: -20%;
  }
}

@media (max-width: 767px) {
  .cornerRibbons {
    left: -36px;
    top: 24px;
    width: 160px;
  }

  .item-list.make-grid .cornerRibbons {
    left: -36px;
    top: 24px;
  }

  .cornerRibbons {
    font-size: 11px;
  }

  .item-list.make-compact .cornerRibbons {
    left: -100px;
  }
}

/*************** TAB BOX ***************/

.tab-box {
  position: relative;
  display: flex;
  align-items: center;
  background-color: transparent;
  justify-content: space-between;
  .list-link {
    font-size: 18px;
    .link {
      color: #222222;
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      font-family: Montserrat Condensed, Helvetica, Arial, Montserrat;
      &.active {
        color: #16a085;
      }
    }
  }
}

%extend_add_tabs {
  border-radius: 0;
  padding-bottom: 15px;
  padding-top: 15px;
  margin-right: 0;
  font-size: 13px;
}

.nav-tabs.add-tabs {
  > li {
    &:hover > a {
      background: #ededed;
    }
    > a {
      @extend %extend_add_tabs;
    }
    &.active > a {
      @extend %extend_add_tabs;
      &:hover {
        @extend %extend_add_tabs;
      }
      &:focus {
        @extend %extend_add_tabs;
      }
    }
    &.active > a {
      border-left: $border;
      border-right: $border;
      border-top: 1px solid #e6e6e6;
      background-color: #fff;
      &:hover,
      &:active,
      &:focus {
        border-bottom: solid 1px #fff;
      }
    }
  }
}

@media (max-width: 991px) {
  .nav-tabs .badge {
    font-size: 11px;
  }
}

@media (max-width: 767px) {
  .nav-tabs.add-tabs > li > a {
    font-size: 12px;
  }

  .nav-tabs .badge {
    display: none;
  }

  .select-sort-by .selecter-options {
    font-size: 11px !important;
  }
}

.nav-tabs.add-tabs > li:first-child > a {
  border-left: 1px solid transparent;
}

/* tab filter */

.tab-filter {
  position: absolute;
  right: 0;
  top: 0;
  @include breakpoint($sm-only) {
    display: none;
  }
}

.listing-filter {
  border-bottom: $border;
  padding: 15px;
}

.breadcrumb-list {
  color: #888;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.5;
  margin-bottom: 0;
}

.breadcrumb-list a.current {
  color: #000;
  font-weight: bold;
  margin-right: 5px;
}

.dropdown-menu-modal {
  width: 100%;
  max-width: 400px;
}

.listing-view-action span {
  color: #888;
  cursor: pointer;
}

.listing-view-action span.active {
  color: #000;
}

/* Customize bootstrap-select plugin */

.tab-filter .select-sort-by {
  margin-right: 15px;
  margin-top: 10px;
  min-width: 150px !important;
  @include breakpoint($xs-only) {
    display: none !important;
  }
}

.tab-filter .selecter.bottom .selecter-options {
  bottom: auto !important;
  top: auto;
}

.select-sort-by .selecter-selected {
  font-size: 12px;
  padding: 4.5px 15px;
}

.tab-filter .selecter-item {
  padding: 8px 15px;
  font-size: 12px;
}

/*************** Save Search ****************/

.save-search-bar {
  border-top: $border;
}

.save-search-bar:hover {
  background: #ededed;
}

.save-search-bar a:focus {
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.05) inset;
}

.save-search-bar a {
  display: block;
  font-weight: 500;
  padding: 10px 0;
}

/* =============================================================== */
/* CATEGORY SIDEBAR
/* =============================================================== */
.list-filter {
  width: 100%;
  display: block;
  clear: both;
  .form-inline {
    width: 100%;
  }
  .count {
    color: #9a9a9a;
    font-size: 90%;
  }
  ul {
    li {
      list-style: none;
      a {
        border-radius: 5px;
        display: block;
        padding: 2px 15px 2px 5px;
        position: relative;
        color: #4e575d;
        font-size: 13px;
      }
    }
    ul {
      padding-left: 15px;
    }
  }
}

@include breakpoint($md-max) {
  .hidden-md {
    display: none !important;
  }
  .list-filter {
    ul {
      ul {
        padding-left: 10px;
      }
      li a {
        padding-right: 0;
      }
    }
    .form-inline {
      > div {
        margin-top: 10px;
      }
    }
  }
}

.categories-list {
  .list-title:first-child {
    margin-top: 0 !important;
  }
}

.page-sidebar .list-filter:first-child {
  .list-title {
    margin-top: 0 !important;
  }
}

.nav-filter a.active {
  background-color: #eaeaea;
  color: #333;
}
.list-title {
  color: #222;
  font-size: 18px;
  border-bottom: $border;
  display: block;
  margin-bottom: 10px;
  margin-top: 15px;
  padding: 10px 0;
  position: relative;
  text-transform: uppercase;
  a {
    color: #222;
    font-size: 18px;
  }
  &:before {
    border-color: #ddd transparent transparent;
    border-style: solid;
    border-width: 7px;
    bottom: -14px;
    //Instead of the line below you could use @include box-sizing($bs)
    box-sizing: border-box;
    content: " ";
    display: block;
    left: 34px;
    position: absolute;
    z-index: 2;
  }
  &:after {
    border-color: $white transparent transparent;
    border-style: solid;
    border-width: 6px;
    bottom: -12px;
    //Instead of the line below you could use @include box-sizing($bs)
    box-sizing: border-box;
    content: " ";
    display: block;
    left: 35px;
    position: absolute;
    z-index: 2;
  }
  &.gray:before {
    border-color: transparent transparent transparent;
  }
}

.list-filter {
  form.form-inline {
    margin-top: 5px;
    clear: both;
    display: inline-flex;
    .form-group {
      display: initial;
      margin-bottom: 0;
      @include breakpoint($sm-only) {
        .form-control {
          width: 100%;
        }
      }
    }
  }

  ul li p.maxlist-more a {
    border-radius: 5px;
    display: block;
    font-size: 12px;
    padding: 2px 25px 2px 20px;
    position: relative;
    color: #369;
  }

  .maxlist-more {
    a {
      padding-left: 5px;
      font-weight: 500;
    }
  }
}

/* Price Range */

.form-inline .form-control {
  max-width: 100%;
}

.list li {
  list-style: none;
}

ul.list-link li a,
ul.list-link li {
  color: #4e575d;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  padding: 3px 0;
  transition: all 0.1s ease 0s;
}

ul.list-link li a:hover,
.ul.list-link li a:hover {
  text-decoration: underline;
  color: #345676;
}

ul.list-link li a.active {
  color: #345676;
  font-weight: bold;
}

hr.thin {
  margin-bottom: 10px;
  margin-top: 10px;
}

/* PROPERTY LIST */
.property-list {
  .add-title {
    padding-bottom: 5px;
  }
  .item-price {
    padding-bottom: 7px;
  }
  .info-row {
    line-height: normal;
  }
  .item-list {
    &.make-compact {
      .info-row {
        width: 100%;
      }
      .btn-border-thin {
        display: none;
      }
      .item-price {
        float: none;
      }
    }
    .carousel-control {
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: none;
    }
  }
  .add-image a img {
    border-radius: 0;
    padding: 0;
    width: 100%;
  }
  .make-compact .add-title {
    clear: left;
    float: left;
    height: auto;
    overflow: hidden;
    padding-bottom: 7px;
    width: 100%;
  }

  @include breakpoint($xs-only) {
    .btn-border-thin {
      float: right;
      margin-left: 5px;
    }

    .price-box {
      margin-top: 6px;
    }
  }
}
.prop-info {
  display: block;
  width: 100%;
  clear: both;
  padding: 15px 0 0;
  span {
    &.title {
      font-size: 16px;
      font-weight: 600;
      color: #222;
    }
    &.text {
      font-size: 12px;
      font-family: Montserrat Condensed, Helvetica, Arial, Montserrat;
    }
  }
}
.prop-info-block {
  border-right: 1px solid #ccc;
  color: #666;
  display: table-cell;
  margin: 0;
  min-height: 20px;
  padding: 0 10px 5px;
  float: left;
  text-align: center;
  width: 30%;
  @include breakpoint($xs-only) {
    width: 33.33%;
  }
  &:last-child {
    border-right: 0;
  }
  span {
    display: block;
    line-height: 1.2;
  }
}
.make-grid {
  .prop-info-block {
    border-right: 0;
    color: #666;
    display: inline;
    margin: 0;
    min-height: 0;
    padding: 0;
    text-align: left;
    width: 100%;
    span {
      display: inline;
    }
  }
  .prop-info span.title {
    font-size: 14px;
    &.make-grid .prop-info span.text {
      display: inline;
    }
  }
}
#properites-image-slide {
  @include breakpoint($sm-only) {
    .carousel-item {
      img {
        display: block;
        width: 100%;
      }
    }
  }
}
.carousel-control {
  .icon-next::before {
    content: "";
  }
  .icon-prev.icon-left-open-big:before {
    content: "\e83b";
  }
  .icon-next.icon-right-open-big:before {
    content: "\e83c";
  }
}
@media all and(max-width: 779px) {
  .property-list .make-list .prop-info-block {
    float: left;
  }
}
@media all and(max-width: 480px) {
  .auto-heading {
    display: block;
    padding-right: 0;
  }
  .auto-price {
    display: block;
    padding: 5px 0 10px;
    position: relative;
    right: auto;
    text-align: left;
    top: auto;
    width: 100%;
  }
  .property-list .price-box .tooltip {
    display: none !important;
  }
}
@media all and (max-width: 990px) {
  .prop-info {
    padding-top: 10px;
    span {
      &.title {
        font-size: 14px;
      }
      &.text {
        font-size: 11px;
      }
    }
  }
}
.make-compact .prop-info {
  display: none;
}
.product-view-thumb-wrapper .bx-wrapper .bx-controls-direction a {
  height: auto;
  margin-top: -25px;
}

// cutomize customs select pugin

.nice-select {
  border-radius: 3px;
  .list {
    max-height: 250px;
    overflow-y: auto;
    width: 100%;
  }
  &.form-control {
    font-size: 13px;
    margin: 0;
    padding: 8.5px 15px;
    line-height: 20px;
    height: 40px;
  }
  .option {
    width: 100%;
    background: #ffffff;
    border-bottom: 1px solid #cccccc;
    color: #222222;
    cursor: pointer;
    display: block;
    font-size: 13px;
    margin: 0;
    overflow: hidden;
    padding: 0 15px;
    text-decoration: none;
    text-overflow: ellipsis;
    &.selected {
      background: #eeeeee;
    }
  }
}
.select-sort-by {
  &.selectpicker {
    font-size: 12px;
    padding: 7px 10px;
    height: 30px;
    line-height: normal;
    border: 1px solid #cccccc;
    display: block;
  }
  .list .option {
    font-size: 12px;
    line-height: 34px;
    min-height: 34px;
  }
}

.save-search-bar {
  display: block;
  width: 100%;
}

.bx-wrapper .bx-controls-direction a {
  z-index: 90 !important;
}

// code new
.category-container {
  padding: 100px 0;
  @include breakpoint(678) {
    padding: 50px 0;
  }
  .col-xl-2 {
    padding-right: 5px;
    padding-left: 5px;
  }
  .category-item {
    height: 160px;
    background: $white;
    box-shadow: 0 2px 14px 0 $boxshadow;
    margin: 19px 0;
    padding: 23px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    transition: all 0.3s ease-in-out;
    &:hover {
      cursor: pointer;
      box-shadow: 0 2px 14px 0 $hover-boxshadow;
    }
    .category-title {
      font-family: "Montserrat";
      font-weight: 400;
      font-size: 18px;
      color: $dark;
      text-align: center;
      line-height: 27px;
      padding-top: 10px;
    }
    .category-img {
      width: 60px;
    }
  }
}

.overview-container2 {
  margin-top: 20px;
  .default-view {
    position: relative;
    width: 100%;
    .default-view__checkbox {
      @media (min-width: 992px) {
        display: flex;
        .choose-item:nth-child(2) {
          margin-left: 10px;
        }
      }
    }
    .checkbox {
      label {
        font-size: 13px;
      }
    }
    .ui.inverted.dimmer {
      background-color: transparent !important;
    }
  }
}

.product-container {
  .product-heading {
    .scroll-bar {
      .horizontal-menu {
        .menu-wrapper {
          width: 100% !important;
        }
      }
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-bottom: 10px;
    .scroll-bar {
      margin-top: 5px;
      width: 100% !important;
      .menu-item {
        padding: 0 8px;
        margin: 5px 0px;
        user-select: none;
        font-family: "Montserrat";
        font-weight: 400;
        cursor: pointer;
      }
      .menu-item-wrapper.active {
        .menu-item {
          font-weight: 700;
        }
      }
      .scroll-menu-arrow {
        padding: 0 5px;
        cursor: pointer;
      }
    }
    .collection-scroll {
      width: 55% !important;
    }
    .category-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex: 1;
      overflow: hidden;
      &:after {
        content: "";
        flex: 1 auto;
      }
      .category-link {
        margin: 5px;
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 13px;
        color: $dark;
        letter-spacing: 0;
        cursor: pointer;
        &.category-active {
          font-weight: bold;
        }
      }
    }
    .block-title {
      padding-bottom: 0;
      margin-top: 5px;
      white-space: nowrap;
      margin-right: 10px;
      width: 120px;
      &.title-width-header {
        width: auto !important;
        margin: 0px !important;
        font-weight: 700;
      }
      &.block-title-slider {
        white-space: pre-line;
        width: auto;
      }
      a {
        color: inherit;
        font-size: 18px;
      }
      h4 {
        padding-bottom: 0px !important;
      }
    }
    .btn-circle-primary {
      margin-top: 5px;
      margin-left: 10px;
    }
    .product-breadcrumb {
      display: flex;
      border: 1px solid $border-bussiness;
      border-radius: 24px;
      padding: 6px;
      margin-left: 10px;
      margin-right: auto;
      margin-top: 5px;
      .item {
        margin: 0 5px;
        padding: 0 10px;
        white-space: nowrap;
        span {
          cursor: pointer;
        }
        &:not(:last-child) {
          border-right: 1px solid $border-bussiness;
        }
      }
    }
  }
  .category-content {
    max-height: 300px;
    overflow-y: scroll;
    .category-item {
      padding: 1px 0;
    }
    &::-webkit-scrollbar {
      width: 8px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px unset;
      border-radius: 10px;
      height: 80px;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      opacity: 0.4;
      background-color: $scrollbars;
      border-radius: 3.5px;
      height: 6px;
    }
    .category-list {
      .category-link {
        display: block;
        padding: 8px 18px;
        font-family: "Montserrat";
        font-weight: 500;
        font-weight: 700;
        font-size: 13px;
        color: $dark;
        letter-spacing: 0;
        cursor: pointer;
        &:hover {
          color: $primary;
        }
        &.category-all {
          color: $primary;
        }
      }
    }
  }
  .tab-heading::before {
    position: absolute;
    content: "";
    bottom: -2px;
    left: 0;
    background-color: #131c33;
    height: 3px;
    width: 60px;
  }
  .service-before::before {
    width: 98px !important;
  }
  .product-before::before {
    width: 150px !important;
  }
  .product-store-user::before {
    width: 105px !important;
  }
  .catalog-before::before {
    width: 135px !important;
  }
  .promotion-before::before {
    width: 130px !important;
  }
  .tab-heading {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid #d7d7d7;
    text-transform: uppercase;
    padding-bottom: 15px;
    &.tab-heading-stores {
      .category-list {
        .horizontal-menu {
          width: 100%;
          @include breakpoint(992) {
            margin-bottom: 10px;
          }
        }
      }
      &.menu-store {
        border: 0px;
        padding-bottom: 0px;
      }
      &.menu-store::before {
        background-color: transparent;
      }
    }
    .category-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex: 1;
      width: calc(100% - 300px);
      overflow: hidden;
      .horizontal-menu {
        width: calc(100% - 70px);
      }
      .menu-item {
        @include breakpoint(992) {
          background-color: #b1b1c08f;
          color: white;    
          margin: 5px 5px 0 0;
        }
        border-radius: 12px;
        padding: 0 8px;
        margin: 5px 0;
        user-select: none;
        font-family: "Montserrat";
        font-weight: 400;
        cursor: pointer;
      }
      .menu-item-wrapper.active {
        .menu-item {
          background-color: rgba($color: $heading, $alpha: 0.8);
          color: $white;
          font-weight: 700;
          padding: 2px 8px;
        }
      }
      .scroll-menu-arrow {
        padding: 0 5px;
        cursor: pointer;
      }
      &:after {
        content: "";
        flex: 1 auto;
      }
      .category-link {
        margin: 5px;
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 13px;
        color: $dark;
        letter-spacing: 0;
        cursor: pointer;
        &.category-active {
          font-weight: bold;
        }
      }
    }
    .category-sort {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      margin: 5px;
      .sort-title {
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 13px;
        color: $dark;
        letter-spacing: 0;
        padding-right: 10px;
        white-space: nowrap;
      }
      .category-select {
        min-width: 160px;
        padding: 10px;
        background: url("/assets/images/icons/arrow-1.svg") no-repeat right
          $white;
        -webkit-appearance: none;
        background-position-x: 140px;
        border-radius: 18px;
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 11px;
        color: $dark;
        outline: none;
        font-weight: 700;
      }
    }
  }
}
.adds-block {
  background-color: $white;
  box-shadow: $boxshadow-inner;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.adds-block {
  margin: 0px 0px 30px 0px;
  padding: 10px 0px 5px 0px;
  .row {
    margin: 0;
  }
  .adds-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 5px;
    padding: 8px !important;
    .sub-items {
      display: flex;
      @media screen and (max-width: 991px) and (min-width: 768px) {
        padding-left: 10px;
      }
      @include breakpoint(767) {
        flex-direction: column;
      }
      .sub-item {
        width: 50%;
        a {
          display: flex;
          @media (min-width: 768px) {
            flex-direction: column;
          }
        }
        @include breakpoint(767) {
          width: 100%;
        }
        margin-bottom: 10px;
        margin-right: 10px;
        .item-image {
          width: 50%;
          height: 50px;
          object-fit: cover;
          @include breakpoint(767) {
            width: 30%;
          }
        }
        .item-info {
          color: #000;
          @include breakpoint(767) {
            margin-left: 5px;
          }
          .item-name {
            margin: 0;
            font-size: 10px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            /* autoprefixer: off */
          }
          .adds-price {
            font-size: 9px;
          }
        }
      }
    }
    // a {
    //   overflow: hidden;
    //   display: block;
    // }
    &.adds-list-view {
      flex-direction: row;
      width: 100%;
      justify-content: start;
      padding: 0 20px;
      position: relative !important;
      .adds-content {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
      }
      .adds-norm {
        flex: 1;
        font-family: "Montserrat";
        font-weight: 600;
        color: $dark;
        padding-bottom: 0px;
        text-overflow: unset !important;
        white-space: unset !important;
      }
      .adds-actions {
        width: 100px;
      }
      .group-price {
        width: 160px;
        padding: 0 10px;
        .adds-price {
          font-family: "Montserrat";
          font-weight: 600;
          color: $dark;
          padding-bottom: 0px;
        }
        .adds-price-red {
          color: $danger;
        }
      }
      .list-star {
        width: 130px;
        padding: 0 20px;
      }
      .adds-content {
        .adds-norm {
          padding-top: 5px !important;
          padding-bottom: 5px !important;
        }
      }
    }
    // &.adds-list-view:hover {
    //   background-color: #028ab8;
    //   .adds-content {
    //     .adds-norm {
    //       color: #ffffff !important;
    //     }
    //     .group-price {
    //       .price-item {
    //         .adds-price {
    //           color: #ffffff !important;
    //         }
    //       }
    //     }
    //   }
    //   .adds-actions {
    //     .update-service:nth-child(1) {
    //       border:1px solid #c0c0c0;
    //     }
    //   }
    // }
    &.adds-list-service {
      padding-top: 25px !important;
      .rating-service {
        position: absolute;
        top: 10px;
        left: 10px;
      }
    }
    .adds-images:hover {
      // transform: scale(1.1);
      // overflow: hidden;
      transition: box-shadow .25s;
      box-shadow: 0 2px 2px 0 rgba(0,0,0,.2), 0 4px 15px 0 rgba(0,0,0,.19);
    }
    .adds-images {
      object-fit: cover;
      position: relative;
      width: 100%;
      // overflow: hidden;
      height: 200px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      transition: all .2s ease;
      border: 1px;
      .icon-check {
        width: 20px;
        position: absolute;
        right: 0px;
        top: 0;
        @include breakpoint(600) {
          right: 7px;
        }
      }
      .adds-img {
        height: 100%;
        width: 100%;
        height: 150px;
        border: 1px solid rgba($color: $dark, $alpha: 0.5);
        border-radius: 2px;
        object-fit: cover;
      }
      .icon-gift {
        position: absolute;
        left: -5px;
        top: -5px;
        cursor: pointer;
      }
    }
    .adds-videos-wrapper {
      position: relative;
      width: 100%;
      overflow: hidden;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border: 1px solid rgba($color: $border-bussiness, $alpha: 0.6);
      .play-icon {
          position: absolute;
          display: block;
          background: url("/assets/images/play-btn.png");
          height: 50px;
          width: 50px;
          top: 50%;
          left: 50%;
          background-size: 50px 50px;
          background-repeat: no-repeat;
          transform: translate(-50%, -50%);
      }
      .react-player {
        width: 100% !important;
        height: auto !important;
      }
      .myvideo-list {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
      }
      .adds-img {
        height: 100%;
        width: 100%;
        height: 150px;
        border: 1px solid rgba($color: $dark, $alpha: 0.5);
        border-radius: 2px;
        object-fit: cover;
      }
    }
    .adds-content {
      @media only screen and (min-width: 992px) {
        .btn-order-sm {
          display: none;
        }
      }
      .adds-price {
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 13px;
        color: $dark;
        letter-spacing: 0;
        line-height: 17px;
        padding-bottom: 0;
        .text-red.text-underline {
          color: #f7434c;
          text-decoration: line-through;
          font-size: 12px;
        }
      }
      .adds-norm-store {
        height: 60px;
      }
      .adds-norm {
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 12px;
        color: #000000;
        letter-spacing: 0;
        line-height: 17px;
        padding-bottom: 0px !important;
        padding-top: 5px !important;
        display: block;
        // padding: 5px;
        // height: 40px;
        .store-name {
          font-size: 14px;
        }
        .store-address {
          font-size: 10px;
        }
        b, em {
          display: block;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          /* autoprefixer: off */
        }
        span {
          display: block;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          /* autoprefixer: off */
        }
      }
      .adds-description {
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 12px;
        color: #000000;
        letter-spacing: 0;
        line-height: 17px;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-bottom: 0px !important;
        // padding-top: 10px !important;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        // min-height: 45px;
        // max-height: 45px;
        // padding: 5px;
        font-style: italic !important;
      }
      .adds-name {
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 13px;
        color: #000;
        text-transform: uppercase;
      }
      .adds-info-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .adds-info {
        position: relative;
        padding: 8px 8px 0px 0px;
        text-align: end;
        .adds-price {
          font-family: "Montserrat";
          font-weight: 500;
          font-size: 13px;
          color: $dark;
          letter-spacing: 0;
          line-height: 17px;
          padding-bottom: 0;
          // min-height: 40px !important;
          .text-red.text-underline {
            color: #f7434c;
            text-decoration: line-through;
          }
        }
        .icon-cart {
          position: absolute;
          right: 0;
          bottom: 0px;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          &:hover {
            transform: scale(1.2);
          }
        }
      }
      .adds-actions {
        margin-top: 5px;
        .icon-cart {
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          &:hover {
            transform: scale(1.2);
          }
        }
        .icon-delete {
          margin-left: 5px;
        }
      }
    }
    .btn-order {
      border: 0px;
      color: #fff;
      font-weight: 600;
      background-color: rgb(19, 28, 51) ;
      outline: line;
      width: 30px;
      height: 30px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      -khtml-border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      .icon-order {
        width: 20px !important;
        float: right;
      }
    }
    .btn-order:hover {
      background-color: #434a5e;
    }
    .hover-image {
      // overflow: hidden;
      // max-width: 100%;
    }
    &.adds-user-list-services {
      .adds-content {
        .adds-actions {
          width: auto;
        }
      }
      .update-service:nth-child(1) {
        float: none;
        background-color: #028ab8;
        padding: 5px;
        font-size: 12px;
        margin-right: 10px;
        border: 1px solid #028ab8;
        border-radius: 5px;
        color: #fff;
        font-weight: 500;
        cursor: pointer;
      }
      .update-service:nth-child(1):hover {
        background-color: #131c33;
        color: #fff;
      }
      .update-service:nth-child(2) {
        float: none;
        background-color: #e4e4e4;
        padding: 5px;
        border: 1px solid #d4d4d4;
        border-radius: 5px;
        color: #000;
        font-weight: 500;
        font-size: 12px;
        cursor: pointer;
      }
      .update-service:nth-child(2):hover {
        background-color: #d80123;
        color: #fff;
        border: 0px;
      }
    }
    &.adds-user-services {
      border: 1px solid #c0c0c0;
      .price-item {
        text-align: end;
      }
      .adds-actions {
        margin-top: 15px;
        .update-service:nth-child(1):hover {
          background-color: #131c33;
          color: #fff;
        }
        .update-service:nth-child(1) {
          float: left;
          background-color: #028ab8;
          padding: 5px;
          font-size: 12px;
          border: 1px solid #028ab8;
          border-radius: 5px;
          color: #fff;
          font-weight: 500;
          cursor: pointer;
        }
        .update-service:nth-child(2) {
          float: right;
          background-color: #e4e4e4;
          padding: 5px;
          font-size: 12px;
          border: 1px solid #d4d4d4;
          border-radius: 5px;
          color: #000;
          font-weight: 500;
          cursor: pointer;
        }
        .update-service:nth-child(2):hover {
          background-color: #d80123;
          color: #fff;
          border: 0px;
        }
        .clear {
          clear: both;
        }
      }
    }
  }
  .adds-item-video {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 10px 0;
    .adds-images {
      position: relative;
      width: 100%;
      height: 88.5px;
      overflow: hidden;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border: 1px solid rgba($color: $border-bussiness, $alpha: 0.6);
    }
    .adds-content {
      padding: 8px 10px;
      .adds-norm {
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 9px;
        color: #000000;
        letter-spacing: 0;
        line-height: 17px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .adds-name {
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 10px;
        color: #000;
        text-transform: uppercase;
      }
    }
  }
  @media only screen and (max-width: 375px) {
    .adds-item-video {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin: 10px 0;
      .adds-images {
        position: relative;
        width: 100%;
        height: 142px;
        overflow: hidden;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border: 1px solid rgba($color: $border-bussiness, $alpha: 0.6);
      }
      .adds-content {
        padding: 8px 10px;
        .adds-norm {
          font-family: "Montserrat";
          font-weight: 400;
          font-size: 9px;
          color: #000000;
          letter-spacing: 0;
          line-height: 17px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .adds-name {
          font-family: "Montserrat";
          font-weight: 600;
          font-size: 10px;
          color: #000;
          text-transform: uppercase;
        }
      }
    }
  }
}
.adds__search {
  @include breakpoint(768) {
    .adds-item {
      .adds-images__link {
        width: 40%;
        .adds-images {
          object-fit: cover;
          height: 80px !important;
          min-width: unset;
        }
      }
      .adds-info {
        margin-left: 5px;
        width: 60%;
      }
      .adds-content {
        width: 60%;
        padding-left: 5px !important;
        .adds-norm {
          padding-top: 0 !important;
        }
      }
    }
  }
  .adds-norm {
    font-weight: 500 !important;
    min-height: unset !important;
  }
}

.category-name {
    text-align: center;
    background-color: #bcc0c1;
    padding: 6px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: #000;
    text-transform: uppercase;
    margin-top: 30px;
}

.background-menu {
  position: absolute;
  height: 100%;
  max-height: 500px !important;
  // top: 50%;
  right: 0px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.block-menu-image-list-view {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: 0px !important;

  .adds-list-view {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: 10px 0px 10px 0px;
  }
}