/* =============================================================== */
/* TYPOGRAPHY
/* =============================================================== */

h1, h2, h3, h4, h5, h6 {
  font-family: $base-font-condensed;
  font-weight: normal;
  margin: 0;
  padding-bottom: 15px;
}

h1 {
  font-size: 30px;
  line-height: 35px;
}

h2 {
  font-size: 24px;
  line-height: 28px;
}

h3 {
  font-size: 20px;
  line-height: 24px;
}

h4 {
  font-size: 17px;
  line-height: 20px;
}

h5 {
  font-size: 14px;
  line-height: 20px;
}

h6 {
  font-size: 12px;
  line-height: 20px;
}

@media(max-width: 979px) {
  //tablet
  h1 {
    font-size: 24px;
    line-height: 26px;
  }
  h2 {
    font-size: 20px;
    line-height: 24px;
  }
  h3 {
    font-size: 18px;
    line-height: 20px;
  }
  h4 {
    font-size: 16px;
    line-height: 18px;
  }
  h5 {
    font-size: 14px;
    line-height: 20px;
  }

  h6 {
    font-size: 12px;
    line-height: 20px;
  }

}

@media(max-width: 767px) {
  //mobile
  h1 {
    font-size: 22px;
    line-height: 24px;
  }
  h2 {
    font-size: 18px;
    line-height: 24px;
  }
  h3 {
    font-size: 16px;
    line-height: 18px;
  }
  h4 {
    font-size: 14px;
    line-height: 18px;
  }
  h5 {
    font-size: 12px;
    line-height: 16px;
  }
  h6 {
    font-size: 10px;
    line-height: 14px;
  }

}

// Customs class

.title-2 {
  border-bottom: 1px solid #e6e6e6;
  font-size: 18px;
  margin-bottom: 20px;
  text-transform: uppercase;
  text-align: left;
  span {
    font-weight: lighter;
  }



}

.page-sidebar .inner-box  {
  padding: 20px;
  .title-2 {
    border-bottom: 1px solid #e6e6e6;
    font-size: 18px;
    margin-bottom: 20px;
    text-transform: uppercase;
    text-align: left;
    margin: 0 -20px 20px;
    padding: 0 20px 15px;
    font-weight: 500;
  }
}

.title-5 {
  font-family: $base-font;
  border-bottom: 1px solid #ebebeb;
  color: #080808;
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 20px;
  padding: 0 0 20px;
  text-transform: uppercase;
  text-align: left;
}

