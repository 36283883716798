.list-item-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 120px;
  img {
    opacity: 0;
    visibility: hidden;
  }
  &.image-avatar {
    width: 30px;
    height: 30px;
    float: left;
    margin-right: 5px;
  }
}
.text-share-link{
  margin-top: 5px;
  font-weight: 600;
}
.share-list-ref{
  width: auto;
}
.product-container {
  .adds-block {
    .ads-details a {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .item-table {
      .adds-images {
        background-size: contain !important;
        i {
          position: absolute;
          right: 0;
          bottom: 0;
          font-size: 20px;
          cursor: pointer;
        }
        &:hover {
          transform: unset !important;
        }
      }
    }
  }
  .item-price {
    @include rem('font-size', 17);
    line-height: 1.2;
    .text-red {
      display: unset;
      color: $brand-danger !important;
      @include rem('font-size', 15);
    }
    .text-underline {
      text-decoration: line-through;
    }
  }
}
