.popup-assign-task {
    position: unset !important;
    height: unset !important;
    background-color: #fff;
    padding-top: unset !important;
    max-width: 100%;
    width: 430px;
  }
  .modal-assign-task {
    margin: 16px 0;
    display: flex;
    flex-direction: column;
    height: 600px;
    width: 100%;
    .modal-assign-task__header {
      margin: 0 30px;
      .modal-assign-task__header__title {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .list-staffs-assigned {
      display: flex;
      margin: 16px 30px;
      gap: 8px;
      white-space: nowrap;
      overflow: auto;
      .item-staff-assigned {
        display: flex;
        flex-direction: row;
        background: #e9e6e6aa;
        padding: 3px 5px;
        border-radius: 12px;
        justify-content: start;
        align-items: center;
        gap: 5px;
        .staff-avatar {
          .staff-avatar__img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
          }
        }
        .staff-info {
          display: flex;
          align-items: center;
          .staff-info__fullname {
            font-size: 13px;
          }
          .staff-icon__close {
            color: grey;
            cursor: pointer;
          }
        }
      }
    }
    .container-search-staff {
      border: 1px solid #aaaa;
      border-radius: 12px;
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      padding: 8px;
      margin: 0 30px;
      .staff-icon__search {
        font-size: 15px;
        cursor: pointer;
        color: #aaa;
      }
      .staff_input__search {
        flex: 1;
        border: 0px;
        color: #aaa;
        padding-left: 8px;
        font-size: 15px;
      }
    }
    .bottom-assign-task {
      display: flex;
      justify-content: flex-end;
      margin-top: 16px;
      padding: 0 16px;
    }
    .list-staffs-not-assigned {
      min-height: 200px;
      max-height: 400px;
      overflow: auto;
      display: flex;
      flex-direction: column;
      justify-content: start;
      margin-top: 16px;
      .item-staff-not-assigned {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
        border-top: 2px solid #dad8d8;
        border-bottom: 2px solid #dad8d8;
        padding: 16px 8px;
        .container-info-staff {
          display: flex;
          margin-left: 18px;
          gap: 8px;
        }
        .item-staff-not-assigned__control {
          .staff-not-assigned__checkbox {
            margin-left: 30px;
            height: 40px;
            width: 20px;
          }
        }
        .staff-avatar-not-assigned__img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
        .staff-not-assigned__info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .staff-not-assigned__name {
            font-size: 14px;
            font-weight: bold;
          }
          .staff-not-assgined__position {
            font-size: 13px;
            font-weight: normal;
          }
        }
      }
    }
  }