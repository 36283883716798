//
// Text
//

// Alignment

.text-justify  { text-align: justify !important; }
.text-nowrap   { white-space: nowrap !important; }
.text-truncate { @include text-truncate; }

// Responsive alignment

// Transformation

.text-lowercase  { text-transform: lowercase !important; }
.text-uppercase  { text-transform: uppercase !important; }
.text-capitalize { text-transform: capitalize !important; }

// Weight and italics

.font-weight-normal { font-weight: $font-weight-normal; }
.font-weight-bold   { font-weight: $font-weight-bold; }
.font-italic        { font-style: italic; }

// Contextual colors

.text-white {
  color: #fff !important;
}

// Misc

.text-hide {
  @include text-hide();
}
