* {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px unset;
    border-radius: 10px;
    height: 80px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    opacity: 0.4;
    background-color: $scrollbars;
    border-radius: 3.5px;
    height: 6px;
  }
}
.dot-active {
  width: 15px;
  height: 15px;
  background-color: $blue;
  border-radius: 100%;
  position: absolute;
  top: -5px;
  left: -5px;
}
.dot-inactive {
  width: 15px;
  height: 15px;
  background-color: #b7b7b7;
  border-radius: 100%;
  position: absolute;
  top: -5px;
  left: -5px;
}
.stores-container {
  .heading-title {
    &.store-title-page {
      // max-width: 515px;
      font-size: 30px;
      padding-top: 10px;
    }
    font-family: "Montserrat";
    font-weight: 800;
    font-size: 18px;
    color: #131c33;
    letter-spacing: 0;
    padding-bottom: 0;
  }
  .heading-container {
    margin-top: -100px;
    &.heading-overview {
      margin-top: 16px;
      background-color: $white;
      border-radius: 4px;
      .heading-content {
        display: flex;
        padding: 20px 10px;
        justify-content: space-between;
        width: 100%;
        .heading-info {
          position: relative;
          width: calc(100% - 250px);
          > .heading-title {
            letter-spacing: 0;
            padding: 10px 0;
            margin-top: 0;
          }
        }
        .heading-trading {
          width: 250px;
          padding: 0 20px;
          margin-top: 10px;
          .heading-right {
            display: block;
          }
        }
      }
    }
  }
  .logo-stores {
    position: relative;
    background: $white;
    box-shadow: 0 2px 24px 0 $boxshadow-logo;
    border-radius: 11px;
    .edit-avatar {
      i {
        background: #000;
        border-radius: 51%;
        cursor: pointer;
        color: #fff;
        position: absolute;
        z-index: 2;
        bottom: -10px;
        right: -10px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .block-action {
      color: #fff;
      display: flex;
      font-size: 11px;
      position: absolute;
      top: 5px;
      right: 5px;
      .block-action__btn {
        padding: 2px 5px;
        border-radius: 8px;
        font-weight: 600;
        cursor: pointer;
      }
      .btn-save {
        background-color: #0088ff;
        color: #fff;
        margin-left: 5px;
      }
      .btn-cancel {
        background-color: #fff;
        color: #000;
      }
    }
    .box-active {
      width: 10px;
      height: 10px;
      background-color: $blue;
      border-radius: 100%;
      position: absolute;
      bottom: 5px;
      right: 5px;
    }
    .box-inactive {
      width: 10px;
      height: 10px;
      background-color: $danger;
      border-radius: 100%;
      position: absolute;
      bottom: 5px;
      right: 5px;
    }
    .inactive {
      width: 10px;
      height: 10px;
      background-color: $danger;
      border-radius: 100%;
      position: absolute;
      bottom: 5px;
      right: 5px;
    }
    .logo-img {
      max-height: 260px;
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }
  .heading-content {
    display: flex;
    padding-top: 100px;
    justify-content: space-between;
    width: 100%;
    .heading-info {
      position: relative;
      width: calc(100% - 250px);
      > .heading-title {
        font-family: "Montserrat";
        font-weight: 800;
        font-size: 30px;
        color: #131c33;
        letter-spacing: 0;
        padding: 10px 0;
        padding-right: 120px;
      }
      .heading-norm {
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 14px;
        color: #131c33;
        letter-spacing: 0;
        line-height: 24px;
        &.store-sologun {
          font-size: 16px;
          color: #131c33;
          font-weight: 600;
        }
        &.bussiness-description {
          font-weight: 700;
          font-size: 14px;
          .read-or-hide {
            color: #028ab8;
            cursor: pointer;
            font-size: 14px;
          }
          .read-or-hide:hover {
            color: #59aa00;
            font-size: 14px;
          }
        }
      }
      .heading-profile {
        display: inline-flex;
        margin-top: 0px;
        width: 60%;
        .profile-name {
          white-space: pre-wrap;
          font-family: "Montserrat";
          font-weight: 400;
          font-size: 14px;
          color: $text-title;
          letter-spacing: 0;
          flex: 1 1 auto;
          margin: 5px;
          max-width: 60px !important;
          cursor: pointer;
          .link {
            display: flex;
            align-items: center;
          }
          a {
            color: inherit;
          }
          .directions-text {
            font-weight: 700;
            font-size: 10px;
            text-align: center;
          }
        }
        .box-icon {
          width: 35px;
          height: 35px;
          border: 1px solid #ffffff;
          background-color: #bcc0c1;
          color: #000;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: auto;
          // &:hover {
          //   border: 1px solid $white;
          //   background-color: $primary;
          //   .icon {
          //     color: $white;
          //   }
          // }
          .icon {
            font-size: 12px;
          }
        }
      }
      .heading-status {
        // position: absolute;
        // right: 0;
        // top: 15px;
        padding: 5px 0;
        font-weight: 500;
        font-size: 16px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        .list-star {
          padding: 0 10px;
        }
        .heading-total {
          font-family: "Montserrat";
          font-weight: 500;
          font-size: 20px;
          color: $yellow;
          letter-spacing: 0;
          padding-top: 5px;
          padding-bottom: 5px;
        }
        .status {
          border-radius: 5px;
          padding: 2px 5px;
          font-family: "Montserrat";
          font-weight: 600;
          font-size: 14px;
          color: $blue;
          letter-spacing: 0;
          &.status-close {
            color: $danger;
          }
        }
      }
    }
    .heading-trading {
      width: 250px;
      padding: 0 20px;
      .heading-right {
        display: contents;
      }
      .heading-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .toggle-menu-top {
          display: none;
        }
      }
      .status {
        border-radius: 5px;
        padding: 5px 12px;
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 14px;
        color: $blue;
        letter-spacing: 0;
        &.status-close {
          color: $danger;
        }
      }
      .heading-title {
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 13px;
        color: #000000;
        letter-spacing: 0;
        font-weight: 700;
        padding: 5px 0;
      }
      .list-trading {
        border-top: 1px solid $border-link;
        max-height: 100px;
        min-height: 100px;
        overflow-y: scroll;
        padding: 0px;
        text-align: right;
        &::-webkit-scrollbar {
          width: 8px;
          background-color: transparent;
        }
        /* Track */
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px unset;
          border-radius: 10px;
          height: 80px;
          background-color: transparent;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
          opacity: 0.4;
          background-color: $trading-bg;
          border-radius: 3.5px;
          height: 6px;
        }
        .item-norm-red {
          background-color: $danger;
          color: $white;
          border-radius: 50px;
          padding: 4px 8px;
          font-size: 12px;
        }
        .item-trading {
          &.status-close {
            text-decoration: line-through;
            color: #000;
          }
          display: flex;
          justify-content: space-between;
          flex-wrap: nowrap;
          text-align: right;

          .item-norm {
            opacity: 0.9;
            font-family: "Montserrat";
            font-weight: 400;
            font-size: 14px;
            color: $text-title;
            letter-spacing: 0;
            padding: 5px 0;
          }
        }
      }
    }
  }
  .btn-change {
    margin-left: 15px;
    color: #b5afaf;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }

  .store-overview {
    &.comment-container-responsive {
      display: none;
    }
    .menu {
      display: flex;
      border-bottom: 1px solid rgba($color: $border-bussiness, $alpha: 0.3);
      margin-bottom: 20px;
      .item {
        width: 50%;
        position: relative;
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 20px;
        color: $dark;
        letter-spacing: 0;
        line-height: 46px;
        margin-bottom: 0px;
        &::after {
          content: "";
          position: absolute;
          width: 0%;
          height: 3px;
          left: 0;
          bottom: -8px;
          background-color: $primary;
          transition: all 0.3s ease-in-out;
        }
        &.active {
          color: $primary;
          &::after {
            width: 100%;
          }
        }
      }
    }
    .review-nums {
      display: flex;
      margin-top: 20px;
      .total-star-title {
        font-size: 25px;
        line-height: 23px;
        margin-right: 10px;
      }
      .total-review-title {
        align-self: end;
        line-height: 18px;
        font-size: 15px;
        margin-left: 10px;
      }
    }
    .review-list {
      margin-top: 20px;
      max-height: 500px;
      overflow: auto;
      .review-item {
        padding: 10px 0;
        border-top: 1px solid #d0caca;
        .user-info {
          align-items: center;
          display: flex;
          &__avatar {
            align-items: center;
            border-radius: 50%;
            color: $white;
            display: flex;
            margin-right: 10px;
            width: 50px;
            height: 50px;
            justify-content: center;
            background-size: cover;
            background-repeat: no-repeat;
          }
          &__name {
            font-weight: 600;
          }
          &__date {
            font-size: 11px;
          }
        }
        .review-content {
          margin: 10px 0 0 50px;
          &__desc {
            font-size: 13px;
            white-space: pre-line;
          }
          &__images {
            display: flex;
            flex-wrap: wrap;
            justify-content: left;
            gap: 10px;
            margin-top: 10px;
            .img {
              flex-basis: 17%;
              img {
                object-fit: cover;
                max-width: 100%;
                height: auto;
                vertical-align: middle;
                border-radius: 5px;
              }
            }
          }
        }
      }
    }
    .overview {
      .overview-info {
        &__item {
          margin: 10px 0;
        }
        &__map {
          height: 500px;
        }
      }
      .marker-img {
        width: 35px;
      }
    }
  }
}
.btn-claim, .btn-claim:hover, .btn-claim:focus {
  background-color: $heading !important;
  color: #fff !important;
}
.stores-front {
  @include breakpoint(768) {
    .heading-container {
      .rating-mobile {
        span {
          color: white !important;
        }
      }
      margin-top: -75vh !important;
      .heading-title, .popover__content {
        color: #fff !important;
      }
      .heading-content {
        .heading-title, .heading-norm, .profile-name {
          color: $white !important;
        }
      }
    }
    .product-container {
      &.mt-5 {
        margin-top: calc(75vh - 415px) !important;
      }
      .inner-overvier-active {
        width: 100vw !important;
        position: relative !important;
        left: calc(-50vw + 50%);
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}
.bussiness-tab {
  position: fixed;
  left: 0;
  top: 150px;
  background-color: $white;
  box-shadow: 0 2px 14px 0 $boxshadow;
  border-radius: 0px 12px 12px 0px;
  z-index: 9999;
  .bussiness-item {
    position: relative;
    display: block;
    border-bottom: 1px solid $border-bussiness;
    &:hover {
      .bussiness-title {
        left: 55px;
        visibility: visible;
        opacity: 1;
      }
    }
    &:last-child {
      border-bottom: none;
    }
    .bussiness-title {
      position: absolute;
      top: 5px;
      left: 0px;
      background-color: $white;
      box-shadow: 0 2px 11px 0 $boxshadow-bussiness-title;
      border-radius: 4px;
      font-family: "Montserrat";
      font-weight: 600;
      font-size: 12px;
      color: #333333;
      letter-spacing: 0;
      white-space: nowrap;
      padding: 5px 10px;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s ease-in-out;
      &::after {
        position: absolute;
        content: "";
        width: 8px;
        height: 8px;
        background-color: $white;
        left: -2px;
        top: 10px;
        transform: rotate(45deg);
      }
    }
    .bussiness-icon {
      padding: 10px 10px;
      width: 45px;
      height: 45px;
    }
  }
}

.banner-setting {
  position: relative;
  .edit-bg {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    cursor: pointer;
    padding: 2px 5px;
    position: absolute;
    z-index: 2;
    bottom: 15px;
    right: 15px;
    i {
      font-size: 17px;
      margin-right: 5px;
    }
  }
  @include breakpoint(992) {
    .edit-bg {
      width: 27px;
      i {
        margin-right: unset;
      }
    }
  }
  .block-action {
    display: flex;
    font-size: 11px;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100%;
    background-color: #2125295e;
    color: #000;
    padding: 10px;
    justify-content: end;
    padding-right: 130px;
    .block-action__btn {
      border-radius: 8px;
      color: #fff;
      cursor: pointer;
      padding: 5px;
      font-size: 15px;
      font-weight: 600;
    }
    .btn-save {
      background: #5656f8;
      margin-left: 15px;
    }
    .btn-cancel {
      background: #dcdce482;
    }
  }
}

.banner-store {
  color: white;
  .banner-item {
    height: 450px;
  }
  .action {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    cursor: pointer;
    flex-direction: column;
    padding: 10px 5px;
    position: absolute;
    top: 30px;
    right: 10px;
    z-index: 2;
    img {
      width: 30px;
      filter: brightness(0) invert(1);
    }
    li { 
      i {
        font-size: 23px;
      }
    }
  }
}

.banner-store_video {
  .action {
    top: 60px;
  }
}

.banner-store_video {
  background-color: lightgray;
  @include breakpoint(768) {
    margin-top: -80px;
    margin-bottom: 0 !important;
    height: 100vh;
    .bg-blur {
      height: 100vh;
      position: absolute;
      background: rgba(0, 0, 0, 0.2);
      width: 100%;
    }
    .edit-bg {
      bottom: 85px;
    }
    .action {
      top: 90px;
    }
  }
  video {
    height: auto;
    @include breakpoint(992) {
      max-height: 260px;
    }
    @include breakpoint(768) {
      height: 100%;
      max-height: 100%;
      object-fit: cover;
    }
    max-height: 450px;
  }
}

.banner-container {
  .store-slider {
    .banner-item {
      background-repeat: no-repeat;
      background-size: 100% 100%;
      height: 450px;
    }
  }
}

.tab-food {
  .item {
    font-family: "Montserrat" !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    color: $dark !important;
  }
  .item-norm {
    font-size: 14px !important;
  }
  .tab-item {
    margin: 0 5px;
  }
}

.popover__content {
  position: absolute;
  bottom: -15px;
  left: 0px;
  font-size: 9px;
  color: #000;
  font-weight: 600;
}

.qr-code__menu {
  display: flex;
  flex-direction: column;
  margin-left: 3px;
}

.menu-popover__content {
  line-height: 13px;
  border-radius: 5px;
  display: inline-block;
  white-space: nowrap;
  font-size: 9px;
  font-weight: 600;
  color: #000;
  margin-top: -2px;
}

.block-qr-code {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #ffffff;
  padding: 5px 5px 0px 5px;
  border-radius: 0px 5px 0px 5px;
  border: 2px solid black;
  cursor: pointer;
  .ic-common-qr-code {
    font-size: 20px;
    color: #ffffff;
    color: black;
  }
}

.block-qr-code__menu {
  background-color: #ffffff;
  padding: 5px 5px 0px 5px;
  cursor: pointer;
  margin-right: 5px;
  border: 2px solid black;
  border-radius: 5px;
  @media only screen and (max-width: 768px) {
    padding: 5px 5px 2px 5px;
  }
  .ic-common-qr-code {
    font-size: 20px;
    color: black;
  }
}

.heading-title__menu {
  display: flex;
  align-items: center;
}

@keyframes ani-popover__content {
  0% {
    top: 38px;
  }
  100% {
    top: 45px;
  }
}

.float_cart {
  // height: calc(100vh - 197px);
  @include breakpoint(992) {
    display: none;
  }
  box-shadow: $boxshadow-inner;
  margin: 0 0 40px 0;
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
  contain: layout;
  .block_cart {
    background-color: $white;
    padding: 10px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    .store_info {
      padding-bottom: 5px;
      margin-bottom: 30px;
      border-bottom: 1px solid #d0caca;
      display: flex;
      justify-content: space-between;
      .store_name {
        color: #028ab8;
      }
      img {
        width: 80px;
        object-fit: contain;
      }
      .title {
        .title_head {
          font-size: 20px;
          font-weight: 600;
        }
        .title_item {
          font-weight: 500;
          font-size: 12px;
          color: #686b78;
          text-transform: uppercase;
        }
        margin-bottom: 30px;
      }
    }
    .items {
      padding-bottom: 5px;
      border-bottom: 1px solid #d0caca;
      max-height: 250px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 8px;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px unset;
        border-radius: 10px;
        height: 80px;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        opacity: 0.4;
        background-color: $scrollbars;
        border-radius: 3.5px;
        height: 6px;
      }
      .item-container {
        display: flex;
        flex-direction: column;
        padding-bottom: 15px;
        .item {
          display: flex;
          justify-content: space-between;
          .name {
            font-size: 11px;
            font-weight: 300;
            color: black;
            width: 80px;
            max-width: 80px;
          }
          .quantity {
            display: flex;
            height: 28px;
            .operator {
              border: 1.5px solid #707070;
              font-family: "Montserrat";
              font-weight: 400;
              font-weight: 700;
              cursor: pointer;
              color: $dark;
              &::selection {
                background-color: transparent;
              }
              &:hover {
                background-color: $grey;
              }
              &:first-child {
                border-right: unset;
                font-size: 15px;
                padding: 1px 10px;
                color: #bcaeae;
              }
              &:nth-child(2) {
                border-right: unset;
                border-left: unset;
                padding: 3px 8px;
                font-size: 12px;
                color: #028ab8;
              }
              &:last-child {
                font-size: 15px;
                padding: 1px 10px;
                border-left: unset;
                color: #028ab8;
              }
            }
          }
          .total-price {
            font-size: 11px;
          }

          .text-options{
            font-size: 11px;
            color: gray;
            font-weight: 400;
            font-style: italic;
            cursor: pointer;
          }
        }
      }
     
    }
    .empty-container {
      height: 100px;   
      margin-bottom: 20px;
      img {
        width: 100px;
      }
      .empty-title {
        text-align: left;
        color: #ccc;
        font-size: 11px;
      }
    }
    .total {
      display: flex;
      justify-content: space-between;
      color: #3d4152;
      font-size: 15px;
      font-weight: 600;
      margin-top: 10px;
    }
  }
  button {
    width: 100%;
    border-radius: unset !important;
    color: $white !important;
    background-color: $heading !important;
    font-weight: bold !important;
    border-bottom-right-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }
}


.bottom_cart {
  @media (min-width: 992px) {
    display: none;
  }
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 48px;
  color: $white;
  z-index: 2;
  background-color: #131c33;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px -4px 3px #bfb9b9bf;
  .block_cart {
    display: flex;
    width: 70%;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    .build-cart {
      position: relative;
      display: inline-block;
      img {
        width: 25px;
      }
      .cart-total {
        position: absolute;
        right: -10px;
        top: -5px;
        width: 17px;
        height: 17px;
        padding: 1px 5px 0;
        border-radius: 100%;
        background-color: #e22121;
        color: #fff;
        font-size: 10px;
        font-family: "Montserrat";
        font-weight: 600;
      }
    }
  }
  button {
    width: 30%;
    margin-right: 0!important;
    padding: 0!important;
    color: #131c33 !important;
    border-radius: unset!important;
    background-color: #fff !important;
    height: 100%;
    font-size: 13px !important;
  }
}
.method-block {
  @media (min-width: 993px) {
    margin-bottom: 10px;
    position: sticky;
    top: 70px;
  }
  display: flex;
  justify-content: space-between;
  .sub-header__type {
    display: flex;
    flex-direction: column;
    .label-table {
      font-size: 10px;
      font-weight: normal;
    }
  }
  @include breakpoint(992) {
    .btn-change {
      text-transform: none;
    }
    .sub-header__type {
      color: #000;
    }
  }
}
.popup-method-container {
  width: 500px;
  @include breakpoint(600) {
    width: 300px;
  }
  background-color: #ffffff;
  border-radius: 10px;
  .method-wrap {
    border-bottom: 1px solid rgb(166, 166, 166);
    padding: 10px 34px 15px;
    .sub-header__type {
      padding: 11px 15px;
      cursor: pointer;
      &:hover {
        background-color: #f5eeee;
      }
    }
    .method-active {
      border-bottom: 4px solid rgb(117, 117, 117);
    }
  }
  .method-body {
    padding: 11px 30px 25px 30px;
    .block-btn {
      padding-top: 15px;
      button {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 0 auto;
      }
    }
    .method-content {
      font-size: 14px;
      font-family: 'Rating';
    }
  }
}
.sub-header__type {
  text-transform: uppercase;
  color: #4a4949;
  letter-spacing: -.1px;
  font-size: 15px;
  font-weight: 700;
}
.popup-promotion-scan {
  .block-image {
    display: flex;
    justify-content: space-between;
    .offer-image {
      object-fit: contain;
    }
    .modal-title {
      font-size: 14px;
      em {
        color: red;
      }
    }
  }
  .btn-claim {
    padding: 5px 10px;
    font-size: 15px;
    line-height: normal;
    font-weight: 600;
  }
  .form-control {
    width: 100% !important;
  }
}