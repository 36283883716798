.header-container {
  @include breakpoint(1111) {
    .menu-desktop {
      .navbar-menu {
        &.navbar-authenticated {
          > .menu-item {
            margin: 0 3px;
            &:last-child {
              margin-right: 0;
            }
            &.menu-last {
              margin: 0 5px;
            }
            > .nav-link > .nav-link {
              font-size: 13px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
  @include breakpoint(992) {
    height: 50px;
    &.header-landing {
      padding: 0;
    }
    .mega-container {
      top: calc(50px + 38px);
    }
    .container-category {
      top: 50px;
    }
    .menu-responsive {
      display: flex;
    }
    .search-box {
      &.search-box-desktop {
        display: none;
      }
    }
    .menu-desktop {
      display: none;
    }
  }
}

@include breakpoint(576) {
  .head-bg__transparent {
    background: transparent;
    .ui.selection.dropdown {
      border: 1px solid $white;
      background-color: transparent;
      color: $white;
      &:hover {
        border-color: $white;
      }
      .text {
        color: $white !important;
      }
      .transition {
        left: 1.05px;
        box-sizing: border-box;
        box-shadow: none;
        min-width: 100% !important;
        width: calc(100% + 1px) !important;
        background-color: transparent !important;
        border: 1px solid;
        border-color: $white !important;
        .active {
          text-decoration: underline;
        }
        .item {
          color: $white;
        }
      }
    }
  }
}

// responsive social
.social-container {
  @include breakpoint(992) {
    margin-top: 20px;
    .social-content {
      padding: 50px 0;
      .social-list {
        align-items: center;
        justify-content: start;
      }
    }
    .social-bg {
      width: 700px;
      .social-img {
        display: none;
      }
    }
    .social-bg-reponsive {
      .social-img-reponsive {
        width: 100%;
        display: block;
      }
    }
  }
}
.testimontals-container {
  @include breakpoint(992) {
    .testimontals-content {
      background-image: url("/assets/images/home/quote.svg");
      background-repeat: no-repeat;
      background-position: left 0%;
      background-size: 30%;
      padding-top: 80px;
    }
    .testimontals-slider {
      overflow: hidden;
      .slick-arrow {
        &.slick-prev {
          bottom: 15%;
        }
        &.slick-next {
          bottom: 15%;
        }
      }
    }
  }
  @include breakpoint(678) {
    .testimontals-content {
      margin-top: 20px;
    }
    .testimontals-slider {
      text-align: left;
      .slick-arrow {
        &.slick-prev {
          bottom: 0%;
          left: 0;
          transform: rotate(0deg);
          &.slick-disabled {
            transform: rotate(0deg);
            &::before {
              content: url("/assets/images/home/arrow-5.svg");
            }
          }
          &::before {
            content: url("/assets/images/home/arrow-5.svg");
          }
        }
        &.slick-next {
          bottom: 0%;
          left: 50px;
        }
      }
    }
  }
}

.social-container {
  @include breakpoint(991) {
    .social-content {
      .social-list {
        .social-img {
          height: 40px;
        }
      }
    }
    .social-bg {
      width: 700px;
      .social-img {
        position: absolute;
        z-index: 1;
        right: 0;
        bottom: 0;
        width: auto;
        height: 100%;
      }
    }
  }
}

.bestpicks-list {
  overflow: hidden;
}

// reponsive Stores
.stores-container {
  @include breakpoint(992) {
    .heading-title {
      padding: 0px 0px 5px 0px !important;
      width: 100%;
      &.store-title-page {
        text-align: center;
      }
      &.trading-hour {
        padding: 0px !important;
        margin-right: 10px;
        font-weight: 500 !important;
        font-size: 12px !important;
      }
    }
    .heading-norm {
      width: 100%;
    }
    .heading-status {
      // position: absolute !important;
      // right: 0 !important;
      // top: 0px !important;
      // display: flex !important;
      // flex-wrap: nowrap !important;
      // align-items: center !important;
      // margin-left: auto !important;
      // margin-right: auto !important;
      // left: 0px !important;
      // width: 100px !important;
    }
    // .heading-container {
    //   margin-top: -160px;
    // }
    .logo-stores {
      max-width: 200px;
      margin: 0 auto;
    }
    .heading-content {
      display: flex;
      padding-top: 10px;
      flex-wrap: wrap;
      justify-content: space-between;
      .heading-info {
        margin-top: 10px;
        position: relative;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        .heading-left {
          width: 100% !important;
          &.store-title-page {
            text-align: center;
          }
        }
        .heading-norm {
          &.store-sologun {
            font-size: 16px;
            font-weight: 600;
            text-align: center;
          }
          font-size: 12px;
          &.bussiness-description {
            text-align: center;
          }
        }
        .heading-profile {
          width: auto;
          margin: auto !important;
          padding-bottom: 10px;
          .profile-name {
            .link {
              &.link-address {
                align-items: center !important;
              }
            }
          }
        }
        .heading-status {
          width: 100%;
          position: relative;
          right: 0;
          top: 0;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          .list-star {
            margin-right: auto;
          }
          .rating {
            margin-right: auto;
          }
        }
        .heading-profile {
          .profile-name {
            &:last-child {
              span[class*="ic-common"] {
                padding-right: 0px;
              }
            }
            .link {
              display: flex !important;
            }
          }
        }
      }
      .heading-trading {
        width: 100%;
        position: relative;
        .heading-top {
          display: flex;
          justify-content: space-between;
          .toggle-menu-top {
            display: block;
            display: flex;
            justify-content: space-between;
            font-size: 20px;
            height: auto;
          }
          .heading-right {
            display: flex;
            align-items: baseline;
          }
          .status {
            margin-right: 10px;
            font-size: 12px !important;
          }
          .heading-title {
            font-weight: 700 !important;
            &::after {
              content: "\e90d";
              font-family: "icomoon";
              font-size: 8px;
              padding-left: 5px;
            }
          }
        }
        .bussiness-content {
          position: absolute;
          top: 100%;
          right: 0;
          width: 100%;
          background-color: $white;
          box-shadow: 0 0 10px -2px rgba($color: $primary, $alpha: 0.1);
          z-index: 999;
          display: none;
          &.bussiness-content-active {
            display: block;
          }
        }
        .list-trading {
          max-height: 200px;
          padding: 10px;
          border-radius: 4px;
        }
      }
    }
    .product-container {
      .inner-box {
        background-color: unset;
        margin-bottom: 15px;
        .category-content {
          background-color: unset;
        }
        .category-list {
          .category-item {
            .category-link {
              border-bottom: unset;
              background-color: #fff;
              color: black;
              border-radius: 20px;
            }
            .category-active {
              color: #028ab8;
              border: 2px solid #028ab8;
              &::before {
                content: none;
              }
            }
            margin-right: 5px;
          }
        }
      }
    }
  }
  @include breakpoint(768) {
    .heading-container {
      margin-top: -160px;
    }
  }
  @include breakpoint(567) {
    .heading-content {
      flex-wrap: wrap;
      padding: 0;
    }
  }
}
// Banner container
.banner-container {
  @include breakpoint(992) {
    .slick-slide {
      height: calc(100vh - 80px);
      video {
        // max-height: 600px;
        height: 100%;
      }
    }
    &.banner-category {
      .banner-image {
        max-height: auto;
        min-height: auto;
      }
    }
  }
}
// banner
.banner-container {
  @include breakpoint(991) {
    .store-slider {
      .banner-item {
        height: 300px;
      }
    }
  }
  @include breakpoint(768) {
    position: relative;
    margin-bottom: 80px;
    .banner-item {
      height: 260px;
    }
    &.banner-category {
      .banner-image {
        max-height: 260px;
        min-height: 260px;
      }
    }
    .btn-store {
      bottom: -70px;
    }
    .banner-image {
      width: 100%;
      min-height: 200px;
    }
  }
  @include breakpoint(567) {
    position: relative;
    .banner-item {
      height: 260px;
    }
    &.banner-category {
      .banner-image {
        max-height: 260px;
        min-height: 260px;
      }
    }
    .btn-store {
      bottom: -70px;
    }
    .banner-image {
      width: 100%;
      min-height: 200px;
    }
    .store-slider {
      .banner-item {
        height: 200px;
      }
    }
  }
  @include breakpoint(400) {
    .btn-store {
      bottom: -70px;
      width: 80%;
      font-size: 13px;
      font-weight: 600;
    }
  }
}

// tab bussiness
.bussiness-tab {
  @include breakpoint(512) {
    .bussiness-item {
      display: none;
      .bussiness-title {
        display: none;
      }
      .bussiness-icon {
        width: 30px;
        height: 30px;
      }
    }
  }
}

// details
.details-container {
  @include breakpoint(991) {
    .category-details {
      .category-content {
        padding: 20px;
      }
      .slider-details {
        padding: 20px 20px 20px 80px;
      }
    }
    .bussiness-reponsive {
      background-color: $white;
      display: flex;
      margin-bottom: 16px;
      padding: 10px;
      margin-top: -47px;
      border-top: 1px solid $border-bussiness;
      display: flex;
      .logo-stores {
        padding: 10px;
        margin-right: 10px;
        width: 30%;
      }
      .inner-box {
        box-shadow: unset;
        padding: 0 10px;
        width: 70%;
        .inner-title {
          border: unset;
        }
      }
    }
  }
}

.newsletter-container {
  @include breakpoint(991) {
    .newsletter-form {
      text-align: left;
      .newsletter-input {
        min-width: auto;
        width: 100%;
      }
      .btn-subscribe {
        margin-left: 0px;
        width: auto;
      }
    }
  }
}

// business
.bussiness-container {
  @include breakpoint(414) {
    padding: 20px 0;
    .bussiness-item {
      .bussiness-title {
        font-size: 11px;
      }
    }
  }
  @include breakpoint(567) {
    padding: 20px 0;
  }
  @include breakpoint(768) {
    padding: 20px 0;
  }
}
// banner
.banner-container {
  @include breakpoint(567) {
    position: relative;
    &.banner-category {
      .banner-image {
        max-height: 260px;
        min-height: 260px;
      }
    }
    .banner-image {
      width: 100%;
      min-height: 200px;
    }
  }
}
// learn more
.learn-more-container {
  @include breakpoint(567) {
    padding: 20px 0px;
  }
}

// online container
.online-container {
  @include breakpoint(567) {
    padding: 20px 0;
    .online-content {
      padding: 0;
    }
  }
}

.delivery-container {
  @include breakpoint(768) {
    padding: 20px 0 20px;
    background-color: $white;
    .delivery-item {
      text-align: left;
      margin: 10px 0;
      .delivery-title {
        text-align: left;
      }
      .delivery-content {
        margin-top: 10px;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}

.comment-container {
  @include breakpoint(992) {
    &.comment-container-responsive {
      display: block;
    }
    &.comment-container-desktop {
      display: none;
    }
  }
  @include breakpoint(768) {
    .tab-container {
      display: flex;
      flex-wrap: nowrap;
      border-bottom: 1px solid rgba($color: $border-bussiness, $alpha: 0.3);
      overflow-x: scroll;
      max-width: 500px;
      &::-webkit-scrollbar {
        height: 4px;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px unset;
        border-radius: 10px;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        opacity: 0.4;
        background-color: $scrollbars;
        border-radius: 3.5px;
      }
      .label-tab {
        white-space: nowrap;
      }
    }
  }
}

.popup-login {
  .wrap-login {
    width: 100% !important;
  }
}

.login-container {
  @include breakpoint(1334) {
    padding-top: 50px;
  }
  @include breakpoint(1336) {
    .login-more {
      width: calc(100% - 50%);
    }
    .wrap-login {
      width: 50%;
    }
  }
  @include breakpoint(1280) {
    .login-more {
      width: calc(100% - 50%);
    }
    .wrap-login {
      width: 50%;
      .login-form {
        padding: 0 50px;
      }
    }
  }
  @include breakpoint(1024) {
    padding-top: 50px;
    .login-more {
      width: calc(100% - 50%);
    }
    .wrap-login {
      width: 50%;
      .login-form {
        padding: 0 50px;
      }
    }
  }
  @include breakpoint(992) {
    flex-direction: column;
    padding-top: 50px;
    .login-more {
      width: 100%;
      height: 30%;
    }
    .wrap-login {
      width: 100%;
      height: calc(100% - 30%);
    }
  }
  @include breakpoint(768) {
    flex-direction: column;
    padding-top: 50px;
    .login-more {
      width: 100%;
      height: 100px;
    }
    .wrap-login {
      width: 100%;
      height: calc(100% - 100px);
      .form-item {
        margin: 16px 0;
        &.form-button {
          width: 100%;
          .btn-form {
            display: block;
            width: 100%;
          }
        }
      }
    }
  }
  @include breakpoint(678) {
    flex-direction: column;
    padding-top: 50px;
    .login-more {
      width: 100%;
      height: 100px;
    }
  }
  @include breakpoint(567) {
    flex-direction: column;
    padding-top: 50px;
    .login-more {
      width: 100%;
      height: 100px;
    }
  }
}

// inner box
.inner-box {
  @include breakpoint(991) {
    .inner-title {
      position: relative;
      margin: 5px 0 5px;
      cursor: pointer;
      .dropdown-inner {
        position: absolute;
        right: 15px;
        top: -3px;
        display: block;
        .icon-open {
          transform: rotate(180deg);
          transition: transform 0.3s ease-in-out;
        }
      }
    }
    .inner-responsive {
      display: none;
      &.inner-responsive-open {
        display: block;
      }
    }
  }
}
.product-container {
  @include breakpoint(992) {
    .tab-heading {
      flex-wrap: wrap;
      border: 0px;
      .category-list {
        flex: unset;
        width: 100%;
      }
    }
    .tab-heading::before {
      display: none;
    }
    .product-heading {
      flex-wrap: wrap;
      .view {
        width: 100%;
        text-align: right;
      }
      .scroll-bar {
        width: 85% !important;
      }
      .block-title {
        width: 100%;
      }
      .btn-circle-primary {
        width: 100%;
        margin-left: 0;
        text-transform: uppercase;
        font-weight: 600;
        background-color: #131c33;
      }
    }
    .info-main {
      flex-direction: column;
    }
  }
  .btn {
    &.btn-circle-primary {
      padding: 3px 32px;
      text-transform: uppercase;
      font-weight: 600;
      background-color: #131c33;
    }
    &.btn-circle {
      border-radius: 5px;
    }
  }
}
// footer container
.footer-container {
  @include breakpoint(768) {
    .footer-social {
      justify-content: flex-start;
      .social-item {
        &:first-child {
          padding: 0;
          .dropdown-language {
            margin: 0;
          }
        }
      }
    }
  }
}

// empty-container
.empty-container {
  @include breakpoint(1500) {
    height: calc(100vh - 120px - 338px);
  }
  @include breakpoint(1334) {
    height: calc(100vh - 50px - 338px);
  }
  @include breakpoint(567) {
    .img-empty {
      width: 100px;
    }
    .btn-continue {
      font-size: 13px;
    }
  }
  @include breakpoint(400) {
    height: 100%;
    padding: 30px 0;
  }
}

.learn-container {
  @include breakpoint(1280) {
    .learn-more {
      width: calc(100% - 50%);
      min-height: 300px;
    }
    .wrap-learn {
      width: 50%;
    }
  }
  @include breakpoint(992) {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    z-index: -1;
    min-height: calc(100vh - 80px);
    .learn-more {
      width: 100%;
      .login-more-img {
        position: absolute;
        width: 250px;
        left: 50%;
        top: 0px;
        transform: translate(-50%, 0);
      }
    }
    .wrap-learn {
      text-align: left;
      width: 100%;
    }
  }
}

// adds-wrapper
.adds-block {
  @include breakpoint(992) {
    .row div[class*="col-"] {
      border-bottom: 0px !important;
    }
    .adds-item {
      border-radius: 0px;
      .adds-info-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  @include breakpoint(576) {
    .adds-item {
      flex-direction: row;
      &.adds-item-responsive {
        flex-wrap: wrap;
      }
      .adds-images {
        min-width: 100px;
        height: 80px !important;
      }
      a {
        width: 100%;
      }
      .adds-container {
        width: 100%;
      }
      .adds-content {
        width: 100%;
        padding: 0px 0px 0px 5px !important;
        .adds-norm {
          white-space: normal;
          padding: 0;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          // min-height: 50px;
          // max-height: 58px;
        }
        .adds-info {
          padding: 8px 8px 4px 0px;
        }
        .adds-info-item {
          .list-star {
            position: unset !important;
          }
        }
      }
      &.adds-list-view {
        .adds-content {
          .adds-norm {
            h5 {
              line-height: 19px !important;
            }
          }
          .group-price {
            width: 100% !important;
            .adds-price {
              float: left;
            }
          }
        }
      }
    }
  }
  @include breakpoint(1444) {
    .adds-item {
      .adds-images {
        // height: 120px;
      }
    }
  }
  @include breakpoint(992) {
    margin: 0;
    box-shadow: none;
    // background-color: transparent;
    margin-bottom: 10px;
    border-bottom: 1px solid #e8e8e1;
    border-left: 1px solid #e8e8e1;
    border-right: 1px solid #e8e8e1;
    .row {
      border-bottom: 1px solid #e8e8e1;
      &:last-child {
        border-bottom: unset;
      }
      &.pt-3,
      &.pb-3 {
        padding: 0 !important;
      }
      div[class*="col-"] {
        padding-left: 0;
        padding-right: 0;
        border-bottom: 1px solid #e8e8e1;
        margin-bottom: 10px;
        &:last-child {
          border-bottom: unset;
          margin-bottom: 0;
        }
      }
    }
    .adds-item {
      flex-direction: row;
      &.adds-item-responsive {
        flex-wrap: wrap;
      }
      &.adds-user-services {
        border: 0px;
      }
      .adds-images {
        min-width: 100px;
        height: 100%;
        object-fit: cover;
      }
      .hover-image {
        width: 40%;
      }
      a {
        width: 100%;
      }
      .adds-container {
        width: 100%;
      }
      .adds-content {
        width: 100%;
        padding: 0px 0px 0px 10px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .adds-actions {
          position: absolute;
          right: 0px;
          bottom: 0px;
          .update-service:nth-child(1) {
            margin-right: 5px;
          }
        }
        .adds-norm {
          white-space: normal;
          padding: 0;
          display: block;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          min-height: none !important;
          max-height: none !important;
        }
        .adds-info {
          padding: 8px 8px 4px 0px;
          position: relative;
          .list-star {
            .ui {
              &.small {
                &.rating {
                  font-size: 12px !important;
                }
              }
            }
          }
          @include breakpoint(576) {
            .list-star {
              position: absolute;
              top: 5px;
              left: 5px;
              .ui {
                &.small {
                  &.rating {
                    font-size: 10px !important;
                  }
                }
              }
            }
          }
        }
      }
      &.adds-list-view {
        .adds-content {
          .group-price {
            width: 100% !important;
            .adds-price {
              float: left;
            }
          }
        }
      }
    }
  }
}

// cart
.cart-table {
  @include breakpoint(992) {
    overflow-x: auto;
    &.cart-table-desktop {
      table {
        display: none;
      }
    }
    &.cart-table-responsive {
      background-color: $white;
      display: block;
      .cart-item {
        display: block;
        padding: 20px;
        &:not(:first-child) {
          border-top: 1px solid rgba($color: $border-bussiness, $alpha: 0.35);
        }
      }
    }
  }
  @include breakpoint(400) {
    overflow-x: auto;
    &.cart-table-desktop {
      table {
        display: none;
      }
    }
    &.cart-table-responsive {
      background-color: $white;
      display: block;
      .cart-item {
        display: block;
        padding: 20px 10px;
        &:not(:first-child) {
          border-top: 1px solid rgba($color: $border-bussiness, $alpha: 0.35);
        }
      }
    }
  }
}

.checkout-container {
  @include breakpoint(992) {
    .btn-process {
      &.btn-process-yellow {
        width: 100%;
      }
    }
  }
}

.popup-container {
  @include breakpoint(992) {
    width: 90vw;
    height: 60vh;
    flex-direction: row;
    position: relative;
    flex-wrap: wrap;
    .icon {
      right: 10px;
      top: 10px;
      color: #000;
    }
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
    }
    .popup-more {
      height: calc(60vh - 150px);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
    }
    .popup-content {
      height: 150px;
      width: 100%;
      padding-bottom: 30px;
      z-index: 2;
      .label-checbox {
        width: 100%;
        justify-content: center;
        right: 0;
        left: 0;
        bottom: 10px;
      }
      .content-title {
        display: none;
      }
      .content-norm {
        display: none;
      }
      .content-form {
        width: 100%;
      }
    }
  }
}

.popup-scan-container {
  @include breakpoint(992) {
    display: flex;
    flex-direction: column;
    width: 95vw;
    .icon {
      right: 25px;
      top: 25px;
      color: $black;
    }
    .popup-more {
      height: calc(70vh - 150px);
      background-size: 100% auto;
      width: 100%;
      .popup-norm {
        font-size: 13px;
      }
    }
  }
}

.styles_overlay__CLSq- {
  @include breakpoint(992) {
    padding: 0;
  }
}

.shop-now-slider {
  @include breakpoint(992) {
    display: none;
  }
}

.featured-content {
  @include breakpoint(678) {
    .featured-list-tab {
      .featured-tab {
        span {
          font-size: 13px;
        }
      }
    }
  }
  .featured-slider {
    @include breakpoint(992) {
      visibility: hidden;
      max-height: 0;
    }
  }
}

.shop-now-slider-responsive {
  @include breakpoint(992) {
    display: block;
  }
  @include breakpoint(768) {
    .slider-item {
      .inner-img {
        width: 100%;
        height: 300px;
      }
    }
  }
  @include breakpoint(768) {
    .slider-item {
      .inner-img {
        width: 100%;
        height: 200px;
      }
    }
  }
}

.about-container {
  @include breakpoint(992) {
    .about-more {
      height: 300px;
    }
    .explore-container {
      .explore-content {
        padding: 30px 10px;
      }
    }
    .business-container {
      .business-our {
        &.business-our-comnunity {
          .business-img {
            margin-right: 0px;
            margin-left: 0px;
            margin-top: 0px;
          }
          .business-content {
            height: auto;
          }
        }
        .business-content {
          height: auto;
        }
        .business-img {
          margin-right: 0;
          margin-left: 0px;
          margin-top: 0px;
        }
      }
      .business-content {
        height: auto;
        padding: 18px;
      }
      .business-img {
        margin-right: 0px;
      }
    }
    .customer-container {
      padding: 100px 0px;
      background-color: rgba($color: $primary, $alpha: 0.1);
      .slick-arrow {
        top: 30px;
        &.slick-prev {
          left: 5px;
        }
        &.slick-next {
          right: 5px;
        }
      }
      .slider-item {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column-reverse;
        box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.3);
        .customer-img {
          width: 100%;
        }
        .customer-content {
          padding: 10px;
          width: 100%;
        }
      }
    }
    .stay-container {
      padding: 20px 0;
    }
  }
}

.delivery {
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  border: 1px solid $yellow;
  border-radius: 0px 0px 8px 8px;
  @include breakpoint(992) {
    .item {
      width: 50%;
    }
  }
}

.menu-video {
  @include breakpoint(1200) {
    display: none;
  }
}

.video-container {
  @include breakpoint(992) {
    overflow: hidden;
  }
}

.product-container {
  @include breakpoint(992) {
    padding-top: 0 !important;
    &.mt-5 {
      margin-top: 10px !important;
    }
    strong {
      display: block;
    }
  }
}

.qr-code {
  @include breakpoint(992) {
    width: 100%;
  }
  @include breakpoint(768) {
    margin: 10px auto !important;
    width: 250px;
    height: 30vh;
    object-fit: cover;
  }
}

.adds-block-video {
  @include breakpoint(768) {
    .adds-item-video {
      width: 100%;
    }
  }
}

.tabs-container {
  @include breakpoint(768) {
    .tabs-item {
      margin: 0 10px;
    }
  }
}
@media only screen and (max-width: 992px) {
  .category-name {
    margin-top: 0px;
    padding: 3px;
  }
}
// @media only screen and (max-width: 992px) {
//   .btn-order {
//     display: none;
//   }
// }
.btn-order-sm {
  border: 0px;
  color: #fff;
  font-weight: 600;
  background-color: rgb(19, 28, 51);
  padding-top: 2px;
  float: right;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  .icon-order {
    width: 13px !important;
    float: right;
    margin: 3px 0px 0px 5px;
  }
}
.settings-container {
  .row {
    .col-lg-12 {
      .ui {
        &.form {
          .choose-item {
            .radio {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
@include breakpoint(992) {
  .rating-mobile {
    span {
      font-weight: 500;
    }
  }
  .item-list {
    .make-grid {
      width: 100% !important;
    }
  }

  .category-list {
    .adds-block {
      border-bottom: 0px !important;
      border-left: 0px !important;
      border-right: 0px !important;
    }
  }

  .item-list {
    &.make-grid {
      .cornerRibbons {
        left: -18% !important;
        top: 8% !important;
      }
    }
  }
  .item-list {
    width: 100% !important;
  }
}
@include breakpoint(767) {
  .item-list {
    &.make-grid {
      .cornerRibbons {
        left: -14% !important;
        top: 7% !important;
      }
    }
  }
}
@include breakpoint(1264) {
  .menu-item {
    .build-shop_pc {
      display: none;
    }
  }
}
