.checkout-container {
  .title-checkout {
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 24px;
    color: $dark;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    .icon {
      margin-right: 16px;
    }
  }
  .cart-order {
    width: 100%;
    @include breakpoint(678) {
      .btn-continue-dark {
        width: 160px;
        max-width: 160px;
        font-size: 12px;
      }
    }
  }
  .checkout-content {
    background-color: $white;
    padding: 10px 16px;
    border-radius: 4px;
    box-shadow: 0 0 10px -2px $boxshadow;
    width: 100%;
    .react-tel-input .form-control {
      width: 100%;
      box-shadow: none;
    }
    .time-info {
      .time-info__book {
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
      }

      .time-info__est {
        font-size: 13px;
        font-weight: 500;
        line-height: 21px;
        color: #878c93;
      }
    }
    .service-name a {
      font-size: 13px;
      font-weight: 600;
      color: #000;
    }
    .store_info {
      margin-bottom: 15px;
      padding-bottom: 5px;
      border-bottom: 1px solid #d0caca;
      .store_name {
        color: #028ab8;
      }
      .store_addr {
        font-weight: 400;
        font-size: 12px;
        color: gray;
      }
    }
    .total {
      display: flex;
      justify-content: space-between;
      color: #3d4152;
      font-size: 15px;
      font-weight: 600;
      margin-top: 10px;
    }
  }
  .checkout-info {
    &:not(:first-child) {
      border-top: 1px solid #dadee2;
      margin: 10px -10px;
      padding: 0 10px;
    }
    .checkout-title {
      font-family: "Montserrat";
      font-weight: 700;
      font-size: 13px;
      color: $dark;
      padding: 10px 0;
      text-transform: uppercase;
      .icon {
        margin-right: 8px;
        width: 16px;
      }
    }
    .choose-payment {
      display: flex;
      .payment-credit {
        display: flex;
        align-items: center;
        .icon {
          margin-right: 5px;
        }
      }
      .choose-item {
        display: flex;
        align-items: center;
        img {
          width: 40px;
        }
        &:not(:first-child) {
          margin-left: 10px;
        }
        .choose-radio {
          margin-right: 5px;
          white-space: nowrap;
          font-size: 13px;
        }
        .choose-label {
          font-family: "Montserrat";
          font-weight: 400;
          font-size: 14px;
          color: $dark;
        }
      }
    }
    .check-label {
      font-family: "Montserrat";
      font-weight: 400;
      font-size: 14px;
      color: $dark;
      font-weight: 700;
      .check-name {
        font-weight: 400;
        padding-left: 5px;
      }
    }
    .label-checkbox {
      font-family: "Montserrat";
      font-weight: 400;
      font-size: 14px;
      color: $dark;
      display: flex;
      align-items: center;
      .checkbox {
        margin-right: 5px;
      }
    }
    .form-checkout {
      padding: 5px 0;
      .form-item {
        display: block;
        margin: 5px 0;
        .form-label {
          font-family: "Montserrat";
          font-weight: 500;
          font-size: 14px;
          color: $dark;
          font-weight: 700;
          margin-bottom: 0;
        }
        .form-input {
          display: block;
          width: 100%;
          padding: 8px 16px;
          outline: none;
          border: 1px solid #cfcfcf;
          font-family: "Montserrat";
          font-weight: 400;
          font-size: 15px;
          border-radius: 4px;
        }
      }
    }
  }
  .btn-process {
    background-color: rgba(19, 28, 51, 0.8);
    color: white;
    font-family: "Montserrat";
    font-weight: 500;
    border-radius: 26px;
    padding: 6px 16px;
    font-size: 15px;
    &.btn-process-yellow {
      background-color: #f6cf37;
      border: 1px solid #f6cf37;
      color: $dark;
      border-radius: 4px;
      display: inline-flex;
      align-items: center;
      padding: 10px 36px;
    }
    .icon {
      width: 16px;
      margin-right: 5px;
      margin-top: -5px;
    }
  }
  .process-checkout {
    background-color: $white;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0 0 10px -2px $boxshadow;
    .text-total {
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 21px;
      color: $dark;
      &.text-price {
        color: $danger;
      }
    }
    .text-norm {
      font-family: "Montserrat";
      font-weight: 500;
      white-space: nowrap;
      font-size: 15px;
      color: $dark;
      margin-bottom: 0;
    }
  }
  .pay-credit {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 14px;
    .img-credit {
      width: 60px;
      margin-right: 10px;
    }
  }
  .service-content {
    background-color: $white;
    box-shadow: 0 0 10px -2px $boxshadow;
    border-radius: 4px;
    .image-service {
      margin-top: 26px;
      .img-serivce {
        width: 100%;
        height: auto;
      }
    }
    .service-info {
      margin-top: 10px;
      .item {
        padding-right: 30px;
      }
      .service-title {
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 13px;
        color: $dark;
        padding: 6px 0;
        text-transform: uppercase;
      }
      .service-norm {
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 14px;
        color: $dark;
      }
    }
  }
  .w-100px {
    width: 100px !important;
  }
  .quantity_prod {
    position: absolute;
    right: -4px;
    top: -13px;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    background-color: #e22121;
    color: #fff;
    font-size: 13px;
    font-family: "Montserrat";
    font-weight: 600;
    z-index: 9;
    .number {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
    }
  }
}

.btn-choose-checkout__login {
  width: 100%;
  background-color: rgba(19, 28, 51, 0.8);
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 14px;
  padding: 8px 16px;
  white-space: pre-line;
}

.modal-choose-checkout {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .text-or {
    width: 100%;
    position: relative;
    display: block;
    text-align: center;
    padding: 10px 0;
    margin: 10px 0;
    color: $text-grey;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 12px;
    &::after {
      content: '';
      position: absolute;
      left: 10%;
      top: 20px;
      width: 30%;
      height: 1px;
      background-color: $text-grey;
    }
    &::before {
      content: '';
      position: absolute;
      right: 10%;
      top: 20px;
      width: 30%;
      height: 1px;
      background-color: $text-grey;
    }
  }
  button {
    width: 100%;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 14px;
    padding: 8px 16px;
    img {
      margin-right: 15px;
      width: 25px;
    }
    i {
      margin-right: 20px;
    }
  }
  .btn-choose-checkout__login {
    margin: 35px 0 0 0;
    white-space: pre-line;
    background-color: #f6cf37;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn-choose-checkout__guest {
    background-color: rgba(19, 28, 51, 0.8);
    color: #fff;
  }
}