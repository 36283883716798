.empty-container {
  position: relative;
  height: calc(100vh - 80px - 338px);
  display: flex;
  align-items: center;
  justify-content: center;
  &.empty-container-white {
    background: $white;
    margin: 16px 0;
    border-radius: 4px;
  }
  .empty-content {
    text-align: center;
  }
  .empty-title {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 18px;
    color: $dark;
    line-height: 26px;
  }
  .btn-continue {
    background-color: $heading;
    color: $white;
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 20px;
    border: 1px solid $heading;
    // &:hover {
    //   background-color: $hover-primary;
    // }
  }
}
