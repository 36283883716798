.footer-container {
  background-color: $footer-bg;
  padding: 30px 0;
  .footer-logo {
    width: 100%;
    .footer-img {
      margin-bottom: 5px;
      vertical-align: top !important;
      margin-right: 20px;
      max-height: 120px;
    }
  }
  .footer-col {
    display: flex;
    margin: 0 8px;
    .footer-nav {
      li {
        display: inline-block;
        // opacity: 0.32;
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 13px;
        color: $white;
        width: 100%;
        line-height: 28px;
        margin: 5px 0;
      }
    }
  }
  .footer-menu {
    display: flex;
    flex-wrap: wrap;
    &.footer-menu-landing {
      display: block;
      .title-menu {
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 14px;
        color: $white;
        text-transform: uppercase;
        padding-bottom: 10px;
      }
      .footer-link {
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 12px;
        color: $white;
        line-height: 26px;
        &:hover {
          color: $primary;
        }
      }
      @media screen and (max-width: 767px) {
        .title-cardbey {
          display: none;
        }
      }
      .social-item {
        &:first-child {
          padding-left: 0;
        }
      }
    }
    .footer-link {
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 13px;
      color: $white;
      letter-spacing: 0;
      text-transform: uppercase;
      margin: 5px 0;
      white-space: nowrap;
    }
    .col{
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  .footer-dot {
    border-top: 1px solid $dot-white;
    opacity: 0.2;
  }
  .footer-social {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    .social-item {
      padding: 0 16px;
      display: inline;
    }
  }
  > .footer-title {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 18px;
    color: $white;
    letter-spacing: 0;
  }
  > .footer-norm {
    display: inline-block;
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 15px;
    color: $text-grey;
    letter-spacing: 0.5px;
    line-height: 28px;
    padding: 16px 0;
    &:last-child {
      margin-left: 46px;
    }
  }
  .info {
    .footer-title {
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 18px;
      color: $white;
      letter-spacing: 0;
    }
    .information {
      display: inline;
      .footer-norm {
        font-family: "Open Sans";
        font-size: 15px;
        color: $text-grey;
        letter-spacing: 0.5px;
        line-height: 28px;
        &:last-child {
          margin-left: 64px;
        }
      }
    }
  }
  .dropdown-language {
    .default-option {
      background-color: $dark !important;
    }
  }
}
