.service-staff-list {
    overflow: auto;
    white-space: nowrap;
  //   .staff-container:hover {
  //     .staff-info {
  //       opacity: 1;
  //     }
  //   }
    .staff-container {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 120px;
      height: 120px;
      display: inline-block;
      margin: 0 5px;
      .staff-image {
        opacity: 1;
        transition: all 0.3s;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
      }
      .staff-info {
        position: absolute;
        bottom: 0;
        background: #46b8da;
        color: white;
        width: 100%;
        padding-left: 4px;
        font-weight: bold;
        font-size: 9px;
        min-height: 20px;
        display: flex;
        align-items: center;
        opacity: 1;
        pointer-events: none;
        transition: all 0.2s;
      }
      .staff-check {
        position: absolute;
        right: 0;
        top: 0;
        background-color: #46b8da;
        width: 25px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 9px;
        color: white;
        border-top-right-radius: 5px;
        padding-left: 5px;
      }
    }
  }