@keyframes blink {
  0% {
      opacity: 1;
  }
  50% {
      opacity: 0.6;
  }
  100% {
      opacity: 1;
  }
}
.screen-ads {
  .map {
    height: 750px;
    position: relative;
    display: flex;
    // background-color: rgb(2, 15, 47, 0.8);
    .btn-back-store{
      margin-top: 5px;
      margin-left: 20px;
    }
    .mapboxgl-map {
      width: calc(100% - 600px) !important;
      height: 100%;

      @include breakpoint(768) {
        width: 100% !important;
      }
    }
    .search-box{
      margin-top: 10px;
      padding-left: 10px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      border-bottom:1px solid silver;
      padding-bottom: 10px;
      .select-box{
        margin-top: 5px;
        width: unset;
        min-width:11em;
      }
      .btn-filter{
        margin-top: 5px;
        line-height: 1em;
      }

    }
    .result {
      top: 0;
      left: 10px;
      height: 100%;
      width: 600px;
      background-color: #fff;
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      z-index: 3;

      @include breakpoint(768) {
        max-width: 100%;
        width: 100%;
        left: 0;
        position: absolute;
      }

      .main-title {
        border-bottom: 1px solid silver;
        padding: 0px 0 10px 20px;
        font-size: 35px;
        font-weight: 700;
        letter-spacing: .7px;
        line-height: 54px;
        display: flex;
        align-items: center;
        @include breakpoint(768) {
          font-size: 20px;
          i {
            display: flex !important;
          }
        }

        i {
          height: 40px;
          width: 40px;
          display: none;
          justify-content: center;
          align-items: center;
          border: 1px solid gray;
          border-radius: 50%;
          margin-left: 10px;
          font-size: 20px;
          cursor: pointer;

          &:hover {
            background-color: #f7f7f7;
          }
        }
      }

      .screen {
        margin: 16px 20px 0;
        border-bottom: 1px solid #f6f3f2;
        display: flex;
        border: 1px solid #131c33;
        border-radius: 10px;
        margin: 15px 5px;
        padding: 5px 0;
        .screen-img {
          background-color: #131c33;
          border-radius: 10px;
          display: flex;
          width: 55%;
          margin-left: 5px;
          position: relative;
          img {
            border-bottom-left-radius: 10px;
            border-top-left-radius: 10px;
            object-fit: cover;
            max-width: 200px;
            max-height: 120px;
            min-width: 200px;
            width: 60%;
          }

          .screen-price {
            color: #fff;
            font-weight: 500;
            font-size: 12px;
            padding: 3px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            @include breakpoint(768) {
              display: none;
            }
          }

          .screen-price-mobile {
            color: #fff;
            font-weight: 500;
            font-size: 12px;
            padding: 3px;
            text-align: center;
            display: none;
            align-items: center;
            justify-content: center;
            bottom: 0px;
            border-bottom-left-radius: 10px;
            width: 100%;
            border-bottom-right-radius: 10px;
            position: absolute;
            background-color: #3a4254a6;
            @include breakpoint(768) {
              display: flex;

            }
            }
          .new-label{
            animation: blink 1s infinite;
            position: absolute;
            left: 0px;
            top: 0px;
            background-color: red;
            padding: 0 15px 0 2px;
            font-weight: 600;
            font-size: 12px;
            text-align: left;
            color: #fff;
            transition: all .5s;
            pointer-events: none;
            -webkit-clip-path: polygon(0 0,0 100%,100% 100%,85% 100%,100% 0);
            clip-path: polygon(0 0,0 100%,100% 100%,85% 100%,100% 0);
            border-top-left-radius: 10px 10px;
            span{
              margin-left: 5px;
            }
          }
        }

        .screen-info {
          margin-left: 15px;
          color: #131c33;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        .store-name {
          cursor: pointer;
          color: #028ab8;
          font-size: 14px;
          font-weight: 700;
          letter-spacing: .4px;
        }

        .store-address {
          font-size: 9px;
        }

        .screen-name {
          font-size: 12px;
          font-weight: 700;
          color: #739399;
        }

        button {
          margin-top: 10px;
          padding: 5px;
          font-weight: 600;
        }

        .name {
          font-size: 11px;
        }
      }
    }

    .is-show {
      height: 120px;
      overflow: hidden;
    }

    .marker-img {
      width: 60px;

      &:hover {
        transform: scale(1.2);
      }
    }

    .card {
      img {
        width: 100%;
      }

      .content {
        padding: 10px;

        .header {
          font-weight: 700;
          font-size: 15px;

          a {
            color: #000;
          }
        }

        .meta {
          color: gray;
          font-size: 10px;
        }
      }
    }
  }
}

.text-warning-upload {
  color: red;
  font-size: 12px;
  font-weight: 500;
  margin-top: 15px;
  text-align: center;
}

.table-screens-share {
  overflow: auto;
  margin-bottom: 50px;
  video,
  img {
    max-width: 200px;
    object-fit: cover;
  }
}
.screen-detail{
    width: 360px;
    border: none;
    max-width: 360px;
    background: white;
    box-shadow: 0px 0px 3.5rem rgba(0, 0, 0, 0.45);
    left: auto;
    right: 0;
    .header{
      height: 50px;
    }
    .content{
      @include breakpoint(768) {
        padding-top: 115px!important;
      }
    }
    .box-icon{
      display: inline-block;
      margin-left: 5px;
    }
    .tag {
      margin-top: 5px;
      margin-bottom: 5px;
      span{
        font-style: italic;
        color: #16A085;
        font-size: 12px;
        font-weight: 600;

        margin-right: 5px;
        display: inline-block;
      }

    }
    .meta{
      font-size: 12px!important;
    }
    .screen-info{
      margin-top: 5px;
    }
    .business-name{
      font-size: 15px;
      margin: 10px 0px 10px 0px;
      display: flex;
    }
    .btn-request{
      margin-top: 10px;
      font-weight: bold;
    }
    .dt-btn-close{
      display: flex;
      padding: 0;
      max-height: 0;
      min-height: 0;
      align-items: center;
      justify-content: flex-end;

      .button{
        z-index: 5;
        margin-top: 34px;

      }

    }
}