.sidebar-container {
  padding: 0;
  background-color: white;
  width: 400px;
  max-width: 100vw;
  max-height: 90vh;
  overflow: auto;
  .sidebar-header {
    text-transform: uppercase;
    color: #000;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 0 16px;
    justify-content: space-between;
    .text-header {
      font-size: 15px;
      color: #000;
      font-weight: 500;
    }
    .icon-close {
      font-size: 18px;
      color: #ababab;
      cursor: pointer;
      font-weight: bold;
    }
  }

  .sidebar-detail {
    .slick-slider {
      padding: 0 !important;
      min-height: auto;
    }
    .thumb-image img {
      max-height: 300px;
      width: 100%;
      object-fit: cover;
    }
  }

  .divider-sidebar {
    height: 1px;
    background-color: #aaaaaa3b;
  }

  .sidebar-image {
    object-fit: contain;
    width: 100%;
    width: 400px;
    margin: 16px 0px;
  }

  .sidebar-content {
    margin: 4px 16px;
    .category-details {
      .category-content {
        padding: 0;
      }
    }
    .sidebar-title {
      font-size: 15px;
      color: #000;
      font-weight: 500;
      margin: 8px 0px;
    }
    .sidebar-des {
      font-size: 13px;
      color: #000;
      margin-bottom: 8px;
    }
  }
  .warpping-btn {
    display: flex;
    .btn-add-cart {
      background-color: #131c33;
      font-size: 13px;
      flex: 1;
      padding: 16px 16px;
      border-radius: 0px;
      text-align: center;
      color: white;
      margin: 0;
      cursor: pointer;
    }
  }

  .block-btn {
    display: flex;
    justify-content: center;
    margin: 10px 0;
    button {
      width: 80%;
    }
  }

  .price-options {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }

  .sidebar-option-list {
    margin: 8px;
    .sidebar-option-item {
      margin-left: 8px;
      margin-top: 8px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .option-control {
        .option-control-left {
          display: flex;
          flex-direction: row;
          gap: 3px;
        }
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-between;
        label {
          font-size: 13px;
          margin-bottom: 0px;
          margin-left: 8px;
          color: #676767;
        }
      }
    }
  }

  .sidebar-total-price {
    display: flex;
    justify-content: space-between;
    margin-right: 16px;
    .sidebar-total-title {
      font-weight: bold;
      font-size: 15px;
    }
  }

  .sidebar-option-content {
    border-top: 1px solid #aaaaaa3b;
    // height: 350px;
    // overflow: auto;
    .sidebar-group-options {
      margin: 4px 16px;
      height: auto;
      border: none;
      overflow: hidden;
      .sidebar-group-top {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        justify-content: space-between;
        background: #aaaaaa3b;
        padding: 0 8px;
      }
      .option-quantity {
        display: flex;
        flex-direction: row;
        font-size: 13px;
        font-weight: bold;
        justify-content: center;
        .option-button {
          padding: 0 8px;
          color: white;
          cursor: pointer;
          background-color: #028ab8;
        }
        .option-number {
          padding: 0 12px;
          border-top: 1px solid #028ab8;
          border-bottom: 1px solid #028ab8;
        }
      }
      .sidebar-group-text {
        font-size: 15px;
        color: #1c1c1c;
        margin: 8px 0px;
        font-weight: 500;
      }
    }
    .active {
      height: 45px;
      border-bottom: 1px solid #aaaaaa3b;
    }
  }
}

.cart-item-card {
  margin: 8px 0px;
  transition: all 0.5s;
  .con-cart-item {
    min-width: 250px;
    border: 1px solid #0000001f;
    border-radius: 4px;
    padding: 6px;
    .cart-item-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .sub-title {
        font-size: 11px;
        font-style: italic;
        margin-left: 5px;
      }
      font-weight: 500;
      .inner-title {
        font-weight: bold;
        font-size: 13px;
        padding-bottom: 2px;
      }
    }
    .active {
      height: auto !important;
    }
    .cart-item-content {
      transition: all 0.5s;
      height: 0px;
      overflow: hidden;
      padding-top: 2px;
      display: flex;
      flex-direction: column;
      .cart-body {
        padding: 10px 0 5px;
        border-bottom: 1px #aaaaaa70 solid;
        &:last-child {
					border-bottom: none;
				}
      }
      .cart-quantity {
        border: 1px solid #000;
        max-width: 93px;
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid dashed;
        .operator {
          color: #028ab8;
          flex: 1;
          justify-content: center;
          align-items: center;
          display: flex;
        }
      }
      .cart-option-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .label-control-cart {
          label {
            font-size: 12px;
          }
        }
        .price-cart {
          font-size: 12px;
          margin-bottom: 3px;
          padding-left: 5px;
          font-weight: 600;
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .sidebar-container {
    height: 90vh !important;
    margin-top: -55px;
  }
  // .sidebar-option-content {
  //   height: calc(100vh - 545px) !important;
  // }
  .warpping-btn {
    position: sticky;
    width: 100%;
    bottom: 0;
  }
}
