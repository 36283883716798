
@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon/fonts/icomoon.eot?akmph4');
  src:  url('../fonts/icomoon/fonts/icomoon.eot?akmph4#iefix') format('embedded-opentype'),
    url('../fonts/icomoon/fonts/icomoon.ttf?akmph4') format('truetype'),
    url('../fonts/icomoon/fonts/icomoon.woff?akmph4') format('woff'),
    url('../fonts/icomoon/fonts/icomoon.svg?akmph4#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ic-common"], [class*=" ic-common"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ic-common-pickup:before {
  content: "\e916";
}
.ic-common-table:before {
  content: "\e917";
}
.ic-common-qr-code:before {
  content: "\e915";
}
.ic-common-video-player:before {
  content: "\e914";
}
.ic-common-delivery:before {
  content: "\e910";
}
.ic-common-setting:before {
  content: "\e911";
}
.ic-common-order:before {
  content: "\e912";
}
.ic-common-booking:before {
  content: "\e913";
}
.ic-common-slide-show:before {
  content: "\e90f";
}
.ic-common-toggle:before {
  content: "\e90e";
}
.ic-common-arrow-down:before {
  content: "\e90d";
}
.ic-common-share:before {
  content: "\e903";
}
.ic-common-service:before {
  content: "\e904";
}
.ic-common-promotion:before {
  content: "\e905";
}
.ic-common-product:before {
  content: "\e906";
}
.ic-common-phone:before {
  content: "\e907";
}
.ic-common-overview:before {
  content: "\e908";
}
.ic-common-menu:before {
  content: "\e909";
}
.ic-common-mail:before {
  content: "\e90a";
}
.ic-common-logout:before {
  content: "\e90b";
}
.ic-common-location:before {
  content: "\e90c";
}
.ic-common-close:before {
  content: "\e902";
}
.ic-common-prev:before {
  content: "\e900";
}
.ic-common-next:before {
  content: "\e901";
}
