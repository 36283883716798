// ----------------------------------------------------------------------

  // Media Query Breakpoints

// ----------------------------------------------------------------------

// example @include breakpoint(940) { width:80%; }

@mixin breakpoint($size) {
  @media only screen and (max-width: $size + px) { @content; }
}

@mixin breakpoint-height($size) {
  @media only screen and (max-height: $size + px) { @content; }
}