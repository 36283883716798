#n_search {
    .heading-results {
        text-wrap: nowrap;
    }
    .form-overview {
        max-width: 300px;
        width: 300px;
        @include breakpoint(576) {
            max-width: 100%;
            width: 100%;
        }
    }
    .s_name {
        height: 30px;
        font-size: 11px;
    }
    .rc-tree-select {
        font-size: 11px;
    }
    .cate-label {
        background-color: $white;
        border: 1px solid #bcbbbb;
        border-radius: 20px;
        cursor: pointer;
        display: inline-block;
        margin: 0 5px 5px 0;
        user-select: none;
        padding: 5px 10px;
        font-weight: 500;
        &:hover, &.active {
            border: 1px solid #0077b5;
        }
    }
}