/* =============================================================== */
/* ADS DETAILS
/* =============================================================== */

.seller-info {
  padding: 5px;
}

.ads-image {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
}
.bx-wrapper {
  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    text-align: center;
  }
  .bx-prev {
    background: none !important;
  }
  .bx-next {
    background: none !important;
  }
  &:hover .bx-controls-direction a:after {
    opacity: 1;
  }
}
#bx-pager {
  text-align: center;
}

.ads-details-wrapper {
  .info-row {
    border-top: $border;
    margin-bottom: 15px;
    padding-top: 10px;
  }
  &.inner-box {
    padding-bottom: 0;
  }
  .content-footer {
    margin-left: -20px;
    margin-right: -20px;
  }
}
.ads-details-info {
  ul {
    margin-bottom: 20px;
  }
  ol {
    margin-bottom: 20px;
  }
  h4 {
    margin-bottom: 10px;
    padding-bottom: 0;
    padding-top: 5px;
  }
  .h4 {
    margin-bottom: 10px;
    padding-bottom: 0;
    padding-top: 5px;
  }
  h5 {
    margin-bottom: 10px;
    padding-bottom: 0;
    padding-top: 5px;
  }
  h6 {
    margin-bottom: 10px;
    padding-bottom: 0;
    padding-top: 5px;
  }
  .h6 {
    margin-bottom: 10px;
    padding-bottom: 0;
    padding-top: 5px;
  }
  h1 {
    margin-bottom: 15px;
    padding-bottom: 0;
    padding-top: 10px;
  }
  .h1 {
    margin-bottom: 15px;
    padding-bottom: 0;
    padding-top: 10px;
  }
  h2 {
    margin-bottom: 15px;
    padding-bottom: 0;
    padding-top: 10px;
  }
  .h2 {
    margin-bottom: 15px;
    padding-bottom: 0;
    padding-top: 10px;
  }
  h3 {
    margin-bottom: 15px;
    padding-bottom: 0;
    padding-top: 10px;
  }
  .h3 {
    margin-bottom: 15px;
    padding-bottom: 0;
    padding-top: 10px;
  }
}
.h5 {
  margin-bottom: 10px;
  padding-bottom: 0;
  padding-top: 5px;
}
@include breakpoint($xxs-only) {
  .breadcrumb  {
    float: none !important;
    text-align: center !important;
    display: inline-flex;
    margin: 0 auto;
    // padding: 0 !important;
    font-size: 11px;
    // margin-bottom: 0 !important;
  }
  nav[aria-label="breadcrumb"] {
    float: none !important;
    text-align: center !important;
    width: 100%;
  }
  .backtolist {
    float: none !important;
    margin: 0 auto;
    padding: 0 0 10px;
    text-align: center;
    font-size: 11px;
    margin-bottom: 10px;
  }
}

h1 {
  &.pricetag span {
    float: right;
  }
  &.trtri span {
    float: right;
  }
}
.list-dot {
  margin-left: 30px;
  > li {
    list-style: disc;
  }
}
.list-circle {
  margin-left: 30px;
  > li {
    list-style: circle;
  }
}
.card-details {
  border: $border;
  background: #f8f8f8;
  position: relative;
}
.content-footer {
  background: #f8f8f8;
  position: relative;
  padding: 10px;
  border-top: $border;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05) inset;
  &.expand {
    margin-left: -20px;
    margin-right: -20px;
  }
  .btn {
    margin-right: 8px;
  }
}
.card-footer {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05) inset;
}
@media(max-width: 360px) {
  .content-footer .btn {
    margin-bottom: 4px;
  }
}
.text-count {
  color: #9a9a9a;
  font-size: 12px;
}
.modal {
  z-index: 1100;
}
.modal-title {
  padding-bottom: 0;
}
.modal-header {
  border-bottom: $border;
  border-radius: 3px 3px 0 0;
  font-weight: bold;
  background:  #f8f8f8;
  border-top: $border;
  padding: 8px;
  position: relative;
}
.breadcrumb {
  background: none;

  border-radius: 0;
  list-style: outside none none;
  margin-bottom: 8px;
  padding: 8px 0;
}
.backtolist {
  padding: 8px 0;
}
.page-sidebar-right {
  padding-left: 10px;
}
.user-info {
  h3 {
    padding-bottom: 5px;
  }
  p {
    margin-bottom: 0;
  }
}
.user-ads-action {
  margin-top: 15px;
}
@media(max-width: 767px) {
  .user-ads-action .btn:first-child {
    margin-bottom: 5px;
  }
}
.card-header {
  font-family: $base-font-condensed;
  font-size: 16px;
}
.sidebar-card {
  margin-bottom: 20px;
  .card-header {
    background: #f8f8f8;
    position: relative;
    border-bottom: $border;
    font-size: 16px;
    line-height: 22px;
  }
}
.card-contact-seller .card-body {
  padding: 10px;
}
.ads-image .bx-wrapper {
  /*rtl:ignore*/
  direction: ltr;

  margin-bottom: 20px;
  border:0;
  box-shadow: none;
  .bx-viewport {
    border: 0 none;
    box-shadow: none;
    left: 0;
    border-radius: 3px;
  }
}
.bltri:before {
  content: '';
  position: absolute;
  height: 0;
  width: 0;
}
.tltri:before {
  content: '';
  position: absolute;
  height: 0;
  width: 0;
}
.trtri:before {
  content: '';
  position: absolute;
  height: 0;
  width: 0;
}
.list-border > li {
  border-top: 1px solid #ebebeb;
  line-height: 36px;
  position: relative;
  &:first-child {
    border: none;
  }
}
.list-check > li {
  margin-bottom: 3px;
  padding-left: 18px;
  position: relative;
  &:after {
    position: absolute;
    font-family: fontello;
    content: '\e80d';
    left: 0;
    top: 0;
  }
}
.list-number > li {
  list-style: decimal;
  > ul > li {
    list-style: decimal;
  }
}

.ads-action {
  font-family: $base-font-condensed;
  li {
    i {
      width: 20px;
    }
  }
}
@media screen and(max-width: 480px) {
  .slider-left {
    width: 80%;
    width: calc(100% - 90px);
  }
  .ads-img-v2 {
    #bx-pager {
      width: 70px;
    }
    .thumb-item-link img {
    }
  }
}

label.error {
  color: #e74c3c;
  font-size: 90%;
}