body {
  background-color: $white;
}
.main-category {
  background-color: $category-bg;
}
.homepage-container {
  .home-content {
    display: flex;
    .left-menu {
      min-width: 240px;
      padding-top: 10px;
      @include breakpoint(768) {
        display: none;
      }
      .nav-content {
        padding: 10px 20px;
      }
      .nav-list {
        border-bottom: 1px solid rgba(22, 24, 35, 0.12);
        padding-bottom: 10px;
      }
      .nav-link {
        color: #161823;
        font-size: 16px;
        font-weight: 700;
        padding-left: 0;
        text-transform: capitalize;
      }
      .icon {
        font-size: 22px;
        margin-right: 10px;
      }
      .second-nav-list {
        .nav-link {
          font-size: 14px;
        }
      }
      .term-nav-list {
        border: 0;
        .nav-link {
          color: rgba(22, 24, 35, 0.5);
          font-size: 13px;
        }
      }
    }
    .main-content {
      width: calc(100% - 240px);
      height: calc(100vh - 80px);
      @include breakpoint(768) {
        width: 100%;
      }
    }
  }
  .slider-container {
    position: relative;
    max-width: 415px;
    margin: 0 auto;
    .action-button {
      right: -50px;
    }
  }
  .banner-container {
    padding: 20px 0;
    // width: 80vw;
    margin: 0 auto;
    height: 100%;
    .info-stores {
      position: absolute;
      max-width: 200px;
      margin: 0 auto;
      display: block;
      width: 100%;
      left: 50%;
      top: 28%;
      transform: translate(-50%, -50%);
      animation: fadeIn 2s ease-in-out;
      .logo-stores {
        max-width: 150px;
        margin: 0 auto;
        position: relative;
        width: 150px;
      }
      .name-store {
        margin-top: 5px;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        p {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          /* autoprefixer: off */
        }
      }
      a {
        overflow: hidden;
      }
      img {
        border-radius: 10px;
        object-fit: cover;
        max-height: 150px;
        max-width: 150px;
      }
    }
    .slide-items {
      position: absolute;
      margin: 0 auto;
      max-height: 100px;
      display: block;
      width: 100%;
      bottom: 0;
      .slide-item {
        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          background: rgba(0, 0, 0, 0.2);
          margin: 0 5px 0 5px;
          .item-name {
            width: 100%;
            padding-left: 2px;
            color: white;
            display: -webkit-box;
            margin-bottom: 3px;
            font-size: 10px;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            /* autoprefixer: off */
          }
          img {
            width: 100%;
            height: 65px;
            object-fit: cover;
          }
        }
      }
    }
    .action {
      cursor: pointer;
      flex-direction: column;
      padding: 10px 5px;
      position: absolute;
      z-index: 2;
      img {
        width: 30px;
        filter: brightness(0) invert(1);
      }
      li {
        margin: 15px 0 0 0 !important;
        i {
          background-color: rgba(22, 24, 35, 0.06);
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 23px;
          color: #161823;
          width: 48px;
          height: 48px;
          @include breakpoint(768) {
            font-size: 18px;
            color: #fff;
            background-color: rgba(22, 24, 35, 0.4);
            width: 35px;
            height: 35px;
            svg {
              color: #fff;
              fill: #fff;
            }
          }
        }
      }
    }
    .block-action {
      .action-button {
        z-index: 2;
        position: absolute;
      }
      .button {
        align-items: center;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        width: 40px;
        height: 40px;
        justify-content: center;
      }
      img {
        width: 25px;
        height: 25px;
      }
    }
    @media (min-width: 768px) {
      .banner-slider {
        margin: 0 auto;
        video {
          object-fit: cover;
          overflow: hidden;
          border-radius: 15px;
        }
      }
      .w-banner {
        width: 315px;
      }
      .block-action {
        margin: 0 auto;
        position: relative;
        .action-button {
          right: -70px;
          top: 260px;
          .btn-action2 {
            margin-bottom: 10px;
          }
        }
        .action-pc {
          right: 100px;
        }
        .action-mobile {
          right: -70px;
        }
        .action {
          border-radius: 10px;
          color: #fff;
          bottom: 0;
        }
      }
    }
    @include breakpoint(767) {
      .block-action {
        .action-button {
          position: unset;
          .button {
            position: absolute;
            top: 70%;
            z-index: 2;
          }
          .btn-action1 {
            left: 10px;
          }
          .btn-action2 {
            right: 10px;
          }
        }
      }
      color: white;
      margin-top: -1px;
      .action {
        border-radius: 30px;
        top: 80px;
        right: 10px;
      }
      // width: 100vw;
      height: 85vh;
      padding: 0;
      .banner-slider,
      .slick-list,
      .slick-track,
      .slick-slide,
      .slick-slide > div,
      video,
      .banner-item {
        // height: 100% !important;
      }
      video,
      .banner-item {
        max-height: 100% !important;
      }
    }
    .o-f-contain {
      object-fit: contain !important;
      background-color: $black;
    }
    .o-f-cover {
      object-fit: cover !important;
    }
    .banner-empty {
      color: $black;
      position: relative;
      text-align: center;
      font-size: 30px;
      min-height: 600px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .view-all {
      color: #028ab8;
      cursor: pointer;
      max-width: 1170px;
      margin: 0 auto;
      font-weight: 600;
      padding-bottom: 5px;
      &__pc {
        width: 1170px;
      }
      &__mobile {
        width: 310px;
      }
      @include breakpoint(992) {
        display: none;
      }
    }
    .view-all__sp {
      display: none;
      position: absolute;
      top: 30px;
      right: 0;
      background: rgba(0, 0, 0, 0.2);
      padding: 5px 10px;
      width: auto;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      z-index: 2;
      @include breakpoint(992) {
        display: block;
      }
    }
  }
  @media (min-width: 768px) {
    .pc {
      max-width: 1170px;
      width: 1170px;
    }
    .mobile {
      width: 315px;
      max-width: 315px;
    }
    .mobile-slide {
      width: 415px;
      max-width: 415px;
    }
  }
  .banner-home {
    margin: 0 auto;
    display: block !important;
    overflow: hidden;
    border-radius: 10px;
    @include breakpoint(768) {
      border-radius: 0;
    }
    .btn-now {
      background: #4e6ac7;
      border-radius: 3px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      bottom: 85px;
      border: none;
      font-size: 14px;
      width: 135px;
      // height: 35px;
      animation: fadeIn 2s ease-in-out;
      img {
        width: 16px;
        margin-left: 5px;
        display: inline;
      }
    }
    .banner-bussiness__name {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  video {
    // height: 100%;
  }
  .action {
    li {
      a {
        color: #000;
      }
    }
    .icon-heart-empty-1 {
      margin-top: -3px;
    }
    img {
      width: 18px;
      cursor: pointer;
    }
    i {
      vertical-align: middle;
      font-size: 18px;
      cursor: pointer;
    }
    .react-num {
      color: rgba(22, 24, 35, 0.75);
      font-size: 12px;
      font-weight: bold;
      margin-top: -6px;
      margin-top: 5px;
    }
  }
}
.business {
  .banner-item {
    height: 450px;
  }
}
.banner-container {
  position: relative;
  .slick-slide {
    height: calc(100vh - 120px);
    transition: opacity 2s ease-in-out !important;
    & > div {
      height: 100%;
    }
  }
  .slick-arrow.slick-next::before {
    font-family: "icomoon";
    content: "\E901";
    color: #000;
  }
  .slick-arrow.slick-prev::before {
    font-family: "icomoon";
    content: "\E900";
    color: #000;
  }

  .banner-slider {
    .banner-item {
      height: 100%;
    }
  }
  .banner-item {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  &.banner-category {
    .banner-image {
      max-height: 600px;
      min-height: 600px;
    }
    .banner-image-over {
      max-height: 570px;
      min-height: 570px;
    }
  }
  .btn-store {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translate(-50%, 0);
    background: $yellow;
    border: 1px solid $yellow;
    &:hover {
      background-color: $hover-button-banner;
    }
  }
  .slick-dots {
    position: absolute;
    bottom: 10px;
    display: none;
    li {
      &.slick-active {
        button {
          &::before {
            color: $dot-black;
          }
        }
      }
      button {
        &::before {
          font-size: 9px;
          color: $dot-white;
          opacity: 1;
        }
      }
    }
  }
  .slick-dotted {
    margin-bottom: 0;
    li {
      &.slick-active {
        button {
          &::before {
            color: $dot-black;
          }
        }
      }
      button {
        &::before {
          font-size: 9px;
          color: $dot-white;
          opacity: 1;
        }
      }
    }
  }
  .banner-image {
    width: 100%;
    min-height: auto;
  }
}
.video-container {
  .slick-slide {
    transition: opacity 2s ease-in-out !important;
  }
  .videos-slider {
    .list-slider {
      .slider-content-main {
        .banner-image-over {
          max-height: 355px;
          min-height: 355px;
          width: 100%;
        }
        .banner-video-over {
          position: relative;
          width: 100% !important;
          max-height: 355px;
          min-height: 355px;
        }
      }
    }
  }
  .btn-store {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translate(-50%, 0);
    background: $yellow;
    border: 1px solid $yellow;
    &:hover {
      background-color: $hover-button-banner;
    }
  }
  .slick-dots {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    li {
      margin: 0px;
      &.slick-active {
        button {
          &::before {
            color: #131c33 !important;
            border: 1px solid #ffffff !important;
            background-color: #ffffff !important;
            border-radius: 5px !important;
          }
        }
      }
      button {
        &::before {
          font-size: 9px;
          color: $dot-white;
          opacity: 1;
        }
      }
    }
  }
  .slick-dotted {
    margin-bottom: 0;
    li {
      &.slick-active {
        button {
          &::before {
            color: $dot-black;
          }
        }
      }
      button {
        &::before {
          font-size: 9px;
          color: $dot-white;
          opacity: 1;
        }
      }
    }
  }
  .banner-image {
    width: 100%;
    min-height: auto;
  }
}
.bussiness-container {
  padding: 40px;
  .bussiness-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &:after {
      content: "";
      flex: auto;
    }
  }
  .bussiness-item {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $white;
    box-shadow: 0 2px 14px 0$boxshadow;
    border-radius: 12px;
    padding: 16px;
    margin: 10px 0;
    min-height: 64px;
    max-height: 64px;
    transition: all 0.3s ease-in-out;
    &:hover {
      cursor: pointer !important;
      box-shadow: 0 2px 14px 5px $hover-boxshadow;
    }
    .bussiness-icon {
      max-width: 36px;
    }
    .bussiness-title {
      font-family: "Montserrat";
      font-weight: 800;
      font-size: 13px;
      color: $text-title;
      letter-spacing: 0;
      padding-left: 10px;
      white-space: normal;
    }
  }
}
.bestpicks-list {
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-between;
  // background-color: $white;
  // overflow: hidden;
  // width: 100%;
  &:after {
    content: "";
    flex: auto;
  }
  .bestpicks-item {
    box-sizing: border-box;
    margin: 10px 0;
    padding: 10px;
    .bestpicks-video {
      height: auto !important;
      width: auto !important;
      video {
        min-height: 162px;
        box-shadow: 0 0 10px -2px rgba($color: #000000, $alpha: 0.26);
        background-color: #ffffff;
      }
    }
    .bestpicks-img {
      border-radius: 5px;
      width: 100%;
      transition: all 0.3s ease-in-out;
      box-shadow: 0 0 10px -2px rgba($color: #000000, $alpha: 0.26);
      max-height: 162px;
      object-fit: contain;
      &:hover {
        cursor: pointer;
        box-shadow: 0 2px 14px 5px $hover-boxshadow;
      }
    }

    .bestpicks-img-tooltip {
      width: calc(100% / 5);
      border-radius: 5px;
      width: 100%;
      transition: all 0.3s ease-in-out;
      box-shadow: 0 0 10px -2px rgba($color: #000000, $alpha: 0.26);
      max-height: 162px;
    }
    .bestpicks-title {
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 14px;
      color: $dark;
      letter-spacing: 0;
      text-align: center;
      padding-top: 10px;
      font-weight: 700;
    }
  }
}
.featured-container {
  overflow: hidden;
}
.featured-content {
  .featured-list-tab {
    display: flex;
    .featured-tab {
      display: inline-block;
      cursor: pointer;
      &.tab-active {
        span {
          &::after {
            width: 100%;
          }
        }
      }
      span {
        position: relative;
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 19px;
        color: $dark;
        letter-spacing: 0;
        font-weight: 700;
        &:hover {
          &::after {
            width: 100%;
          }
        }
        &:after {
          content: "";
          position: absolute;
          bottom: -10px;
          left: 0;
          height: 4px;
          width: 0%;
          background-color: $yellow;
          transition: all 0.3s ease-in-out;
          border-radius: 30px;
        }
      }
    }
  }
  .view-all {
    position: relative;
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 15px;
    color: $primary;
    letter-spacing: 0;
    font-weight: 700;
    cursor: pointer;
    &:hover {
      &:after {
        width: 70%;
      }
    }
    &:after {
      position: absolute;
      left: 0;
      bottom: -3px;
      content: "";
      width: 0%;
      height: 2px;
      border-radius: 20px;
      background-color: $primary;
      transition: all 0.3s ease-in-out;
    }
    .icon {
      padding-left: 8px;
    }
  }
  .featured-slider {
    width: 100%;
    .slick-slide {
      div {
        width: 100%;
      }
    }
    .slick-dots {
      margin-bottom: 0;
      li {
        &.slick-active {
          button {
            &::before {
              color: $dot-black;
            }
          }
        }
        button {
          &::before {
            font-size: 9px;
            color: $grey;
            opacity: 1;
          }
        }
      }
    }
    .slick-arrow {
      &.slick-prev {
        left: -40px;
        &::before {
          font-family: "icomoon";
          content: "\e900";
          color: $dark;
          font-size: 50px;
        }
      }
      &.slick-next {
        right: -40px;
        &::before {
          font-family: "icomoon";
          content: "\e901";
          color: $dark;
          font-size: 50px;
        }
      }
    }
    div {
      display: flex;
      > .inner {
        width: 38%;
        &.inner-only {
          width: 100%;
          img {
            object-fit: contain;
          }
        }
        &.inner-first {
          width: 50% !important;
          padding-right: 10px;
        }
        &.inner-two {
          width: 50% !important;
          padding-left: 10px;
        }
        .inner-link {
          width: 100%;
        }
        &.inner-row {
          width: 24%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 0 8px;
          .inner-children {
            height: 50%;
            &:first-child {
              padding-bottom: 5px;
            }
            &:last-child {
              padding-top: 5px;
            }
          }
        }
        .inner-img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }
    }
  }
}
.learn-more-container {
  background-color: $category-bg;
  padding: 60px;
  .strong {
    display: block;
    margin: 60px 0 30px;
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 500;
    font-size: 18px;
    color: $text-strong;
    letter-spacing: 0;
    text-align: center;
    line-height: 27px;
  }
  .process-content {
    .process-img {
      width: 100%;
    }
  }
}
.online-container {
  padding: 100px 0;
  .online-content {
    padding: 0 50px;
  }
  .online-title {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 30px;
    color: $dark;
    letter-spacing: 0;
    line-height: 30px;
  }
  .online-norm {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 18px;
    color: $text-online-norm;
    letter-spacing: 0;
    line-height: 27px;
  }
  .online-link {
    position: relative;
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 15px;
    color: $primary;
    letter-spacing: 0;
    font-weight: 700;
    &:hover {
      &:after {
        width: 80%;
      }
    }
    &:after {
      position: absolute;
      left: 0;
      bottom: -3px;
      content: "";
      width: 0%;
      height: 2px;
      border-radius: 20px;
      background-color: $primary;
      transition: all 0.3s ease-in-out;
    }
    .icon {
      padding-left: 8px;
    }
  }
  .online-img {
    width: 100%;
  }
}
.application-container {
  .application-video {
    width: 100%;
    height: 100%;
    .application-img {
      max-height: 100%;
      min-height: 100%;
      width: 100%;
    }
  }
  .application-content {
    background: rgba($color: #d8f1bd, $alpha: 0.2);
    border-radius: 5px;
    .video-custom {
      width: 100%;
      height: 450px;
    }
  }
  .application-info {
    padding: 30px;
    .application-title {
      font-family: "Montserrat";
      font-weight: 600;
      font-size: 30px;
      color: $dark;
      letter-spacing: 0;
    }
    .application-norm {
      font-family: "Montserrat";
      font-weight: 400;
      font-size: 18px;
      color: $text-online-norm;
      letter-spacing: 0;
      line-height: 27px;
    }
  }
}
.social-container {
  position: relative;
  margin-top: 100px;
  overflow: hidden;
  .social-content {
    padding: 200px 0;
    z-index: 2;
    .social-title {
      font-family: "Montserrat";
      font-weight: 600;
      font-size: 30px;
      color: $text-title;
      letter-spacing: 0;
    }
    .social-norm {
      font-family: "Montserrat";
      font-weight: 400;
      font-size: 18px;
      color: $text-strong;
      letter-spacing: 0;
      line-height: 27px;
    }
    .social-list {
      display: flex;
      padding: 20px 0;
      .social-item {
        &:not(:first-child) {
          margin-left: 20px;
        }
      }
      .social-img {
        height: 60px;
      }
    }
  }
  .social-bg {
    width: 700px;
    .social-img {
      position: absolute;
      z-index: 1;
      right: 0;
      bottom: 0;
      width: auto;
      height: 100%;
    }
  }
  .social-bg-reponsive {
    .social-img-reponsive {
      width: 100%;
      display: none;
    }
  }
}
.testimontals-container {
  position: relative;
  overflow: hidden;
  padding: 70px 0;
  background-image: url("/assets/images/home/bg-testimontals.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .testimontals-content {
    background-image: url("/assets/images/home/quote.svg");
    background-repeat: no-repeat;
    background-position: left -15%;
    background-size: 30%;
    padding-top: 80px;
    .testimontals-title {
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 30px;
      color: $text-testimontals;
      letter-spacing: 0;
      font-weight: 700;
      line-height: 36px;
    }
    .testimontals-info {
      padding: 50px 0;
      .testimontals-name {
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 24px;
        color: $text-testimontals;
        letter-spacing: 0;
      }
      .testimontals-norm {
        font-family: "Montserrat";
        font-size: 18px;
        color: $text-grey;
        letter-spacing: 0.5px;
      }
    }
  }
  .testimontals-slider {
    position: relative;
    img {
      width: 100%;
    }
    .slick-dots {
      margin-bottom: 0;
      li {
        &.slick-active {
          button {
            &::before {
              color: $dot-black;
            }
          }
        }
        button {
          &::before {
            font-size: 9px;
            color: $grey;
            opacity: 1;
          }
        }
      }
    }
    .slick-arrow {
      &.slick-prev {
        position: absolute;
        left: 70%;
        bottom: 25%;
        top: unset;
        z-index: 9999;
        transform: rotate(180deg);
        &.slick-disabled {
          transform: rotate(0deg);
          &::before {
            content: url("/assets/images/home/arrow-5.svg");
          }
        }
        &::before {
          content: url("/assets/images/home/arrow-6.svg");
        }
      }
      &.slick-next {
        position: absolute;
        left: 75%;
        top: unset;
        bottom: 25%;
        z-index: 9999;
        transform: rotate(0deg);
        &.slick-disabled {
          transform: rotate(180deg);
          &::before {
            content: url("/assets/images/home/arrow-5.svg");
          }
        }
        &::before {
          content: url("/assets/images/home/arrow-6.svg");
        }
      }
    }
  }
}

.video-now-slider-responsive {
  position: relative;
  width: 100%;
  display: none;
  .slider-item {
    .inner-img {
      width: 100%;
      height: 392px;
    }
  }
  .slick-arrow {
    &.slick-prev {
      left: -40px;
      transform: rotate(180deg);
      &::before {
        content: url("/assets/images/home/arrow-next.svg");
      }
    }
    &.slick-next {
      right: -40px;
      &::before {
        content: url("/assets/images/home/arrow-next.svg");
      }
    }
  }
}

.video-now-slider {
  .slider-item {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    > .inner {
      flex: 1;
      padding-right: 17px;
      .inner-img {
        height: 100%;
        width: 100%;
        border-radius: 10px;
        height: 500px;
      }
    }
    .inner-row {
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .inner-children {
        height: 48.5%;
        .inner-img {
          height: 100%;
          width: 100%;
          border-radius: 10px;
        }
      }
    }
  }
  .slider-item-video {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    padding-bottom: calc(100% * 9 / 16);
    > .inner {
      flex: 1;
      padding-right: 17px;
      width: 100%;
      height: 100%;
      .inner-img {
        position: absolute;
        width: 100%;
        border-radius: 10px;
        height: 100%;
      }
    }
    .inner-row {
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .inner-children {
        height: 48.5%;
        .inner-img {
          height: 100%;
          width: 100%;
          border-radius: 10px;
        }
      }
    }
  }
  .slick-arrow {
    &.slick-prev {
      left: -40px;
      transform: rotate(180deg);
      &::before {
        content: url("/assets/images/home/arrow-next.svg");
      }
    }
    &.slick-next {
      right: -40px;
      &::before {
        content: url("/assets/images/home/arrow-next.svg");
      }
    }
  }
}

.shop-now-slider-responsive {
  position: relative;
  width: 100%;
  display: none;
  .slider-item {
    .inner-img {
      width: 100%;
      height: 500px;
    }
  }
  .slick-arrow {
    &.slick-prev {
      left: 0px;
      z-index: 99;
      &::before {
        font-family: "icomoon";
        content: "\e900";
        color: $dark;
      }
    }
    &.slick-next {
      right: 0px;
      z-index: 99;
      &::before {
        font-family: "icomoon";
        content: "\e901";
        color: $dark;
      }
    }
  }
}

// shop-now-slider.
.shop-now-slider {
  width: 100%;
  height: 500px;
  .slider-item {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    > .inner {
      flex: 1;
      padding-right: 17px;
      .inner-img {
        height: 100%;
        width: 100%;
        border-radius: 10px;
        height: 500px;
      }
    }
    .inner-row {
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .inner-children {
        height: 48.5%;
        .inner-img {
          height: 100%;
          width: 100%;
          border-radius: 10px;
        }
      }
    }
  }
  .slick-arrow {
    &.slick-prev {
      left: -40px;
      transform: rotate(180deg);
      &::before {
        content: url("/assets/images/home/arrow-next.svg");
      }
    }
    &.slick-next {
      right: -40px;
      &::before {
        content: url("/assets/images/home/arrow-next.svg");
      }
    }
  }
}

.heading-container {
  .heading-title {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 30px;
    color: #000000;
    letter-spacing: 0;
    line-height: 36px;
    @include breakpoint(678) {
      font-size: 22px;
      margin-bottom: 0;
    }
  }
  .heading-norm {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 18px;
    color: $text-strong;
    letter-spacing: 0;
    line-height: 27px;
    @include breakpoint(678) {
      font-size: 14px;
      margin-bottom: 0;
    }
  }
}
.bars.toggle-menu-header {
  font-size: 25px;
  color: rgb(241, 241, 241);
  cursor: pointer;
}
.featured-content .featured-slider div > .inner .inner-img {
  object-fit: cover;
}
.slick-slider.featured-slider.slick-initialized {
  height: 500px;
  display: flex;
  .slick-list {
    width: 100%;
  }
}

.leftfloating {
  height: 80px;
  box-shadow: 0 2px 14px 5px rgba(163, 176, 192, 0.62);
  width: calc(100vw * 0.25); // 2/12 column
  overflow: hidden;
  background: white;
  border-radius: 40px;
  display: inline-block;
  z-index: 99;
  bottom: 20px;
  left: 20px;
  position: fixed !important;
  .gm-control-active,
  .gm-fullscreen-control {
    display: none;
  }
  img {
    height: 60px;
    width: 60px;
    object-fit: fill;
    border-radius: 50%;
    border: black 2px solid;
    margin-left: 10px;
    float: left;
  }
  .content {
    margin: 10px;
    width: calc(100% - 85px);
    vertical-align: top;
    margin-left: 75px;
    margin-right: 10px;
    position: relative;
    line-height: 1;
    height: 60px;
    top: 0px;
    overflow: hidden;
    h3 {
      display: inline-block;
      max-height: 20px;
      overflow: hidden;
      width: 100%;
      color: #000;
      b {
        line-height: 17px;
        font-size: 17px;
      }
    }
    p {
      color: #000;
      display: inline-block;
      height: 15px;
      font-size: 12px;
      overflow: hidden;
      width: 100%;
      margin: 0;
      white-space: nowrap;
      &.first {
        padding-bottom: 5px;
      }
      &.second {
        padding-top: 5px;
      }
    }
    .textblue {
      color: #1eb7f1;
    }
  }
}

@media screen and (max-width: 600px) {
  .leftfloating {
    top: auto !important;
    left: 20px !important;
    bottom: 20px !important;
    position: fixed !important;
    height: 80px;
    width: calc(100vw * 0.5); // 2/12 column
    overflow: hidden;
    background: white;
    border-radius: 40px;
    display: inline-block;
  }
}

.notification-show {
  transition: bottom 2s ease-in-out;
  bottom: 20px;
}

.notification-hide {
  transition: bottom 2s ease-in-out;
  bottom: -100px !important;
}

@-webkit-keyframes notiSlideInVerticalTop {
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-o-keyframes notiSlideInVerticalTop {
  to {
    opacity: 1;
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes notiSlideInVerticalTop {
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes notiSlideOutVerticalBottom {
  to {
    opacity: 1;
    -webkit-transform: translateY(150px);
    transform: translateY(150px);
  }
}

@-o-keyframes notiSlideOutVerticalBottom {
  to {
    opacity: 1;
    -o-transform: translateY(150px);
    transform: translateY(150px);
  }
}

@keyframes notiSlideOutVerticalBottom {
  to {
    opacity: 1;
    -webkit-transform: translateY(150px);
    -o-transform: translateY(150px);
    transform: translateY(150px);
  }
}

.promotions-count {
  font-size: 11px;
  display: inline-block;
  background-color: red;
  color: #fff;
  padding: 3px;
  font-weight: 700;
  position: absolute;
  // top: 3px;
  // right: -5px;
  min-width: 17px;
  height: 17px;
  line-height: 1;
  // margin-left: 17px;
  margin-left: 25px;
  margin-top: 8px;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.clear-transform {
  transform: translate3d(0, 0, 0) !important;
}
