.container-category {
  position: fixed;
  left: 0;
  top: 80px;
  background-color: #fff;
  z-index: 9999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  .button {
    min-width: 100px;
    padding: 8px;
    font-family: "Montserrat";
    font-weight: bold;
    background-color: #e9e8e8;
    border: 1px solid #e9e8e8;
    &.active {
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.2)
    }
    &:hover {
      background-color: #fff;
      border: 1px solid #fff;
    }
  }
}
.mega-container {
  position: fixed;
  left: 0;
  top: calc(80px + 38px);
  background-color: #fff;
  z-index: 9999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &.mega-container-sticky {
    top: calc(50px + 38px);
  }
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: grey;
  }
  .mega-menu-parent {
    max-height: 500px;
    overflow: auto;
    overflow-y: scroll;
  }
  .mega-menu-item {
    min-width: 300px;
    max-height: 80vh;
    overflow-y: auto;
    &.mega-menu-item-active {
      > .menu-mega-item {
        color: #fc8004;
        border-bottom: 2px solid rgba(0, 0, 0, 0.1);
        &::before {
          height: 100%;
          opacity: 1;
          visibility: visible;
        }
      }
    }
    > .menu-mega-item {
      position: relative;
      display: block;
      width: 100%;
      position: relative;
      padding: 10px 26px 10px 16px;
      &:not(.no-children){
        &::after {
          display: none;
        }
      }
      &.menu-item-all {
        color: #0e9ff1;
        &:hover {
          color: #0e9ff1;
          cursor: pointer;
        }
        &::after {
          display: none;
        }
        &::before {
          display: none;
        }
      }
      &:hover {
        color: #fc8004;
        cursor: pointer;
        border-bottom: 2px solid rgba(0, 0, 0, 0.1);
        &::before {
          height: 100%;
          opacity: 1;
          visibility: visible;
        }
        &::after {
          color: rgba(0, 0, 0, 0.6);
        }
      }
      &::before {
        content: "";
        position: absolute;
        width: 3px;
        height: 0%;
        left: 0;
        top: 0;
        background-color: #fc8004;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease-in-out;
      }
      &::after {
        position: absolute;
        right: 10px;
        top: 10px;
        font-family: "Font Awesome 5 Free";
        content: "\f105";
        font-weight: 900;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
  .menu-children {
    width: 300px;
    padding: 10px 26px;
    border-left: 2px solid rgba(0, 0, 0, 0.1);
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
    .wrapper-menu-children {
      display: flex;
      flex-wrap: nowrap;
      .mega-menu-content {
        .mega-menu-header {
          font-size: 14px;
          color: #0e9ff1;
          font-family: "Montserrat";
          font-weight: 700;
        }
        > .mega-menu-item {
          position: relative;
          display: block;
          width: 100%;
          position: relative;
          padding: 6px 30px 6px 0px;
          .mega-menu-norm {
            &:hover {
              color: #fc8004;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .container-category {}
  .mega-container {
    .menu-children {
      width: 300px;
      .wrapper-menu-children {
        flex-wrap: wrap;
        .mega-menu-content {
          width: 100%;
        }
      }
    }
  }
}
