// Variables
//
// Copy settings from this file into the provided `_custom.scss` to override
// the Bootstrap defaults without modifying key, versioned files.


// Table of Contents
//
// Colors
// Options
// Spacing
// Body
// Links
// breakpoints
// Typography
// Components


@mixin _assert-ascending($map, $map-name) {
  $prev-key: null;
  $prev-num: null;
  @each $key, $num in $map {
    @if $prev-num == null {
      // Do nothing
    } @else if not comparable($prev-num, $num) {
      @warn "Potentially invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} whose unit makes it incomparable to #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    } @else if $prev-num >= $num {
      @warn "Invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} which isn't greater than #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    }
    $prev-key: $key;
    $prev-num: $num;
  }
}

// General variable structure
//
// Variable format should follow the `$component-modifier-state-property` order.




// ----------------------------------------------------------------------
// color
// ----------------------------------------------------------------------
// Start with assigning color names to specific hex values.

$white:  #fff !default;
$black:  #000 !default;
$red:    #d9534f !default;
$orange: #f0ad4e !default;
$yellow: #fbbd08 !default;
$green:  #5cb85c !default;
$blue:   #0275d8 !default;
$teal:   #5bc0de !default;
$pink:   #ff5b77 !default;
$purple: #613d7c !default;
// function component
$heading: #131c33;
$icon-search: #000;
// color
$primary: #028ab8;
$yellow: #cba411;
$blue: #59aa00;
$white: #fff;
$dark: #000;
$grey: #e8e8e8;
$danger: #e22121;
// color text
$text-online-norm: #595959;
$text-title: #333;
$text-strong: #999;
$text-testimontals: #212b36;
$text-grey: #637381;
$text-delivery: #2a2b30;
// color boxshadow
$boxshadow: rgba(163, 176, 192, 0.42);
$boxshadow-inner: -10px 0 15px #00000012, 10px 0 15px #00000012, 0 10px 15px #00000012;
$boxshadow-logo: rgba(0, 0, 0, 0.08);
$boxshadow-bussiness-title: rgba(0, 0, 0, 0.5);
// color border
$border-link: #dadee2;
$border-bussiness: #979797;
// color hover
$hover-button-banner: #b99406;
$hover-button-stroke: #9d800f;
$hover-boxshadow: rgba(163, 176, 192, 0.62);
$hover-primary: #06a3d8;
// errors
$error-input: #e0b4b4;
$error-input-bg: #fff6f6;
// color background
$body-bg: #eeeeee;
$footer-bg: #1d1d20;
$category-bg: #f1f4fa;
$trading-bg: #d8d8d8;
$newsletter-bg: #25252b;
// color dots
$dot-white: #fff;
$dot-black: #000;
// color menu
$menu-toggle: #333;
// color scrollbars
$scrollbars: #d8d8d8;
// active
$active-slick: #7a7a7a;

// Create grayscale
$gray-dark:                 #292b2c !default;
$gray:                      #464a4c !default;
$gray-light:                #636c72 !default;
$gray-lighter:              #eceeef !default;
$gray-lightest:             #f7f7f9 !default;

// Reassign color vars to semantic color scheme
$brand-primary:             #16A085 !default;
$brand-success:             #2ECC71 !default;
$brand-info:                #e4cb65 !default;
$brand-warning:             #fbbd08 !default;
$brand-danger:              #d9534f !default;
$brand-inverse:             #34495E !default;
$brand-inverse-hover:       darken($brand-inverse, 15%) !default;


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded:            true !default;
$enable-shadows:            false !default;
$enable-gradients:          false !default;
$enable-transitions:        true !default;
$enable-hover-media-query:  false !default;
$enable-grid-classes:       true !default;
$enable-print-styles:       true !default;




//
// Define common padding and border radius sizes and more.

$border-radius:          .25rem !default;
$border-radius-lg:       .3rem !default;
$border-radius-sm:       .2rem !default;

//Border color
$border-width: 1px !default;
$border-color:          #ddd;
$border-color-dark:     #e1e1e1;
$border:                solid 1px $border-color;
$border-dark:           solid 1px $border-color-dark;



// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-flex:               false !default;
$enable-rounded:            true !default;
$enable-shadows:            false !default;
$enable-gradients:          false !default;
$enable-transitions:        false !default;
$enable-hover-media-query:  false !default;
$enable-grid-classes:       true !default;
$enable-print-styles:       true !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer:   1rem !default;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;
$spacers: (
        0: (
                x: 0,
                y: 0
        ),
        1: (
                x: ($spacer-x * .25),
                y: ($spacer-y * .25)
        ),
        2: (
                x: ($spacer-x * .5),
                y: ($spacer-y * .5)
        ),
        3: (
                x: $spacer-x,
                y: $spacer-y
        ),
        4: (
                x: ($spacer-x * 1.5),
                y: ($spacer-y * 1.5)
        ),
        5: (
                x: ($spacer-x * 3),
                y: ($spacer-y * 3)
        )
) !default;

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: (
        25: 25%,
        50: 50%,
        75: 75%,
        100: 100%
) !default;


// Body
//
// Settings for the `<body>` element.

$body-bg:       #EEEEEE;
$footer-bg:     #131c33;
$footer-color:     #fff;
$body-color:    $gray-dark !default;
$inverse-bg:    $gray-dark !default;
$inverse-color: $gray-lighter !default;


// link colors
// -------------------------


$link-color:            $brand-primary !default; //369
$link-decoration:       none !default;
$link-hover-color:      darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;


//Social color
$facebook:              #3b5998;
$google:                #dd4b39;
$twitter:               #00aced;
$youtube:               #bb0000;
$linkedin:              #007bb5;
$instagram:             #125688;
$whatsapp:              #4dc247;
$pinterest:             #cb2027;
$flickr:                #ff0084;
$tumblr:                #32506d;
$vk:                    #45668e;




// ----------------------------------------------------------------------
// breakpoints
// ----------------------------------------------------------------------
// using vendor sass-breakpoint with Matches Bootstrap 3

// 767px 991px 1199px 1199px
// 480px 768px 992px 1200px

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
) !default;


$xxs:                 480px;
$xs:                  576px;
$sm:                  768px;
$md:                  992px;
$lg:                  1201px;

$xxs-max:             max-width 479px;
$xs-max:              max-width 767px;
$sm-max:              max-width 991px;
$md-max:              max-width 991px;


$xxs-min:             $xxs;
$xs-min:              $xs;
$sm-min:              $sm;
$md-min:              $md;
$lg-min:              $lg;



$xxs-only:            $xxs-max;   // Mobile
$xs-only :            max-width 575px;   // Mobile
$sm-only :            max-width 767px;       // Tablet - Portrait
$md-only :            max-width 991px;      // Tablet - Landscape
$lg-only :            max-width 1199px;  // Desktop

//
//  By Device Name/BS3
//

$mobile:              $sm-only;   // max-width 767px
$mobile-tablet:       $sm-max;
$mobile-min:          $md-min;   // min-width 768px
$tablet:              $sm-only;
$sm-lg:               768px 1199px;
$sm-md:               768px 991px;
$tablet-desktop:      min-width 768px;
$desktop:             $md-min;

//  usage @include breakpoint($xxs-only)



// Typography
//
// Font, line-height, and color for body text, headings, and more.

$Montserrat:                "Montserrat",Helvetica,Arial,sans-serif;
$Montserrat-condensed:      "Montserrat Condensed",Helvetica,Arial,sans-serif;
$Open-Sans-Condensed:   "Open Sans Condensed",Helvetica,Arial,sans-serif;



$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Montserrat, "Helvetica Neue", Arial, sans-serif !default;
$font-family-serif:      Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:       $Montserrat !default;

$base-font-condensed:   $Montserrat-condensed;
$base-font: 			$Montserrat;
$title-font:            $Montserrat;
$base-font-condensed:   $Montserrat-condensed;
$logo-font:             $Open-Sans-Condensed;
$base-font-size: 13px;


$font-size-base: .85rem !default; // 13px
$font-size-lg:   1rem !default;
$font-size-sm:   .65rem !default;
$font-size-xs:   .5rem !default;

$font-weight-normal: normal !default;
$font-weight-bold: bold !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

$font-size-h1: 2.5rem !default;
$font-size-h2: 2rem !default;
$font-size-h3: 1.75rem !default;
$font-size-h4: 1.5rem !default;
$font-size-h5: 1.25rem !default;
$font-size-h6: 1rem !default;

$headings-margin-bottom: ($spacer / 2) !default;
$headings-font-family:   inherit !default;
$headings-font-weight:   500 !default;
$headings-line-height:   1.1 !default;
$headings-color:         inherit !default;

$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3.5rem !default;

$display1-weight:     300 !default;
$display2-weight:     300 !default;
$display3-weight:     300 !default;
$display4-weight:     300 !default;
$display-line-height: $headings-line-height !default;

$lead-font-size:   1.25rem !default;
$lead-font-weight: 300 !default;

$small-font-size: 80% !default;

$text-muted: $gray-light !default;

$abbr-border-color: $gray-light !default;

$blockquote-small-color:  $gray-light !default;
$blockquote-font-size:    ($font-size-base * 1.25) !default;
$blockquote-border-color: $gray-lighter !default;
$blockquote-border-width: .25rem !default;

$hr-border-color: rgba($black,.1) !default;
$hr-border-width: $border-width !default;

$mark-padding: .2em !default;

$dt-font-weight: $font-weight-bold !default;

$kbd-box-shadow:         inset 0 -.1rem 0 rgba($black,.25) !default;
$nested-kbd-font-weight: $font-weight-bold !default;

$list-inline-padding: 5px !default;


// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:         (4 / 3) !default;
$line-height-sm:         1.5 !default;

$border-radius:          3px !default;
$border-radius-lg:       6px !default;
$border-radius-sm:       2px !default;

$component-active-color: $white !default;
$component-active-bg:    $brand-primary !default;

$caret-width:            .3em !default;

$transition-base:        all .2s ease-in-out !default;
$transition-fade:        opacity .15s linear !default;
$transition-collapse:    height .35s ease !default;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:            .75rem !default;
$table-sm-cell-padding:         .3rem !default;

$table-bg:                      transparent !default;

$table-inverse-bg:              $gray-dark !default;
$table-inverse-color:           $body-bg !default;

$table-bg-accent:               rgba($black,.05) !default;
$table-bg-hover:                rgba($black,.075) !default;
$table-bg-active:               $table-bg-hover !default;

$table-head-bg:                 $gray-lighter !default;
$table-head-color:              $gray !default;

$table-border-width:            $border-width !default;
$table-border-color:            $gray-lighter !default;


// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.


// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.

//$btn-padding-x:                  1rem !default;
//$btn-padding-y:                  .5rem !default;
$btn-padding-x:                  12px !default;
$btn-padding-y:                  8px !default;
//$btn-line-height:                1.25 !default;
$btn-line-height:                1.5 !default;
$btn-font-weight:                $font-weight-normal !default;
$btn-box-shadow:                 inset 0 1px 0 rgba($white,.15), 0 1px 1px rgba($black,.075) !default;
$btn-focus-box-shadow:           0 0 0 2px rgba($brand-primary, .25) !default;
$btn-active-box-shadow:          inset 0 3px 5px rgba($black,.125) !default;

$btn-primary-color:              $white !default;
$btn-primary-bg:                 $brand-primary !default;
$btn-primary-border:             $btn-primary-bg !default;

$btn-secondary-color:            $gray-dark !default;
$btn-secondary-bg:               $white !default;
$btn-secondary-border:           #ccc !default;

$btn-info-color:                 $white !default;
$btn-info-bg:                    $brand-info !default;
$btn-info-border:                $btn-info-bg !default;

$btn-success-color:              $white !default;
$btn-success-bg:                 $brand-success !default;
$btn-success-border:             $btn-success-bg !default;

$btn-warning-color:              $white !default;
$btn-warning-bg:                 $brand-warning !default;
$btn-warning-border:             $btn-warning-bg !default;

$btn-danger-color:               $white !default;
$btn-danger-bg:                  $brand-danger !default;
$btn-danger-border:              $btn-danger-bg !default;

$btn-link-disabled-color:        $gray-light !default;

//$btn-padding-x-sm:               .5rem !default;
//$btn-padding-y-sm:               .25rem !default;

//$btn-padding-x-lg:               1.5rem !default;
//$btn-padding-y-lg:               .75rem !default;

$btn-padding-x-sm:               8px !default;
$btn-padding-y-sm:               4px !default;

$btn-padding-x-lg:               18px !default;
$btn-padding-y-lg:               10px !default;

$btn-block-spacing-y:            .5rem !default;
$btn-toolbar-margin:             .5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:              $border-radius !default;
$btn-border-radius-lg:           $border-radius-lg !default;
$btn-border-radius-sm:           $border-radius-sm !default;

$btn-transition:                 all .2s ease-in-out !default;


// Forms

$input-padding-x:                .75rem !default;
//$input-padding-y:                .5rem !default;
$input-padding-y:                .5rem !default;
$input-line-height:              1.25 !default;

$input-bg:                       $white !default;
$input-bg-disabled:              $gray-lighter !default;

$input-color:                    $gray !default;
$input-border-color:             rgba($black,.15) !default;
$input-btn-border-width:         $border-width !default; // For form controls and buttons
$input-box-shadow:               inset 0 1px 1px rgba($black,.075) !default;

$input-border-radius:            $border-radius !default;
$input-border-radius-lg:         $border-radius-lg !default;
$input-border-radius-sm:         $border-radius-sm !default;

$input-bg-focus:                 $input-bg !default;
//$input-border-focus:             lighten($brand-primary, 25%) !default;
$input-border-focus:             $brand-primary;
//$input-box-shadow-focus:         $input-box-shadow, rgba($input-border-focus, .6) !default;
$input-box-shadow-focus:         1px 0 $brand-primary, 0 -1px 0 $brand-primary, -1px 0 0 $brand-primary, 1px 0 0 $brand-primary;
$input-color-focus:              $input-color !default;

$input-color-placeholder:        $gray-light !default;

$input-padding-x-sm:             .5rem !default;
$input-padding-y-sm:             .25rem !default;

$input-padding-x-lg:             1.5rem !default;
$input-padding-y-lg:             .75rem !default;

//$input-height:                   (($font-size-base * $input-line-height) + ($input-padding-y * 2)) !default;
$input-height:                   38px;
$input-height-lg:                (($font-size-lg * $line-height-lg) + ($input-padding-y-lg * 2)) !default;
$input-height-sm:                (($font-size-sm * $line-height-sm) + ($input-padding-y-sm * 2)) !default;

$input-transition:               border-color ease-in-out .15s, box-shadow ease-in-out .15s !default;

$form-text-margin-top:     .25rem !default;
$form-feedback-margin-top: $form-text-margin-top !default;

$form-check-margin-bottom:  .5rem !default;
$form-check-input-gutter:   1.25rem !default;
$form-check-input-margin-y: .25rem !default;
$form-check-input-margin-x: .25rem !default;

$form-check-inline-margin-x: .75rem !default;

$form-group-margin-bottom:       $spacer-y !default;

$input-group-addon-bg:           $gray-lighter !default;
$input-group-addon-border-color: $input-border-color !default;

$cursor-disabled:                not-allowed !default;

$custom-control-gutter:   1.5rem !default;
$custom-control-spacer-x: 1rem !default;
$custom-control-spacer-y: .25rem !default;

$custom-control-indicator-size:       1rem !default;
$custom-control-indicator-margin-y:   (($line-height-base * 1rem) - $custom-control-indicator-size) / -2 !default;
$custom-control-indicator-bg:         #ddd !default;
$custom-control-indicator-bg-size:    50% 50% !default;
$custom-control-indicator-box-shadow: inset 0 .25rem .25rem rgba($black,.1) !default;

$custom-control-disabled-cursor:             $cursor-disabled !default;
$custom-control-disabled-indicator-bg:       $gray-lighter !default;
$custom-control-disabled-description-color:  $gray-light !default;

$custom-control-checked-indicator-color:      $white !default;
$custom-control-checked-indicator-bg:         $brand-primary !default;
$custom-control-checked-indicator-box-shadow: none !default;

$custom-control-focus-indicator-box-shadow: 0 0 0 1px $body-bg, 0 0 0 3px $brand-primary !default;

$custom-control-active-indicator-color:      $white !default;
$custom-control-active-indicator-bg:         lighten($brand-primary, 35%) !default;
$custom-control-active-indicator-box-shadow: none !default;

$custom-checkbox-radius: $border-radius !default;
$custom-checkbox-checked-icon: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$custom-control-checked-indicator-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"), "#", "%23") !default;

$custom-checkbox-indeterminate-bg: $brand-primary !default;
$custom-checkbox-indeterminate-indicator-color: $custom-control-checked-indicator-color !default;
$custom-checkbox-indeterminate-icon: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#{$custom-checkbox-indeterminate-indicator-color}' d='M0 2h4'/%3E%3C/svg%3E"), "#", "%23") !default;
$custom-checkbox-indeterminate-box-shadow: none !default;

$custom-radio-radius: 50% !default;
$custom-radio-checked-icon: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$custom-control-checked-indicator-color}'/%3E%3C/svg%3E"), "#", "%23") !default;

$custom-select-padding-x:          .75rem  !default;
$custom-select-padding-y:          .375rem !default;
$custom-select-indicator-padding:   1rem !default; // Extra padding to account for the presence of the background-image based indicator
$custom-select-line-height:         $input-line-height !default;
$custom-select-color:               $input-color !default;
$custom-select-disabled-color:      $gray-light !default;
$custom-select-bg:            $white !default;
$custom-select-disabled-bg:   $gray-lighter !default;
$custom-select-bg-size:       8px 10px !default; // In pixels because image dimensions
$custom-select-indicator-color: #333 !default;
$custom-select-indicator:     str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E"), "#", "%23") !default;
$custom-select-border-width:  $input-btn-border-width !default;
$custom-select-border-color:  $input-border-color !default;
$custom-select-border-radius: $border-radius !default;

$custom-select-focus-border-color: lighten($brand-primary, 25%) !default;
$custom-select-focus-box-shadow:   inset 0 1px 2px rgba($black, .075), 0 0 5px rgba($custom-select-focus-border-color, .5) !default;

$custom-select-sm-padding-y:  .2rem !default;
$custom-select-sm-font-size:  75% !default;

$custom-file-height:           2.5rem !default;
$custom-file-width:            14rem !default;
$custom-file-focus-box-shadow: 0 0 0 .075rem $white, 0 0 0 .2rem $brand-primary !default;

$custom-file-padding-x:     .5rem !default;
$custom-file-padding-y:     1rem !default;
$custom-file-line-height:   1.5 !default;
$custom-file-color:         $gray !default;
$custom-file-bg:            $white !default;
$custom-file-border-width:  $border-width !default;
$custom-file-border-color:  $input-border-color !default;
$custom-file-border-radius: $border-radius !default;
$custom-file-box-shadow:    inset 0 .2rem .4rem rgba($black,.05) !default;
$custom-file-button-color:  $custom-file-color !default;
$custom-file-button-bg:     $gray-lighter !default;
$custom-file-text: (
        placeholder: (
                en: "Choose file..."
        ),
        button-label: (
                en: "Browse"
        )
) !default;


// Form validation icons
$form-icon-success-color: $brand-success !default;
$form-icon-success: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$form-icon-success-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E"), "#", "%23") !default;

$form-icon-warning-color: $brand-warning !default;
$form-icon-warning: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$form-icon-warning-color}' d='M4.4 5.324h-.8v-2.46h.8zm0 1.42h-.8V5.89h.8zM3.76.63L.04 7.075c-.115.2.016.425.26.426h7.397c.242 0 .372-.226.258-.426C6.726 4.924 5.47 2.79 4.253.63c-.113-.174-.39-.174-.494 0z'/%3E%3C/svg%3E"), "#", "%23") !default;

$form-icon-danger-color: $brand-danger !default;
$form-icon-danger: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$form-icon-danger-color}' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E"), "#", "%23") !default;





// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width:             10rem !default;
$dropdown-padding-y:             .5rem !default;
$dropdown-margin-top:            .125rem !default;
$dropdown-bg:                    $white !default;
$dropdown-border-color:          rgba($black,.15) !default;
$dropdown-border-width:          $border-width !default;
$dropdown-divider-bg:            $gray-lighter !default;
$dropdown-box-shadow:            0 .5rem 1rem rgba($black,.175) !default;

$dropdown-link-color:            $gray-dark !default;
$dropdown-link-hover-color:      darken($gray-dark, 5%) !default;
$dropdown-link-hover-bg:         $gray-lightest !default;

$dropdown-link-active-color:     $component-active-color !default;
$dropdown-link-active-bg:        $component-active-bg !default;

$dropdown-link-disabled-color:   $gray-light !default;

$dropdown-item-padding-x:        1.5rem !default;

$dropdown-header-color:          $gray-light !default;



// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown-backdrop:  990 !default;
$zindex-navbar:             1000 !default;
$zindex-dropdown:           1000 !default;
$zindex-fixed:              1030 !default;
$zindex-sticky:             1030 !default;
$zindex-modal-backdrop:     1040 !default;
$zindex-modal:              1050 !default;
$zindex-popover:            1060 !default;
$zindex-tooltip:            1070 !default;




// Navbar

$navbar-border-radius:              $border-radius !default;
$navbar-padding-x:                  $spacer !default;
$navbar-padding-y:                  ($spacer / 2) !default;

$navbar-brand-padding-y:            .25rem !default;

$navbar-toggler-padding-x:           .75rem !default;
$navbar-toggler-padding-y:           .25rem !default;
$navbar-toggler-font-size:           $font-size-lg !default;
$navbar-toggler-border-radius:       $btn-border-radius !default;

$navbar-inverse-color:                 rgba($white,.5) !default;
$navbar-inverse-hover-color:           rgba($white,.75) !default;
$navbar-inverse-active-color:          rgba($white,1) !default;
$navbar-inverse-disabled-color:        rgba($white,.25) !default;
$navbar-inverse-toggler-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-inverse-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-inverse-toggler-border:        rgba($white,.1) !default;

$navbar-light-color:                rgba($black,.5) !default;
$navbar-light-hover-color:          rgba($black,.7) !default;
$navbar-light-active-color:         rgba($black,.9) !default;
$navbar-light-disabled-color:       rgba($black,.3) !default;
$navbar-light-toggler-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-light-toggler-border:       rgba($black,.1) !default;

// Navs

$nav-item-margin:               .2rem !default;
$nav-item-inline-spacer:        1rem !default;
$nav-link-padding:              .5em 1em !default;
$nav-link-hover-bg:             $gray-lighter !default;
$nav-disabled-link-color:       $gray-light !default;

$nav-tabs-border-color:                       #ddd !default;
$nav-tabs-border-width:                       $border-width !default;
$nav-tabs-border-radius:                      $border-radius !default;
$nav-tabs-link-hover-border-color:            $gray-lighter !default;
$nav-tabs-active-link-hover-color:            $gray !default;
$nav-tabs-active-link-hover-bg:               $body-bg !default;
$nav-tabs-active-link-hover-border-color:     #ddd !default;
$nav-tabs-justified-link-border-color:        #ddd !default;
$nav-tabs-justified-active-link-border-color: $body-bg !default;

$nav-pills-border-radius:     $border-radius !default;
$nav-pills-active-link-color: $component-active-color !default;
$nav-pills-active-link-bg:    $component-active-bg !default;


// Pagination

$pagination-padding-x:                .75rem !default;
$pagination-padding-y:                .5rem !default;
$pagination-padding-x-sm:             .5rem !default;
$pagination-padding-y-sm:             .25rem !default;
$pagination-padding-x-lg:             1.5rem !default;
$pagination-padding-y-lg:             .75rem !default;
$pagination-line-height:              1.25 !default;

$pagination-color:                     $link-color !default;
$pagination-bg:                        $white !default;
$pagination-border-width:              $border-width !default;
$pagination-border-color:              #ddd !default;

$pagination-hover-color:               $link-hover-color !default;
$pagination-hover-bg:                  $gray-lighter !default;
$pagination-hover-border:              #ddd !default;

$pagination-active-color:              $white !default;
$pagination-active-bg:                 $brand-primary !default;
$pagination-active-border:             $brand-primary !default;

$pagination-disabled-color:            $gray-light !default;
$pagination-disabled-bg:               $white !default;
$pagination-disabled-border:           #ddd !default;


