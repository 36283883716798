.form-overview {
  .dot-bottom {
    margin-left: -17px;
    margin-right: -17px;
  }
  .group-pickup {
    display: flex;
    background: #f6f6f6;
    .item {
      width: 50%;
      border: 1px solid #e0e0e0;
      .form-item {
        margin: 0;
        .item-header {
          padding: 8px;
          border-bottom: 1px solid #e0e0e0;
          .form-label {
            margin-bottom: 0;
          }
        }
        .form-control {
          border: none;
          background-color: transparent;
          &:focus {
            border: none;
            outline: none;
            box-shadow: none;
          }
        }
      }
      &:first-child {
        border-radius: 5px 0 0 5px;
      }
      &:last-child {
        border-radius: 0px 5px 5px 0;
      }
    }
  }
  .label-checbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
    font-size: 14px;
    .link {
      color: $dark;
      text-decoration: underline !important;
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 14px;
    }
    .checkbox {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .checkmark {
      position: absolute;
      top: 0px;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: transparent;
      border: 1px solid $border-bussiness;
      border-radius: 2px;
      &:after {
        content: '';
        position: absolute;
        display: none;
        left: 6px;
        top: 2px;
        width: 6px;
        height: 12px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
  /* On mouse-over, add a grey background color */
  .label-checbox:hover .checkbox ~ .checkmark {
    background-color: #ccc;
  }
  /* When the checkbox is checked, add a blue background */
  .label-checbox .checkbox:checked ~ .checkmark {
    background-color: #2196f3;
  }
  /* Show the checkmark when checked */
  .label-checbox .checkbox:checked ~ .checkmark:after {
    display: block;
  }
  .description {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    color: $dark;
    font-weight: 700;
    display: flex;
    align-items: center;
  }
  .notice {
    display: block;
    position: relative;
    user-select: none;
    font-size: 12px;
    color: $danger;
    font-style: italic;
    margin-left: 10px;
    &::before {
      content: '*';
      position: absolute;
      left: -5px;
      top: -5px;
    }
  }
  .form-content {
    background-color: $white;
    padding: 20px 16px;
    border-radius: 4px;
  }
  .form-item {
    margin: 5px 0;
    .react-tel-input {
      .form-control {
        width: 100%;
        outline: none;
        border: 1px solid #cfcfcf;
        box-shadow: none;
      }
    }
    .form-label {
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 14px;
      color: $dark;
      font-weight: 700;
      &.form-label-required {
        position: relative;
        &::after {
          top: 0;
          right: -7px;
          content: '*';
          color: $danger;
          font-size: 12px;
          position: absolute;
        }
      }
    }
    .selection {
      width: 100%;
    }
    .form-input-file {
      display: none !important;
    }
    .form-label-file {
      border-radius: 4px;
      padding: 6px 10px;
      border: 1px solid $dark;
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 15px;
      margin-top: 5px;
      cursor: pointer;
      .icon {
        margin-right: 5px;
      }
    }
    .form-input {
      display: block;
      width: 100%;
      padding: 6px 16px;
      outline: none;
      border: 1px solid #cfcfcf;
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 15px;
      border-radius: 4px;
    }
    .form-select {
      display: block;
      width: 100%;
      padding: 6px 16px;
      outline: none;
      border: 1px solid #cfcfcf;
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 15px;
      border-radius: 4px;
    }
    .choose-item {
      display: flex;
      align-items: center;
      .choose-radio {
        margin-right: 5px;
      }
    }
    .choose-label {
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 14px;
      color: $dark;
    }
  }
  .btn-url{

    border: 1px solid $dark;
    border-radius: 5px;
    font-size: 14px;
    background-color: $white; ;
    a{
      color: $dark;
      font-size: 13px;
    }
    i{ color: black;}
  }
  .btn-save {
    background-color: $dark;
    color: $white;
    font-family: 'Montserrat';
    font-weight: 600;
    border: 1px solid $dark;
    border-radius: 5px;
    font-size: 14px;
    text-transform: uppercase;
    padding: 3px 32px;
    &:hover,
    &:focus {
      background-color: rgba($color: $dark, $alpha: 0.6);
      color: $white;
    }
  }
  .btn-save-yellow {
    background-color: $yellow;
    color: $dark;
    font-family: 'Montserrat';
    font-weight: 600;
    border: 1px solid $yellow;
    font-size: 13px;
    padding: 8px 26px;
    border-radius: 4px;
    &:hover,
    &:focus {
      background-color: rgba($color: $yellow, $alpha: 0.6);
      color: $dark;
    }
  }
}
.overview-modal {
  background-color: $white;
  min-width: 500px;
  border-radius: 4px;
  .notice {
    display: block;
    position: relative;
    user-select: none;
    font-size: 12px;
    color: $danger;
    font-style: italic;
    text-decoration: revert;
  }
  hr {
    margin: 0;
  }
  .StripeElement--empty {
    margin-top: 10px;
  }
  .card-information {
    margin-top: 5px;
  }
  .payment-credit {
    margin-top: 5px;
    .icon {
      margin-right: 5px;
      width: 40px;
      border-radius: 4px;
    }
  }
  .checkout-title {
    color: $dark;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 16px;
    padding-bottom: 0;
    .icon {
      margin-right: 5px;
    }
  }
  .title {
    color: $dark;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 24px;
    padding-bottom: 0;
    padding: 10px;
    border-bottom: 1px solid #e6e6e6;
  }
  .list-item {
    padding: 10px;
    .item {
      margin: 10px 0;
    }
    .list-image {
      display: flex;
      .item-image {
        width: 33%;
        margin: 0 5px;
        &:first-child {
          margin: 0;
        }
        img {
          width: 100%;
          border-radius: 4px;
        }
      }
    }
    .norm {
      color: $dark;
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 0;
      span {
        font-family: 'Montserrat';
        font-weight: 400;
      }
    }
  }
  .overview-modal-footer {
    border-top: 1px solid #e6e6e6;
    display: flex;
    justify-content: center;
    padding: 10px;
    .btn {
      margin: 0 5px;
      padding: 5px 20px;
      background-color: $dark;
      color: $white;
      font-family: 'Montserrat';
      font-weight: 600;
      border: 1px solid $dark;
      font-size: 13px;
      padding: 8px 26px;
      border-radius: 4px;
      &.btn-ok {
        background-color: $yellow;
        border: 1px solid $yellow;
        color: $black;
      }
    }
  }
}

.leftNoti {
  box-shadow: 0 2px 14px 5px rgba(163, 176, 192, 0.62);
  width: calc(100vw * 0.15); // 2/12 column
  z-index: 99;
  display: inline-block;
  bottom: 100px;
  right: 50px;
  border-radius: 40px;
  position: fixed !important;
  @include breakpoint(992) {
    border-radius: 0;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 15px 0;
    box-shadow: none;
    .block-body {
      width: calc(100vw * 0.5);
      float: right;
      margin-right: 30px;
      position: relative;
    }
  }
  i {
    position: absolute;
    right: -15px;
    top: -10px;
    font-size: 15px;
    cursor: pointer;
    color: #028ab8;
  }
  button {
    width: 100%;
    font-size: 8px;
    margin-top: 0 !important;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
    }
  }
  #tooltip {
    font-size: 9.5px;
  }
}

.btn-create-business {
  margin-bottom: 15px !important;
  button:hover, button:focus{
    background-color: #028ab8 !important;
  }
  @media (min-width: 992px) {
    text-align: right !important;
  }
}

.store_overview {
  .store_overview_list {
    .item_name {
      margin: 5px 0 0 0;
      font-weight: bold;
    }
    .adds-norm {
      min-height: unset;
      padding-top: 0;
      float: right;
    }
  }
  .slick-arrow {
    &.slick-prev {
      left: 30px;
      z-index: 99;
      &::before {
        font-family: "icomoon";
        content: "\e900";
        color: #fff !important;
        font-size: 35px !important;
      }
    }
    &.slick-next {
      right: 30px;
      z-index: 99;
      &::before {
        font-family: "icomoon";
        content: "\e901";
        color: #fff !important;
        font-size: 35px !important;
      }
    }
  }
}
