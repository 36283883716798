// ----------------------------------------------------------------------
// CUSTOMIZE THE BOOTSTRAP MODAL + INPUT FORM ELEMENT + BUTTON + ALERT
// ----------------------------------------------------------------------
@media (min-width: 768px) {
  .category-content .form-horizontal .control-label {
    margin-bottom: 0;
    text-align: right;
  }
}

.form-filter-list {
  height: 35px;
  @include rem('font-size', 13);
}

.ui.form .field.error input:not([type]), .ui.form .field.error input[type=date], .ui.form .field.error input[type=datetime-local], .ui.form .field.error input[type=email], .ui.form .field.error input[type=file], .ui.form .field.error input[type=number], .ui.form .field.error input[type=password], .ui.form .field.error input[type=search], .ui.form .field.error input[type=tel], .ui.form .field.error input[type=text], .ui.form .field.error input[type=time], .ui.form .field.error input[type=url], .ui.form .field.error select, .ui.form .field.error textarea, .ui.form .fields.error .field input:not([type]), .ui.form .fields.error .field input[type=date], .ui.form .fields.error .field input[type=datetime-local], .ui.form .fields.error .field input[type=email], .ui.form .fields.error .field input[type=file], .ui.form .fields.error .field input[type=number], .ui.form .fields.error .field input[type=password], .ui.form .fields.error .field input[type=search], .ui.form .fields.error .field input[type=tel], .ui.form .fields.error .field input[type=text], .ui.form .fields.error .field input[type=time], .ui.form .fields.error .field input[type=url], .ui.form .fields.error .field select, .ui.form .fields.error .field textarea {
  background-color: #fff6f6;
  border-color: #e0b4b4;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ui.form .field.error .input, .ui.form .field.error label, .ui.form .fields.error .field .input, .ui.form .fields.error .field label {
  color: #e74c3c;
}
.ui.form .field.error .input, .ui.form .field.error label.error, .ui.form .fields.error .field .input, .ui.form .fields.error .field label.error {
  color: #e74c3c;
  margin: 0;
  padding-top: 7px;
}

.form-note {
  color: #888;
  font-size: 11px;
  display: block;
  line-height: 1.5;
}