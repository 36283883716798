.landing-container {
    .banner-container {
      position: relative;
      height: 700px;
      background-image: url("/assets/images/landing/Banner.png");
      background-repeat: no-repeat;
      background-size: cover;
      margin-bottom: 0;
      .banner-content {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        .banner-title {
          font-family: "Montserrat";
          font-weight: 700;
          font-size: 40px;
          line-height: 49px;
          color: $white;
        }
        .banner-norm {
          font-family: "Montserrat";
          font-weight: 400;
          font-size: 18px;
          line-height: 27px;
          color: $white;
        }
        .form-input {
          border-radius: 26.4px;
          padding: 8px 16px;
          border: none;
          outline: none;
          font-family: "Montserrat";
          min-width: 350px;
        }
      }
      .banner-footer {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 20px;
        .banner-description {
          font-family: "Montserrat";
          font-weight: 400;
          font-size: 12px;
          line-height: 27px;
          color: $white;
        }
      }
    }
    .delivery-container {
      .delivery-title {
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: $dark;
      }
      .delivery-norm {
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        color: $dark;
      }
    }
    .business-container {
      .business-banner {
        height: 500px;
        background-image: url("/assets/images/landing/banner-business.png");
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        .banner-title {
          font-family: "Montserrat";
          font-weight: 700;
          font-size: 40px;
          line-height: 49px;
          color: $white;
        }
        .banner-norm {
          font-family: "Montserrat";
          font-weight: 400;
          font-size: 18px;
          line-height: 27px;
          color: $white;
        }
      }
      .business-card {
        position: relative;
        height: 340px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        border-radius: 6px;
        cursor: pointer;
        transition: background-size 0.3s ease-in;
        &:hover {
          background-size: 110% 110%;
        }
        .business-footer {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0px;
          width: 100%;
          padding: 10px;
          .business-norm {
            font-family: "Montserrat";
            font-weight: 500;
            font-size: 16px;
            line-height: 25px;
            color: $white;
          }
        }
      }
    }
    .forward-container {
      background-color: #f2f9ff;
      .forward-card {
        .forward-img {
          height: 60px;
          width: 60px;
        }
        .forward-content {
          margin-top: 10px;
        }
        .forward-title {
          font-family: "Montserrat";
          font-weight: 600;
          font-size: 16px;
          line-height: 25px;
          color: $dark;
        }
        .forward-norm {
          font-family: "Montserrat";
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: $dark;
        }
      }
    }
    .wherever-container {
      .wherever-img {
        width: 100%;
      }
      .wherever-content {
        padding: 20px 0;
        .wherever-title {
          font-family: "Montserrat";
          font-weight: 600;
          font-size: 30px;
          line-height: 42px;
          color: $dark;
        }
        .wherever-norm {
          font-family: "Montserrat";
          font-weight: 400;
          font-size: 16px;
          line-height: 27px;
          color: $dark;
        }
      }
    }
  }
  