.order-container {
  margin-bottom: 10px;
  .btn-delivery {
    background-color: #028ab8;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    padding: 8.5px 32px;
    border-radius: 26px;
  }
  .table-orders {
    max-height: 500px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px;
      background-color: transparent;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px unset;
      border-radius: 10px;
      height: 80px;
      background-color: transparent;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      opacity: 0.4;
      background-color: $trading-bg;
      border-radius: 3.5px;
      height: 6px;
    }
  }
  .products-grids {
    .text-gray {
      margin: 0;
      padding: 5px 0;
    }
    .ui.table tr td {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
  .table-order {
    .ui.table tr td {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  .order-detail {
    .block-title {
      background-color: #fff;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      padding: 10px;
      font-size: 15px;
      a {
        color: inherit;
      }
    }
    .order-summary {
      background-color: #fff;
      line-height: 20px;
      padding: 10px;
      .summary-body {
        font-size: 11px;
        font-weight: 600;
        color: #686565;
        .summary-time {
          font-size: 10px;
        }
      }
      .summary-method {
        margin: 10px 0;
        font-size: 15px;
        font-weight: 700;
      }
    }
    .order-list {
      .order-total {
        font-weight: 700;
        text-align: right;
        color: red;
      }
      .order-item {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid #ccc;
        .order-item__body {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 700;
          .order-item__info {
            display: flex;
            align-items: center;
            img {
              margin-right: 10px;
            }
            .order-item__info-quantity {
              margin-right: 10px;
            }
            .order-item__info-name {
              display: flex;
              align-items: center;
            }
          }
        }
        .order-item__status {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .text-cancel {
            color: red;
            font-size: 12px;
            font-weight: 600;
          }
          .text-requested {
            margin-top: 5px;
              font-size: 12px;
              color: gray;
          }
          .btn-confirm {
            border-radius: unset;
            padding: 5px;
            width: 80px;
            background: #131c33;
          }
        }
        .booking-item__body {
          .booking-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 700;
          }
          .booking-time__from {
            margin-top: 10px;
            font-size: 13px;
            font-weight: 700;
            a {
              color: #5e71a0;
            }
          }
          .booking-time__to {
            font-size: 13px;
            color: #ccc;
          }
        }
      }
      .order-note {
        margin-top: 10px;
        background: #fff;
        font-size: 12px;
        color: gray;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    .order-sub {
      padding: 10px 20px;
      background: #fff;
      font-size: 12px;
      color: gray;
      .order-sub__block {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    .order-business-name {
      color: #5e71a0;
      font-weight: 700;
      font-size: 17px;
    }
    .order-table-price {
      th {
        white-space: nowrap;
      }
    }
    .ui.buttons .button,
    .ui.button {
      padding: 9px;
      margin: 0;
    }
  }
  .icon.info.circle {
    color: #16a085;
    overflow: visible;
    padding: 0;
    display: inline-block;
  }
  .ui.selection.dropdown {
    min-width: unset;
    max-width: 120px;
    width: 120px;
    font-size: 12px;
    .item {
      font-size: 12px;
    }
  }
  .change-status {
    text-align: center;
    margin-top: 20px;
    .btn-confirm {
      border-radius: 8px;
      padding: 10px;
      width: auto;
      background: #131c33;
      &:hover {
        background: #131c33;
      }
    }
  }
}
