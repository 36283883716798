.text-xs {
	font-size: 12px;
}

.ml-star {
	margin-left: -2px;
}
.input-container {
	position: relative;
  }
.toggle-password {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
  }
.text-generate-password{
    font-family: 'Montserrat';
    font-weight: 350;
    font-style: italic;
    font-style: 11px;
    margin-top: 2px;
    color: rgba(0, 0, 0, 0.6);
	&:hover{
		cursor: pointer;
		font-weight: bold;
		color: black;
	}
}