.login-container {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 100vh;
  z-index: 9;
  padding-top: 80px;
  .login-more {
    position: relative;
    background-image: url('/assets/images/login/bg-signin.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: calc(100% - 40%);
    .login-more-img {
      position: absolute;
      width: 250px;
      left: 50%;
      top: 80px;
      transform: translate(-50%, 0);
    }
    &.login-more-signup {
      background-image: url('/assets/images/login/bg-signup.png');
    }
  }
  .wrap-login {
    .login-title {
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 36px;
      color: $dark;
      letter-spacing: 0;
      padding: 20px;
      text-align: center;
      line-height: 36px;
    }
    .login-norm {
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 16px;
      color: $dark;
      letter-spacing: 0;
      padding: 0px 50px;
      text-align: center;
    }
    width: 40%;
    .login-form {
      width: 100%;
      padding: 0 100px;
    }
    .form-item {
      margin: 16px 0;
      .react-tel-input {
        .flag-dropdown {
          border-radius: 25px 0 0 25px;
        }
        .selected-flag {
          &.open,
          &:hover,
          &:focus {
            background-color: transparent;
          }
        }
      }
      .form-control {
        width: 100%;
        border-radius: 25px;
        height: 40px;
        box-shadow: unset;
      }
      &.form-button {
        text-align: center;
        .btn-form {
          font-family: 'Montserrat';
          font-weight: 500;
          background-color: $dark;
          padding: 8px 70px;
          font-size: 13px;
          color: $white;
        }
      }
      .form-label {
        font-family: 'Montserrat';
        font-weight: 400;
        font-size: 15px;
        color: $dark;
        letter-spacing: 0;
        font-weight: 700;
        line-height: 26px;
        &.form-label-required {
          position: relative;
          &::after {
            content: '*';
            position: absolute;
            right: -7px;
            top: 0;
          }
        }
      }
      .form-input {
        display: block;
        width: 100%;
        padding: 8px 16px;
        border-radius: 25px;
        outline: none;
        border: 1px solid #cfcfcf;
        font-family: 'Montserrat';
        font-weight: 400;
        font-size: 15px;
      }
      .label-checbox {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        user-select: none;
        font-size: 14px;
        .link {
          color: $dark;
          text-decoration: underline !important;
          font-family: 'Montserrat';
          font-weight: 400;
          font-size: 14px;
        }
        .checkbox {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }
        .checkmark {
          position: absolute;
          top: 0px;
          left: 0;
          height: 20px;
          width: 20px;
          background-color: transparent;
          border: 1px solid $border-bussiness;
          border-radius: 2px;
          &:after {
            content: '';
            position: absolute;
            display: none;
            left: 6px;
            top: 2px;
            width: 6px;
            height: 12px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
      /* On mouse-over, add a grey background color */
      .label-checbox:hover .checkbox ~ .checkmark {
        background-color: #ccc;
      }
      /* When the checkbox is checked, add a blue background */
      .label-checbox .checkbox:checked ~ .checkmark {
        background-color: #2196f3;
      }
      /* Show the checkmark when checked */
      .label-checbox .checkbox:checked ~ .checkmark:after {
        display: block;
      }
    }
    .form-link {
      display: block;
      text-align: center;
      color: $dark;
      text-decoration: underline !important;
      font-family: 'Montserrat';
      font-weight: 300;
      font-size: 12px;
    }
    .text-or {
      position: relative;
      display: block;
      text-align: center;
      padding: 10px 0;
      margin: 10px 0;
      color: $text-grey;
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 12px;
      &::after {
        content: '';
        position: absolute;
        left: 10%;
        top: 20px;
        width: 30%;
        height: 1px;
        background-color: $text-grey;
      }
      &::before {
        content: '';
        position: absolute;
        right: 10%;
        top: 20px;
        width: 30%;
        height: 1px;
        background-color: $text-grey;
      }
    }
    .form-social {
      display: flex;
      align-items: center;
      justify-content: center;
      .social-item {
        margin: 0 10px;
        position: relative;
        width: 40px;
        height: 40px;
        background-color: $dark;
        border-radius: 100%;
        .social-icon {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    .text-registered {
      text-align: center;
      display: block;
      margin-top: 30px;
      .link-registered {
        color: $dark;
        text-decoration: underline !important;
        font-family: 'Montserrat';
        font-weight: 400;
        font-size: 14px;
        &.link-not-underline {
          font-family: 'Montserrat';
          font-weight: 600;
          text-decoration: unset !important;
        }
      }
    }
  }
  .login-btn-cs{
    font-family: 'Montserrat';
    font-weight: 500;
    background-color: #000;
    padding: 8px 70px;
    font-size: 13px;
    color: #fff;
  }
}

.popup-login {
  position: unset !important;
  height: unset !important;
  background-color: #fff;
  padding-top: unset !important;
  padding: 30px;
}

.help-password {
  display: block;
  font-family: 'Montserrat';
  font-weight: 350;
  font-style: italic;
  font-style: 11px;
  margin-top: 2px;
  color: rgba($color: $dark, $alpha: 0.6);
}
