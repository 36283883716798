.store-booking {
	font-family: "Montserrat";

	.step-booking__title {
		letter-spacing: 0px;
		margin: 0px;
		min-width: 0px;
		font-size: 15px;
		font-weight: 400;
		line-height: 21px;
		color: rgb(135, 140, 147);
		padding-top: 15px;

		i {
			color: #000;
			cursor: pointer;
			margin-right: 15px;
			padding-left: 5px;
		}
	}

	.heading-title {
		padding: 10px 0;
		font-size: 29px;
		font-weight: 600;
	}

	.adds-list-view {
		flex-direction: column !important;
		padding: 25px !important;

		.adds-content {
			align-items: start !important;
		}
	}

	.tab-heading {
		border-bottom: unset !important;
		padding-bottom: 0;

		&::before {
			content: none;
		}
	}

	.staff-list {
		margin-bottom: 20px;
		display: flex;

		.staff-pick {
			.ava-img {
				border: 1px solid #26b3e1;
				padding: 5px;
			}

			.staff-name {
				font-size: 10px !important;
				text-align: center;
			}
		}

		.staff-item {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 0 15px;

			.staff-img {
				position: relative;

				.ava-img {
					width: 50px;
					height: 50px;
					object-fit: cover;
					border-radius: 50%;
					cursor: pointer;
				}

				.icon-check {
					width: 20px;
					height: 20px;
					height: 20px;
					position: absolute;
					right: -10px;
					bottom: 0;
				}
			}

			.staff-name {
				font-size: 12px;
				font-weight: 500;
				margin-top: 5px;
			}
		}
	}

	.sidebar-option-content {
		width: 90%;

		.sidebar-option-list {
			background-color: rgb(248, 248, 251);
			margin-top: 20px;
			padding: 15px;

			.sidebar-option-item {
				border-bottom: 1px solid #d0caca;
				padding: 15px 0;

				&:last-child {
					border-bottom: none;
				}

				.option-control-left {
					display: flex;
					justify-content: space-between;
					text-align: right;

					.service-item__name {
						width: 60%;
						font-size: 13px;
					}

					.price-options {
						font-size: 13px;
						font-weight: 500;
						width: 30%;
					}

					.checkbox-wrapper-15 {
						width: 10%;
					}
				}
			}
		}
	}

	@include breakpoint(992) {
		.step-3 {
			margin-top: 15px;

			.float_cart {
				display: unset;
			}
		}

		.time-slot-content {
			margin-top: 15px;
		}
	}

	.float_cart {
		border-radius: 8px;
		margin-top: 45px;
		top: 55px;
		box-shadow: none;

		.block_cart {
			border-radius: 8px;
			padding: 15px 32px;

			.store_info {
				margin-bottom: 15px;

				.title_head {
					color: #000;
					font-size: 18px;
				}

				.store_addr {
					font-weight: 400;
					font-size: 12px;
					color: gray;
				}
			}

			.time-info {
				padding-bottom: 15px;
				margin-bottom: 15px;
				border-bottom: 1px solid #d0caca;

				.time-info__book {
					font-size: 16px;
					font-weight: 600;
					line-height: 24px;
				}

				.time-info__est {
					font-size: 14px;
					font-weight: 500;
					line-height: 21px;
					color: #878c93;
				}
			}

			.items {
				padding-bottom: 15px;
				margin-bottom: 15px;
				border-bottom: 1px solid #d0caca;
			}

			.service-item {
				display: flex;

				.service-content {
					width: 70%;
				}

				.service-item__name {
					font-size: 13px;
					font-weight: 500;
				}

				.adds-price {
					text-align: right;
					white-space: nowrap;
					font-size: 13px;
					padding-left: 5px;
				}
			}

			.staff {
				margin-top: 10px;

				.staff-title {
					font-size: 20px;
					font-weight: 600;
					margin-bottom: 10px;
				}

				&-info {
					display: inline-flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;

					&__img {
						img {
							width: 50px;
							height: 50px;
							object-fit: cover;
							border-radius: 50%;
						}
					}

					&__name {
						font-size: 12px;
						font-weight: 500;
						margin-top: 5px;
						text-align: center;
					}
				}
			}

			.total {
				padding-bottom: 10px;
				border-bottom: 1px solid #d0caca;

				.total_price {
					font-size: 14px;
				}
			}
		}

		.empty-container {
			height: 30px;

			.empty-title {
				font-size: 13px;
			}
		}
	}

	.btn-book-now {
		border-radius: 8px !important;
		max-width: 110px;
		padding: 12px;
		font-size: 16px;
	}

	.booking-form {
		.DayPicker__horizontal {
			margin: 0 auto;
		}

		.time-slot-content {
			background-color: #fff;
			border: 1px solid rgb(211, 211, 211);
			border-radius: 5px;
			padding: 0;
			max-height: 300px;
			min-height: 300px;
			height: unset;
			
			.timeslots-full {
				max-height: 300px;
				min-height: 294px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}

			.time-slot-item {
				padding: 15px 32px;
				display: flex;
				align-items: center;
				position: relative;

				&:hover {
					cursor: pointer;
					background: rgb(242, 242, 244);
				}

				&::after {
					bottom: 0px;
					content: "";
					position: absolute;
					left: 0px;
					right: 0px;
					height: .5px;
					background: rgb(211, 211, 211);
				}

				.time {
					flex-direction: column;
					-webkit-box-flex: 1;
					flex-grow: 1;
					order: 2;
					min-width: 0px;
					-webkit-box-pack: center;
					justify-content: center;

					p {
						letter-spacing: 0px;
						margin: 0px;
						min-width: 0px;
						font-size: 17px;
						font-weight: 600;
						line-height: 24px;
						color: rgb(16, 25, 40);
					}
				}

				.time-icon {
					flex-basis: auto;
					-webkit-box-flex: 0;
					flex-grow: 0;
					margin-left: 16px;
					order: 3;
					font-size: 20px;
				}
			}
		}
	}

	.checkout-content {
		border-radius: 8px;

		.check-label {
			margin-bottom: 10px;
		}

		.form-label {
			font-size: 14px;
			color: #000;
			font-weight: 700;
			margin-bottom: 0;
		}

		.form-input {
			display: block;
			width: 100%;
			padding: 8px 16px;
			outline: none;
			border: 1px solid #cfcfcf;
			font-weight: 400;
			font-size: 15px;
			border-radius: 4px;
		}

		.checkout-title {
			display: flex;
			align-items: center;
		}
	}
}

.service-item {
	@include breakpoint(992) {
		.adds-content {
			flex-direction: unset !important;
		}
	}

	.service-content {
		width: 60%;
	}

	.service-price {
		width: 30%;

		.price-options {
			font-size: 13px;
			font-weight: 500;
		}
	}

	.service-cbx {
		width: 10%;
	}

	.service-item__name {
		font-weight: 400;
		font-size: 15px;
		color: #000000;
		letter-spacing: 0;
		line-height: 17px;
		margin-bottom: 5px;
		padding-bottom: 0px !important;
		display: block;
	}

	.service-item__duration {
		font-weight: 400;
		font-size: 12px;
		color: gray;
		margin-bottom: 5px;
	}

	.service-item__desc {
		width: 90%;

		p {
			color: black;
			font-weight: 500;
			font-size: 13px;
			margin-bottom: 0;
			text-align: justify;
		}
	}
}


.checkbox-wrapper-15 .cbx {
	-webkit-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: transparent;
	cursor: pointer;
}

.checkbox-wrapper-15 .cbx span {
	display: inline-block;
	vertical-align: middle;
	transform: translate3d(0, 0, 0);
}

.checkbox-wrapper-15 .cbx span:first-child {
	position: relative;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	transform: scale(1);
	vertical-align: middle;
	border: 1px solid #B9B8C3;
	transition: all 0.2s ease;
}

.checkbox-wrapper-15 .cbx span:first-child svg {
	position: absolute;
	z-index: 1;
	top: 8px;
	left: 6px;
	fill: none;
	stroke: white;
	stroke-width: 2;
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-dasharray: 16px;
	stroke-dashoffset: 16px;
	transition: all 0.3s ease;
	transition-delay: 0.1s;
	transform: translate3d(0, 0, 0);
}

.checkbox-wrapper-15 .cbx span:first-child:before {
	content: "";
	width: 100%;
	height: 100%;
	background: #506EEC;
	display: block;
	transform: scale(0);
	opacity: 1;
	border-radius: 50%;
	transition-delay: 0.2s;
}

.checkbox-wrapper-15 .cbx span:last-child {
	margin-left: 8px;
}

.checkbox-wrapper-15 .cbx span:last-child:after {
	content: "";
	position: absolute;
	top: 8px;
	left: 0;
	height: 1px;
	width: 100%;
	background: #B9B8C3;
	transform-origin: 0 0;
	transform: scaleX(0);
}

.checkbox-wrapper-15 .cbx:hover span:first-child {
	border-color: #3c53c7;
}

.checkbox-wrapper-15 .inp-cbx:checked+.cbx span:first-child {
	border-color: #3c53c7;
	background: #3c53c7;
	animation: check-15 0.6s ease;
}

.checkbox-wrapper-15 .inp-cbx:checked+.cbx span:first-child svg {
	stroke-dashoffset: 0;
}

.checkbox-wrapper-15 .inp-cbx:checked+.cbx span:first-child:before {
	transform: scale(2.2);
	opacity: 0;
	transition: all 0.6s ease;
}

.checkbox-wrapper-15 .inp-cbx:checked+.cbx span:last-child {
	color: #B9B8C3;
	transition: all 0.3s ease;
}

@keyframes check-15 {
	50% {
		transform: scale(1.2);
	}
}