.ui.star.rating .active.icon {
  color: #f1c40f !important;
  text-shadow: none !important;
}

.active.icon {
  color: #f1c40f !important;
  text-shadow: none !important;
}

.overview-container {
  .ui.top.attached.label {
    border-radius: 0;
    z-index: 100;
    .icon {
      margin: 0;
    }
  }
  .ui.top.attached.label:first-child + :not(.attached),
  .ui.top.attached.label + [class*="right floated"] + * {
    margin-top: 0 !important;
  }
  .ui.scrolling.dropdown .menu .item.item.item {
    font-size: 13px;
  }
}

.choose-data {
  border: 1px solid #f1f1f1;
  box-shadow: none;
}

.create-product {
  .form-group {
    .ui.scrolling.dropdown .menu {
      width: auto !important;
    }
    .dropdown {
      width: 100%;
    }
    .slider-content-main {
      overflow: hidden;
    }
  }
}

.tab-choose-data {
  padding: 20px 0;
  .secondary {
    margin-bottom: -1px;
    position: relative;
    z-index: 10;
  }
  .segment {
    position: relative;
    z-index: 1;
  }
  .adds-block {
    padding: 15px;
  }
  .item {
    border: 1px solid #f1f1f1;
    background-color: #f9f9f9;
    display: inline-block;
    padding: 5px 15px;
    &.active {
      background-color: #fff;
      border-bottom-color: #fff;
    }
  }
  .category-list {
    max-height: 450px;
    overflow: auto;
  }
  .item-list.make-grid {
    border: 1px solid #f1f1f1 !important;
    box-shadow: 0 0 5px #d9d9d9;
  }
}

.react-confirm-alert-overlay {
  z-index: 10000;
}
.react-confirm-alert-body {
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.2);
  font-size: 15px;
  padding: 20px;
  h1 {
    font-size: 19px;
  }
}

.badge-new,
.badge-pending,
.badge-in-progress,
.badge-cancelled,
.badge-delivered,
.badge-done,
.badge-confirmed,
.badge-preparing,
.badge-booked,
.badge-cancel-requested {
  color: #fff;
  font-size: 11px;
  line-height: 1.27;
  padding: 4px 7px 3px;
}
.badge-new,
.badge-pending,
.badge-delivered,
.badge-confirmed,
.badge-preparing,
.badge-cancel-requested {
  background-color: $brand-warning;
}
.badge-in-progress {
  background-color: $blue;
}
.badge-cancelled {
  background-color: #6c757d;
}
.badge-done,
.badge-booked {
  background-color: $brand-success;
}
.styles_overlay__CLSq- {
  background-color: rgba($color: #131c33, $alpha: 0.65);
  z-index: 10000;
}
.styles_modal__gNwvD {
  background: none;
  box-shadow: none;
  max-width: 900px;
  .styles_closeButton__20ID4 {
    top: 23px;
    right: 23px;
    opacity: .6;
  }
}
.pagination-bar {
  overflow: hidden;
  .page-link:focus {
    box-shadow: none;
  }
}
.popup-body-content {
  background-color: #fff;
  width: 375px !important;
  &.popup-body-business {
    width: 475px !important;
  }
  .card-title {
    margin-top: -12px;
    margin-bottom: 0px;
  }
}
.main-slideshow-container,
.slideshow-container,
.feature-products-container {
  .slick-arrow {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.9);
    width: 30px;
    height: 30px;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
  .slick-arrow:before {
    color: #000;
    font-family: "fontello";
    font-size: 14px;
    font-weight: 700;
  }
  .slick-next:before {
    content: "\e83c";
  }
  .slick-prev:before {
    content: "\e83b";
  }
}

.overview-container {
  .__react_component_tooltip.type-dark {
    margin-top: -5px;
    padding: 5px 10px;
    font-size: 12px;
  }
}

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background-color: #fff;
  border-color: #e4e7e7;
  color: #d2d2d2;
}
.sc-bdVaJa {
  width: 100%;
  .sc-ifAKCX {
    font-size: 90%;
    color: #e74c3c;
  }
}
#field-wrapper {
  border: 1px solid #dfdfdf;
  label {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.language-container {
  padding: 8px;
  min-width: 51px;
  text-align: right;
  @include breakpoint(767) {
    padding: 8px 0;
  }

  .text {
    font-size: 13px;
  }
  .ui.dropdown .menu {
    left: auto;
    right: 0;
  }
  .ui.dropdown .menu > .item {
    font-size: 12px;
    padding: 7px 6px !important;
  }
  .ui.dropdown .menu > .item > img {
    width: 15px;
    height: auto;
    margin: 0 5px 0 0;
  }
  .ui.dropdown .menu .active.item {
    background-color: #dfdfdf;
    font-weight: 400;
  }
  .text {
    .icon-lang {
      display: block;
      width: 16px;
      height: 16px;
      text-indent: -999999px;
    }
    .icon-lang-vi {
      background: url(/assets/images/flags/vi.svg) no-repeat center;
      background-size: contain;
    }
    .icon-lang-en {
      background: url(/assets/images/flags/en.svg) no-repeat center;
      background-size: contain;
    }
  }
}

@media screen and (max-width: 767px) {
  .language-container {
    min-width: 40px;
    padding: 8px 0;
  }
}

.loading-top-bar {
  background-color: $orange;
  height: 2px;
  position: fixed;
  top: 0;
  z-index: 9999;
}

.ui.form .required.field > .checkbox:after,
.ui.form .required.field > label:after,
.ui.form .required.fields.grouped > label:after,
.ui.form .required.fields:not(.grouped) > .field > .checkbox:after,
.ui.form .required.fields:not(.grouped) > .field > label:after {
  margin: -0.2em 0 0 0.2em;
  content: "*";
  color: #db2828;
}
.ui.breadcrumb a {
  color: $brand-primary;
  font-size: 14px;
  &:hover {
    color: #0d5d4d;
  }
}
.ui.breadcrumb .active.section {
  font-size: 14px;
}

.autocomplete-dropdown-container {
  background-color: #fff;
  position: absolute;
  z-index: 10;
}
.autocomplete-dropdown-container > div {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: 0;
  padding: 5px;
}

.autocomplete-dropdown-container div:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.icon-qr__text {
  cursor: pointer;
  margin-top: 5px;
  font-size: 11px;
  img {
    display: block;
    margin: 0 auto 2px;
  }
}

// custom ckeditor 5
.ck-editor__editable_inline {
  min-height: 150px;
}
.ql-editor {
  min-height: 150px;
}
.ql-video {
  width: 100%;
  height: 350px;
}

// custome bootstrap
// custome container bootstrap
.container {
  width: 100%;
  @media (min-width: 768px) {
    max-width: 678px !important;
  }
  @media (min-width: 900px) {
    max-width: 800px !important;
  }
  @media (min-width: 992px) {
    max-width: 888px !important;
  }
  @media (min-width: 1024px) {
    max-width: 924px !important;
  }
  @media (min-width: 1200px) {
    max-width: 1080px !important;
  }
  @media (min-width: 1280px) {
    max-width: 1120px !important;
  }
  @media (min-width: 1366px) {
    max-width: 1200px !important;
  }
}
.col-lg-2-5 {
  @media (min-width: 992px) {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

// button social
.button-social {
  display: block;
  border: 0px;
  border-radius: 0 !important;
  box-shadow: none !important;
  color: none !important;
  cursor: pointer;
  font-size: 19px;
  margin: 5px;
  width: calc(100% - 10px);
  overflow: hidden;
  padding: 0px 10px;
  height: 50px;
  border: none !important;
  background: transparent !important;
  &:focus,
  &:hover {
    background: transparent !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    color: none !important;
    outline: none !important;
  }
}

.forgot-pw {
  .notifications-tr {
    top: 80px !important;
  }  
}

.notifications-wrapper {
  z-index: 9999999 !important;

  .notification-dismiss {
    color: #000000 !important;
    background-color: #ffffff !important;
  }
  .notifications-tr {
    z-index: 9999999 !important;
  }
  .notification-error {
    z-index: 9999999 !important;
  }
}
// custom tree select
.rc-tree-select-arrow {
  top: 3px !important;
  right: 3px !important;
}
.rc-tree-select.rc-tree-select-enabled.rc-tree-select-allow-clear {
  width: 100%;
}
.rc-tree-select-selection__rendered {
  line-height: 36px !important;
}

.rc-tree-select-selection--single {
  height: 36px !important;
  cursor: pointer;
  position: relative;
}

.rc-tree-select-enabled.rc-tree-select-focused .rc-tree-select-selection {
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  box-shadow: unset !important;
}

.rc-tree-select-enabled .rc-tree-select-selection:hover {
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  box-shadow: unset !important;
}
.rc-tree-select-search__field__placeholder {
  display: block;
  padding: 4px 9px !important;
}
.rc-tree-select-search__field {
  padding: 7px !important;
}
.rc-tree-select-tree {
  li {
    padding: 4px 0 !important;
    cursor: pointer;
    &:not(:first-child) {
      border-top: 1px solid rgba(34, 36, 38, 0.15) !important;
    }
  }
}

.rc-tree-select-tree-node-selected {
  background-color: transparent !important;
  border: unset !important;
  color: $primary;
  opacity: 0.8;
}

@media only screen and (max-width: 844px) {
  .styles_modal__gNwvD {
    padding: 0px !important;
    margin: auto;
    position: relative;
    top: 30px;
  };
  .styles_closeButton__20ID4 {
    top: 5px !important;
    right: 5px !important;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}


@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100px, 0, 0);
    transform: translate3d(-100px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100px, 0, 0);
    transform: translate3d(100px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
