.guest-contacts {
	@media (min-width: 992px) {
		&:hover .guest-contacts-list {
			display: block !important;
		}
	}

	i {
		position: relative;
		color: #fff;
		// cursor: pointer;
		// margin: 5px 15px 0 0;
		font-size: 20px;

		.contact-total {
			position: absolute;
			right: -13px;
			top: -7px;
			width: 18px;
			text-align: center;
			justify-content: center;
			display: flex;
			height: 18px;
			border-radius: 100%;
			background-color: #e22121;
			color: #fff;
			font-size: 10px;
			font-family: 'fontello';
			font-weight: 600;
			align-items: center;
		}

		@include breakpoint(992) {
			margin: 0;
		}
	}

	.guest-contacts-list {
		position: absolute;
		right: 0;
		background: #fff;
		border-radius: 10px;
		padding: 1rem !important;
		box-shadow: grey 0px 7px 29px 0px;
		font-size: 12px !important;
		max-height: 250px;
		width: 230px;
		max-width: 230px;
		overflow-y: auto;
		z-index: 999999;

		@include breakpoint(992) {
			right: 100px;
		}

		&::-webkit-scrollbar {
			width: 8px;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			box-shadow: inset 0 0 5px unset;
			border-radius: 10px;
			height: 80px;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			opacity: 0.4;
			background-color: $scrollbars;
			border-radius: 3.5px;
			height: 6px;
		}

		.guest-contacts-list__title {
			margin: 0 0 5px -5px;
			font-weight: bold;

			i {
				color: #000;
				margin: 0 5px 0 0;
				font-size: 13px;
			}
		}

		.guest-contacts__item {
			margin-bottom: 5px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.icon-contact {
				cursor: pointer;
				margin: -2px 0 0 5px;
				color: #000;
				font-size: 10px;
				display: inline-block;
				border-radius: 50%;
				box-shadow: 0 0 1px #888;
				padding: 2px 4px;
			}

			a {
				display: flex;
				align-items: center;

				.guest-contacts__item-name {
					margin-left: 10px;
					color: #000;
					white-space: nowrap;
					width: 140px;
					max-width: 140px;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
	}
}

.wallet {
	img {
		max-width: 200px !important;
		height: 80px;
		object-fit: cover;

		@media (max-width: 767px) {
			max-width: none !important;
		}
	}

	.wallet-table {
		margin: 15px 0 15px 0;
		max-height: 500px;
		overflow: auto;

		&::-webkit-scrollbar {
			width: 8px;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			box-shadow: inset 0 0 5px unset;
			border-radius: 10px;
			height: 80px;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			opacity: 0.4;
			background-color: $scrollbars;
			border-radius: 3.5px;
			height: 6px;
		}
	}
}