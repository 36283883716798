video {
  clip-path: inset(1px 1px);
}
.heading-container {
  margin-top: 0;
  .heading-title {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 30px;
    color: #000000;
    letter-spacing: 0;
    @include breakpoint(678) {
      font-size: 18px;
    }
  }
  .heading-norm {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 18px;
    color: $text-strong;
    letter-spacing: 0;
    line-height: 27px;
    @include breakpoint(678) {
      margin-top: -5px;
      font-size: 16px;
    }
  }
}
.image {
  width: 100%;
}
.row-slider {
  margin: 0;
}
.list-star {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  .item-star {
    padding: 0 5px;
    .icon-star-large {
      width: 20px;
    }
    .icon-star-medium {
      width: 15px;
    }
    cursor: pointer;
    &:first-child {
      padding: 0;
    }
  }
}
// inner
.inner-box {
  background-color: $white;
  border-radius: 5px;
  width: 100%;
  padding: 10px 0;
  margin-bottom: 30px;
  @media (min-width: 993px) {
    box-shadow: $boxshadow-inner;
  }
  &.inner-overview {
    .category-content {
      overflow: unset;
      background-color: $white;
      max-height: unset;
      border-radius: 4px;
      .category-list {
        @include breakpoint(992) {
          display: flex;
          overflow: auto;
        }
        .category-item {
          &:last-child {
            .category-link {
              border-bottom: none;
              cursor: pointer;
            }
          }
        }
        .category-link {
          white-space: nowrap;
          position: relative;
          border-bottom: 1px solid $border-link;
          cursor: pointer;
          &:hover {
            &::before {
              height: 60%;
            }
          }
          &.category-active {
            &::before {
              height: 60%;
            }
          }
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 8px;
            height: 0%;
            width: 4px;
            background-color: $dark;
            transition: all 0.3s ease-in-out;
          }
          .icon {
            padding-right: 10px;
          }
          &:hover {
            color: $dark;
          }
        }
      }
    }
  }
  .inner-title {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 13px;
    color: $dark;
    letter-spacing: 0;
    line-height: 24px;
    padding: 0 10px;
    margin: 10px 0 5px;
    border-left: 3px solid $dark;
    text-transform: uppercase;
    .dropdown-inner {
      display: none;
    }
  }
  .bussiness-list {
    .bussiness-item {
      padding: 0 10px;
      margin-left: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &.business-between {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-right: 30px;
      }
      &.business-trading {
        display: flex;
        justify-content: left;
      }
      .category-norm {
        opacity: 0.9;
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 13px;
        color: #333333;
        letter-spacing: 0;
        line-height: 24px;
      }
    }
  }
  .tab-list {
    display: flex;
    justify-content: space-between;
    .tab-item {
      text-align: center;
      width: 50%;
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 46px;
      font-weight: 700;
      border-bottom: 2px solid $border-link;
      color: $dark;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &.tab-active {
        color: $primary;
        border-bottom: 2px solid $primary;
      }
    }
  }
  .inner-search {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    .icon-search {
      position: absolute;
      right: 15px;
      top: 8px;
      cursor: pointer;
      width: 20px;
    }
    .inner-input {
      width: 100%;
      outline: none;
      border: 1px solid $border-link;
      padding: 8px 18px;
      font-family: "Montserrat";
      font-weight: 300;
      opacity: 0.6;
      color: $dark;
      border-right: none;
      border-left: none;
    }
  }
  .info-user {
    margin-bottom: 10px;
    .logo-user {
      width: 60px;
      height: 60px;
      .logo-img {
        width: 100%;
      }
    }
    .user-content {
      .user-title {
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 13px;
        color: $dark;
        letter-spacing: 0;
        line-height: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .user-norm {
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 13px;
        color: #131c33;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  @include breakpoint(992) {
    &.inner-overview {
      display: none;
      &.inner-overview-user {
        display: block;
      }
      &.inner-overvier-active {
        display: block;
        width: calc(100% + 15px);
        margin-right: -15px;
      }
    }
  }
}
.form-item {
  position: relative;
  &.form-error {
    .form-input {
      border: 1px solid $error-input !important;
      background-color: $error-input-bg !important;
    }
    .form-label {
      color: $danger !important;
    }
    .error-norm {
      color: $danger;
      font-size: 13px;
      font-family: "Montserrat";
      font-weight: 600;
    }
  }
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  .pagination-item {
    margin: 0 16px;
    .pagination-link {
      font-family: "Montserrat";
      font-weight: 400;
      font-size: 14px;
      color: rgba($color: $dark, $alpha: 0.6);
      &:hover {
        font-weight: 700;
        color: rgba($color: $dark, $alpha: 1);
      }
      &.pagination-link-active {
        font-weight: 700;
        color: rgba($color: $dark, $alpha: 1);
      }
    }
    &.pagination-item-prev {
      .prev,
      .next {
        position: relative;
        display: block;
        width: 30px;
        height: 30px;
        background-color: $white;
        border-radius: 100%;
        border: 1px solid #c8c8c8;
        cursor: pointer;
        .icon {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

.dropdown-language {
  display: inline-block;
  position: relative;
  margin: 0 10px;
  align-self: center;
  .language-checkbox {
    display: none;
  }
  .default-option {
    color: $white;
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 10px;
    display: flex;
    align-items: center;
    background-color: #11192e;
    border: 1px solid #11192e;
    border-radius: 20px;
    padding: 5px 10px;
    margin-bottom: 0;
    cursor: pointer;
    &::selection {
      background-color: transparent;
    }
  }
  .icon-down {
    margin-left: 5px;
    width: 8px;
  }
  .icon-flag {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    margin-right: 5px;
  }
  .list-dropdown {
    position: absolute;
    left: -50px;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.3);
    top: 80%;
    background-color: $white;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    z-index: 9999;
    min-width: 90px;
    &::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: $white;
      transform: rotate(45deg);
      left: 60px;
      top: -3px;
    }
    .item-dropdown {
      display: flex;
      align-items: center;
      text-align: left;
      padding: 5px;
      cursor: pointer;
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 10px;
    }
  }
  .language-checkbox:checked ~ .list-dropdown {
    top: 110%;
    visibility: visible;
    opacity: 1;
  }
}
.comment-container .tab-details .list-comment .comment-item .comment-avatar {
  width: 50px;
}
.main-container {
  min-height: 50vh;
}
.logo-stores.serivice-detail {
  position: relative;
}
.logo-stores.serivice-detail .qrcode.icon {
  position: absolute;
  bottom: 6px;
  left: -4px;
}
.shop-now-slider .slider-item {
  height: 500px;
}

.list-comment {
  .video {
    height: 140px;
    .video-content {
      .video-item {
        .video-title {
          display: -webkit-box;
          line-height: 1.4;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .content {
          display: -webkit-box;
          line-height: 1.4;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-left: 15px;
        }
      }
    }
  }
  .comment {
    height: 100px;
    overflow: hidden;
    .comments {
      .comment-item {
        .comment-content {
          .comment-name {
            display: -webkit-box;
            line-height: 1.4;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .comment-norm {
            display: -webkit-box;
            line-height: 1.4;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  .user-video {
    .user-content {
      .user-name {
        display: -webkit-box;
        line-height: 1.4;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .video-title {
        display: -webkit-box;
        line-height: 1.4;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.list-view {
  cursor: pointer;
  width: 25px;
  text-align: right;
  margin-left: 10px;
  @include breakpoint(992) {
    display: none;
  }
}

.btn-duration {
  background: none !important;
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 20px !important;
  height: 42px;
  padding: 12px !important;
}
.btn-duration.minus {
  right: 40px;
}
.carbeyvideo {
  width: 100%;
}

.overlay-video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 10;
}

video {
  display: block;
}
video.banner-video {
  width: 100%;
  height: 100%;
}
.list-item-image {
  .fa-video {
    top: 10px;
    right: 10px;
    position: absolute;
  }
}

.adds-images-stock:hover{
  .in-stock {
    opacity: 0;
    transition: all 0.5s;
  }
  .out-stock {
    opacity: 0;
    transition: all 0.5s;
  }
}

.adds-images-stock {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .in-stock {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 15px 0 2px;
    font-weight: 600;
    font-size: 8px;
    text-align: left;
    background-color: #028ab8;
    color: #fff;
    transition: all 0.5s;
    pointer-events: none;
    -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%, 85% 100%, 100% 0);
    clip-path: polygon(0 0, 0 100%, 100% 100%, 85% 100%, 100% 0);
  }
  .out-stock {
    position: absolute;
    top: 0;
    left: 0;
    font-weight: 600; 
    padding: 0 15px 0 2px;
    font-size: 8px;
    text-align: left;
    background-color: #707277;
    color: #fff;
    transition: all 0.5s;
    pointer-events: none;
    -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%, 85% 100%, 100% 0);
    clip-path: polygon(0 0, 0 100%, 100% 100%, 85% 100%, 100% 0);
  }
}

.adds-images {
  .fa-video {
    top: 10px;
    right: 10px;
    position: absolute;
  }
  .cornernew {
    background: #028ab8;
    left: -29%;
    top: 7%;
    color: #fff;
    display: block;
    font-family: Montserrat Condensed, Helvetica, Arial, Montserrat;
    font-size: 12px;
    font-weight: normal;
    text-align: center;
    text-decoration: none;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
    text-transform: uppercase;
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease 0s;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    position: absolute;
    -ms-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    width: 200px;
    z-index: 2;
    transition: all 0.3s ease 0s;
    min-height: 22px;
    line-height: 20px;
    @include breakpoint(420) {
      left: -50%;
    }
  }
}
.adds-videos-wrapper {
  .fa-video {
    top: 10px;
    right: 10px;
    position: absolute;
    z-index: 1;
  }
}
.carbeyvideo-list {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}
.loading-container {
  min-height: 100px;
  background-color: $white !important;
  margin-top: -20px;
  margin-bottom: 20px;
  padding: 20px 0;
}

.loading-adds-wrapper {
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 20px 0;
  &.loading-white {
    background-color: $white;
  }
}

#anchor {
  &.menu-breadcrum-sticky {
    position: sticky;
    top: 50px;
    left: 0;
    width: 100% !important;
    background-color: rgba($color: $heading, $alpha: 0.8);
    margin-top: 0 !important;
    transition: all 0.3s;
    z-index: 999;
    border-radius: unset !important;
    color: #ffffff;
    padding: 5px 10px;
    border-top: 1px solid #ffffff;
    @include breakpoint(992) {
      top: 80px;
      .horizontal-menu {
        margin-bottom: 0px !important;
      }
      position: fixed;
    }
    .horizontal-menu {
      width: 100% !important;
    }
    .category-link {
      display: none;
    }
    .anchor-image-logo {
      filter: brightness(0) invert(1);
    }
    .qr-code__menu, .menu-popover__content {
      color: $white !important;
    }
    .view, .menu-breadcrum {
      margin-top: 0 !important;
    }
    .heading-title {
      margin-top: 5px !important;
      color: $white !important;
    }
    .menu-item-wrapper.active {
      .menu-item {
        background-color: $white;
        color: rgba($color: $heading, $alpha: 0.8);
      }
    }
  }
}
.customIconBtn {
  fill: #d1c8c8;
}
#sharesocial {
  font-size: 20px;
  .item {
    margin: 0 5px;
    text-align: center;
  }
  .item-qr {
    cursor: pointer;
    margin: 0 7px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #000;
  }
}
.icon-qr {
  display: inline-block;
    border-radius: 50%;
    box-shadow: 0 0 1px #888;
    padding: 3px 5.5px;
    font-size: 13px;
}
.share-list {
  font-size: 12px !important;
  font-weight: normal;
  z-index: 9999;
  color: #000;
  width: 200.25px;
  box-sizing: border-box;
  position: absolute;
  top: -80px;
  right: 0;
  background: #fff;
  border-radius: 10px;
  padding: 1rem !important;
  box-shadow: gray 0px 7px 29px 0px;
  font-size: 12px !important;
}

.share-list-refer {
  top: 0 !important;
}

.share-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  div {
    margin: 0 5px 5px;
  }
}
.hide {
  display: none !important;
}
.block {
  display: block !important;
}
.cursor-pointer {
  cursor: pointer;
}

.popup-qrcode {
  background-color: #fff;
  box-sizing: content-box;
  max-width: 300px;
  width: 300px;
}

.popup-qrcode__bot {
  position: relative;
  padding: 5px 30px 10px 30px;
  border: 1px solid #cecece;
  border-top: unset !important;
  margin-top: -5px;
}

#print-document {
  .background-logo {
    width: 100%;
    height: auto;
    margin: 0 auto;
    overflow: hidden;
    // max-width: 225px;
    @include breakpoint(768) {
      img {
        height: 140px;
        object-fit: cover;
        max-height: 140px;
      }
    }
  }
}

.dimmer-qr {
  opacity: 0.6 !important;
}

.ani-contact {
  animation: contact 0.7s infinite;
  animation-direction: alternate;
}

.popup-container, .popup-scan-container {
  animation: customEnterModalAnimation 1.5s;
  animation-direction: alternate;
}

@keyframes contact {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

@keyframes customEnterModalAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.red-heart {
  color: red !important;
  font-size: 22px !important;
  margin-top: -6px !important;
}
.loading-ready {
  position: absolute;
  top: 52%;
  left: 50%;
}
.play_pause{
    position: absolute;
    top: 54%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: #131c33;
    z-index: 999;
    // opacity: 0;
    pointer-events: none;
    font-size: 4em;
    // .bx {
    //   background: rgba(0, 0, 0, 0.2);
    //   border-radius: 50%;
    // }
}
.play_pause.show{
    opacity: 1;
    pointer-events: none;
}

$animationDuration: 3s;
$amount: 6;
$particleRadius: 2vmin;
.common-loading span {
  width: $particleRadius * 2;
  height: $particleRadius * 2;
  border-radius: $particleRadius;
  backface-visibility: hidden;
  position: absolute;
  animation-name: move;
  animation-timing-function: cubic-bezier(0.4, 0, 1, 0.8);
  animation-iteration-count: infinite;
  animation-duration: $animationDuration;
  top: calc(50% - #{$particleRadius});
  left: 50%;
  transform-origin: ($particleRadius*-1) center;

  $colors: (
    #C5F0A4,
    #35B0AB,
    #226B80
  );
  
@for $i from 1 through $amount {
    &:nth-child(#{$i}) {
      background: nth($colors, random(length($colors)));
      animation-delay: ($i / $amount) * $animationDuration * -1;
      opacity: 0;
    }
  }
}

@keyframes move {
  0% {
    transform: scale(1) rotate(0deg) translate3d(0, 0, 1px);
  }
  30% {
    opacity: 1;
  }
  100% {
    z-index: 10;
    transform: scale(0) rotate(360deg) translate3d(0, 0, 1px);
  }
}

.popup-review {
  border-radius: 10px;
  position: unset !important;
  height: unset !important;
  background-color: #fff;
  padding-top: unset !important;
  padding: 26px;
  max-width: 600px;
  width: 90vw;
  h2 {
    font-size: 20px;
    color: gray;
  }
  .comment-form {
    button {
      color: #fff;
      padding: 10px 25px;
      text-transform: uppercase;
      font-weight: 600;
      background-color: #131c33;
    }
  }
  .list-comment {
    .comments {
      height: 150px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px unset;
        border-radius: 10px;
        height: 80px;
        background-color: transparent;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        opacity: 0.4;
        background-color: $trading-bg;
        border-radius: 3.5px;
        height: 6px;
      }
    }
  }
}
.noti-animation {
  animation: ani_bell 0.7s infinite;
  animation-direction: alternate;
}
@keyframes ani_bell {
  0% {
    font-size: 23px;
  }

  25% {
    font-size: 26px;
  }

  50% {
    font-size: 30px;
  }

  75% {
    font-size: 26px;
  }

  100% {
    font-size: 23px;
  }
}

.digital-slide-vertical {
  width: 150px;
  height: 250px;
  margin: 0 auto;
}

.digital-slide-horizontal {
  height: 150px;
}

@mixin track {
  ::-webkit-slider-runnable-track { @content; }
  ::-moz-range-track { @content; }
  ::-moz-focus-outer {
    border: 0;
  }
}

@mixin thumb {
  ::-webkit-slider-thumb { @content; }
  ::-moz-range-thumb { @content; }
}

.slider {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #664aff;
  
  input[type=range] {
    outline: none;
    height: 3px;
    flex: 1 1 auto;
    color: inherit;
    margin-right: 1em;
    background-color: #E3E3E3;
    background-image: linear-gradient(currentColor, currentColor);
    background-size: 0 100%;
    background-repeat: no-repeat;
    cursor: pointer;
    appearance: none;
    padding: 0;
  }

  @include track {
    border: none;
    background: transparent;
    appearance: none;
  }

  @include thumb {
    width: 16px;
    height: 16px;
    border: 0;
    background: currentColor;
    border-radius: 50% 50%;
    appearance: none;
  }
}

.slider-value {
  display: inline-block;
  position: relative;
  height: 25px;
  
  &:before, &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: .25em;
  }
  
  &:before {
    top: 0;
    background: linear-gradient(#FFF, rgba(255, 255, 255, 0));
  }
  
  &:after {
    bottom: 0;
    background: linear-gradient(rgba(255, 255, 255, 0), #FFF);
  }
}

.slider-value-number {
  height: 25px;
  overflow: hidden;
  display: inline-block;
  padding: .25em 0;
  user-select: none; 
  font-weight: 600;
  ul {
    margin-top: -8px;
    padding: 0;
    list-style-type: none;
    transition: transform .75s ease, opacity .5s ease;
    
    li {
      height: 25px;
      display: block;
      margin: 0;
      padding: .25em 0;
    }
  }
}

.mapboxgl-popup-content {
  padding: 0 !important;
}
.error{
  color: red;
}