.popup-container {
  display: flex;
  flex-wrap: nowrap;
  background-color: $white;
  width: 900px;
  .icon {
    position: absolute;
    right: 0px;
    top: 30px;
    z-index: 9;
    cursor: pointer;
  }
  .popup-more {
    width: 55%;
    background-image: url("/assets/images/popup/best_offer.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 455px;
    position: relative;
    img {
      width: 80px;
      position: absolute;
    }
    .logo {
      left: 10px;
      top: 10px;
    }
    .best-deal {
      bottom: 10px;
      right: 10px;
    }
  }
  .popup-content {
    position: relative;
    width: 45%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .content-title {
      color: $dark;
      font-family: "Montserrat";
      font-weight: 800;
      font-size: 25px;
      line-height: 40px;
    }
    .content-norm {
      font-family: "Montserrat";
      font-weight: 400;
      font-size: 15px;
      color: $dark;
      &.text-italic {
        font-style: italic;
        font-style: 11px;
      }
    }
    .content-form {
      width: 100%;
      .form-input {
        width: 100%;
        height: 48px;
        border-radius: 24px;
        border: 1px solid $dark;
        outline: none;
        padding: 0 20px;
        font-family: "Montserrat";
        font-size: 15px;
      }
      .btn-subcribe {
        background-color: $dark;
        color: $white;
        padding: 10px 30px;
      }
    }
    .label-checbox {
      position: absolute;
      right: 10px;
      bottom: 10px;
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 9px;
      display: flex;
      align-items: center;
      .checkbox {
        margin-left: 5px;
      }
    }
  }
}

.popup-scan-container {
  display: flex;
  flex-direction: column;
  background-color: $white;
  width: 550px;
  .icon {
    position: absolute;
    right: 0px;
    top: 10px;
    z-index: 9;
    cursor: pointer;
    color: #ffffff;
  }
  .popup-more {
    position: relative;
    background-image: url("/assets/images/popup/offer-store.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 455px;
    background-color: #f9fef7;
    padding: 10px;
    border-bottom: 1px solid rgba(0,0,0,0.025);
    .popup-norm {
      position: absolute;
      bottom: 5px;
      width: 100%;
      text-align: center;
      font-family: "Montserrat";
      font-weight: 400;
      font-size: 15px;
      color: $dark;
      margin-bottom: 0;
      &.text-italic {
        font-style: italic;
        font-style: 11px;
      }
    }
  }
  .popup-content {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 10px;
    .content-form {
      width: 100%;
      padding: 10px;
      .form-input {
        width: 100%;
        height: 48px;
        border-radius: 24px;
        border: 1.5px solid $dark;
        outline: none;
        padding: 0 20px;
        font-family: "Montserrat";
        font-size: 15px;
      }
      .btn-subcribe {
        background-color: $dark;
        color: $white;
        padding: 10px 30px;
      }
    }
    .label-checbox {
      display: block;
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 9px;
      display: flex;
      align-items: center;
      .checkbox {
        margin-left: 5px;
      }
    }
  }
}

.discount-container {
  position: relative;
  background-color: #ffffff;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  .close {
    color: $white;
    position: absolute;
    right: 10px;
    top: 10px;
    color: $dark;
    font-size: 15px;
  }
  .title {
    color: $dark;
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
    font-size: 25px;
    line-height: 40px;
  }
  .norm {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: $dark;
  }
  .button {
    background-color: #ffc400;
    padding: 10px 16px;
    border-radius: 30px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: $dark;
    border: 1px solid #ffc400;
    &:hover,
    :focus,
    &:active {
      background-color: #ffc400;
      border: 1px solid #ffc400;
      outline: none;
    }
  }
}
