.breadcrumb-container {
  .breadcrumb-list {
    display: flex;
    align-items: center;
    background-color: transparent;
    .item {
      position: relative;
      padding: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      opacity: 0.7;
      font-family: "Montserrat";
      font-weight: 300;
      font-size: 11px;
      color: #131c33;
      letter-spacing: 0;
      text-align: center;
      .item-link,
      .item-norm {
        opacity: 0.7;
        font-family: "Montserrat";
        font-weight: 300;
        font-size: 11px;
        color: #131c33;
        letter-spacing: 0;
        text-align: center;
      }
      &:not(:first-child) {
        padding-left: 15px;
        &::before {
          position: absolute;
          content: url("/assets/images/icons/arrow-breadcrumb.svg");
          top: 55%;
          left: 0px;
          transform: translate(0%, -50%);
        }
      }
      .icon {
        margin-top: -2px;
      }
    }
  }
}
.details-container {
  .search-container {
    .ui.selection.dropdown {
      min-width: unset;
      max-width: 150px;
      width: 150px;
      font-size: 11px;
      .item {
        font-size: 11px;
      }
    }
  }
  .heading-results {
    font-family: "Montserrat";
    font-weight: bold;
    font-size: 14px;
    color: #028ab8;
    border-bottom: #028ab8 2px solid;
    display: inline-block;
  }
  .adds-block {
    margin: 10px 0 0;
    padding: 10px 0;
  }
  .logo-stores {
    background: $white;
    box-shadow: 0 2px 24px 0 $boxshadow-logo;
    border-radius: 11px;
    .logo-img {
      @media only screen and (max-width: 600px) {
        height: 50%;
      }
      width: 100%;
      object-fit: cover;
      height: 100%;
      max-height: 250px;
      border-radius: 4px;
    }
  }
  .category-details {
    background-color: $white;
    border-radius: 5px;
    .block-option {
      box-shadow: none;
    }
    .thumb-image {
      .btn-category {
        width: 100%;
        border-radius: unset;
        .icon {
          display: unset;
        }
      }
    }
    .category-content {
      padding: 0 0 0 20px;
      .category-type {
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 13.83px;
        color: $heading;
        letter-spacing: 0;
        font-weight: 700;
        .type {
          color: $primary;
        }
      }
      .category-title {
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 15px;
        color: $text-title;
        letter-spacing: 0;
      }
      .category-price {
        font-family: "Montserrat";
        font-weight: 400;
        font-weight: 700;
        font-size: 15px;
        letter-spacing: 0;
        color: $dark;
        &.text-underline {
          text-decoration: line-through;
          color: $danger;
        }
        &.category-price-red {
          color: $danger;
        }
        &.category-in-stock {
          color: #028ab8;
        }
        &.category-out-stock {
          color: #e0e0e0;
        }
      }
    }
    .slider-details {
      padding: 20px 0px 20px 80px;
      min-height: 350px;
      &.slider-empty {
        padding: 20px 0px 20px 20px;
      }
      .category-img {
        width: 100%;
        height: 100%;
        max-height: 500px;
        object-fit: contain;
      }
      &.slick-dotted {
        margin-bottom: 0;
      }
      .slick-dots {
        position: absolute;
        left: 20px;
        top: 13px;
        z-index: 999;
        display: block;
        width: auto;
        overflow: hidden;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 8px;
          display: none;
        }
        li {
          display: block;
          width: 50px;
          height: auto;
          max-height: 75px;
          margin: 7px 0;
          border: 1px solid $white;
          &.slick-active {
            border: 1px solid $active-slick;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .slick-arrow {
      &.slick-prev {
        position: absolute;
        left: 32px;
        top: 30px;
        z-index: 9999;
        transform: rotate(-90deg);
        &::before {
          background-size: 25px 25px;
          background-repeat: no-repeat;
          display: inline-block;
          width: 25px;
          height: 25px;
          content: "";
          background-image: url("/assets/images/home/arrow-next.svg");
        }
      }
      &.slick-next {
        position: absolute;
        left: 34px;
        top: unset;
        right: unset;
        bottom: 25px;
        z-index: 9999;
        transform: rotate(90deg);
        &::before {
          background-size: 25px 25px;
          background-repeat: no-repeat;
          display: inline-block;
          width: 25px;
          height: 25px;
          content: "";
          background-image: url("/assets/images/home/arrow-next.svg");
        }
      }
    }
  }
}
.delivery-container {
  padding: 20px 0 40px;
  background-color: $white;
  .delivery-item {
    .delivery-title {
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 15px;
      color: $text-delivery;
      letter-spacing: 0;
      text-align: left;
      margin-bottom: 5px;
    }
    .delivery-content {
      display: flex;
      flex-wrap: nowrap;
      justify-content: start;
      .delivery-img {
        padding-right: 10px;
      }
      .delivery-norm {
        opacity: 0.69;
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 13px;
        color: #000000;
        letter-spacing: -0.11px;
        line-height: 20px;
      }
    }
  }
}
.newsletter-container {
  background-color: $newsletter-bg;
  padding: 40px 0;
  .newsletter-title {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 22px;
    color: $white;
    letter-spacing: 0;
  }
  .newsletter-norm {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 13px;
    color: $white;
    letter-spacing: 0;
    line-height: 23px;
  }
  .newsletter-form {
    width: 100%;
    .newsletter-input {
      font-family: "Montserrat";
      font-weight: 400;
      font-size: 15px;
      color: $dark;
      letter-spacing: 0;
      outline: none;
      border: none;
      padding: 8px 16px;
      border: 1px solid $dark;
      border-radius: 24px;
      background-color: $white;
      min-width: 250px;
      margin: 5px 0;
    }
    .btn-subscribe {
      border: 1px solid $border-bussiness;
      margin-left: 10px;
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 13px;
      color: $white;
      letter-spacing: 0;
      text-align: center;
      background-color: transparent;
    }
  }
}
.comment-container {
  background-color: $white;
  border-radius: 4px;
  &.comment-container-responsive {
    display: none;
  }
  .menu {
    display: flex;
    border-bottom: 1px solid rgba($color: $border-bussiness, $alpha: 0.3);
    .item {
      width: 33.333%;
      position: relative;
      padding: 10px 20px;
      font-family: "Montserrat";
      font-weight: 600;
      font-size: 12px;
      color: $dark;
      letter-spacing: 0;
      line-height: 46px;
      margin-bottom: 0px;
      &::after {
        content: "";
        position: absolute;
        width: 0%;
        height: 3px;
        left: 0;
        bottom: -8px;
        background-color: $primary;
        transition: all 0.3s ease-in-out;
      }
      &.active {
        color: $primary;
        &::after {
          width: 100%;
        }
      }
    }
  }
  .tab-details {
    max-height: 600px;
    overflow: auto;
    padding: 20px;
    &::-webkit-scrollbar {
			width: 8px;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			box-shadow: inset 0 0 5px unset;
			border-radius: 10px;
			height: 80px;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			opacity: 0.4;
			background-color: $scrollbars;
			border-radius: 3.5px;
			height: 6px;
		}
    figure img {
      width: 100%;
    }
    .tab-title {
      font-family: "Montserrat";
      font-weight: 600;
      font-size: 17px;
      color: $dark;
      letter-spacing: 0;
    }
    > .list-star {
      .icon-star {
        width: 30px;
      }
    }
    .comment-form {
      display: block;
      .form-input {
        width: 100%;
        outline: none;
        min-height: 70px;
        border: 1px solid #e0e0e0;
        padding: 10px;
        font-family: "Montserrat";
        font-weight: 400;
      }
      .btn-comment {
        background: $dark;
        border-radius: 20px;
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 13px;
        color: $white;
        letter-spacing: 0;
        text-align: center;
        padding: 10px 40px;
        border: none;
      }
    }
    .list-comment {
      .comment-item {
        display: flex;
        justify-content: start;
        margin: 10px -20px;
        padding: 0 20px 10px;
        &:not(:last-child) {
          border-bottom: 2px solid rgba($color: $border-bussiness, $alpha: 0.3);
        }
        .comment-avatar {
          min-width: 50px;
          .comment-img {
            width: 100%;
          }
        }
        .comment-content {
          padding: 0 20px;
          font-family: "Montserrat";
          font-weight: 400;
          .comment-name {
            font-weight: 600;
            font-size: 17px;
            color: $dark;
            letter-spacing: 0;
          }
          .comment-norm {
            font-size: 14px;
            color: $dark;
            letter-spacing: 0;
            line-height: 25px;
          }
        }
      }
    }
  }
}
