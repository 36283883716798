.pos {
	.store-info {
		display: flex;
		align-items: center;

		img {
			object-fit: cover;
			width: 120px;
		}

		&__name {
			font-weight: 600;
		}
	}
	.fullscreen {
		margin-left: 20px;
		display: inline-block;
		background: rgba(0, 0, 0, 0.04);
		border-radius: 50%;
		padding: 5px;
		border: 1px solid rgba(0, 0, 0, 0.17);
		img {
			width: 20px;
			cursor: pointer;
		};
	}
	.secondary.menu {
		display: flex;
    margin-bottom: 10px;
		padding-bottom: 5px;
    border-bottom: 1px solid #d0caca;
		.item {
			width: 33.33%;
			font-weight: 600;
			font-size: 12px;
			color: #bcc0c1;
			margin-bottom: 0px;
			&.active {
				color: $black;
			}
		}
	}
	.table-orders {
		max-height: calc(100vh - 425px);
		.table {
			font-size: 11px;
		}
	}
	.pagination-bar {
		margin-top: 5px;
	}
	.overview-container {
		max-height: calc(100vh - 315px);
		overflow: auto;
		.order-item__info-name {
			font-size: 10px;
		}
		.order-item__price {
			font-size: 10px;
		}
	}
	.pos-bill {
		background-color: $white;
		padding: 10px;
		border-top-right-radius: 5px;
		border-top-left-radius: 5px;
		height: calc(100vh);
		position: sticky;
		top: 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.nav {
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
			margin-bottom: 10px;

			.methods {
				border: 1px solid #cfcfcf;
				border-radius: 4px;
				.method-item {
					color: #bcc0c1;
					cursor: pointer;
					font-weight: 600;
					padding: 5px 15px 5px 15px;
				}
				.method-item:first-child {
					border-bottom-left-radius: 4px;
    				border-top-left-radius: 4px;
				}
				.method-item:first-child {
					border-bottom-left-radius: 4px;
    				border-top-left-radius: 4px;
				}
				.method-item:last-child {
					border-bottom-right-radius: 4px;
    				border-top-right-radius: 4px;
				}
				.active {
					color: $black;
					color: $white;
					background-color: $black;
				}
			}

			.nav-item {
				max-width: 45px;
				width: 45px;
				font-size: 10px;
				padding: 5px;
				color: #fff;
				background: #131c33;
				border-radius: 8px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				margin-right: 15px;

				.icon {
					font-size: 20px;
				}

				img {
					width: 20px;
				}
			}
		}

		.table-label {
			font-size: 19px;
			font-weight: 600;
		}

		.form-overview {
			margin-bottom: 15px;

			.form-item {
				.form-select {
					font-size: 13px;
				}
			}
		}

		.items {
			padding-bottom: 5px;
			border-bottom: 1px solid #d0caca;
			max-height: calc(100vh - 400px);
			overflow: auto;

			.item-container {
				display: flex;
				flex-direction: column;
				padding-bottom: 15px;

				.item {
					display: flex;
					align-items: center;

					.name {
						font-size: 13px;
						font-weight: 300;
						color: black;
						width: 45%;
					}

					.quantity {
						display: flex;
						height: 50px;
						width: 30%;

						.operator {
							border: 1.5px solid #707070;
							font-family: "Montserrat";
							font-weight: 400;
							font-weight: 700;
							cursor: pointer;
							color: $dark;
							-webkit-user-select: none; /* Safari */
							-ms-user-select: none; /* IE 10 and IE 11 */
							user-select: none; /* Standard syntax */

							// &::selection {
							// 	background-color: transparent;
							// }

							// &:hover {
							// 	background-color: $grey;
							// }

							&:first-child {
								width: 33.33%;
								border-right: unset;
								font-size: 25px;
								padding: 4px 17px;
								color: #bcaeae;
							}

							&:nth-child(2) {
								width: 25%;
								border-right: unset;
								border-left: unset;
								padding: 10px 8px;
								font-size: 17px;
								color: #028ab8;
							}

							&:last-child {
								width: 33.33%;
								font-size: 25px;
								padding: 5px 15px;
								border-left: unset;
								color: #028ab8;
							}
						}
					}

					.total-price {
						width: 25%;
						font-size: 13px;
					}

					.text-options {
						font-size: 11px;
						color: gray;
						font-weight: 400;
						font-style: italic;
						cursor: pointer;
					}
				}
			}

		}

		.empty-container {
			height: 100px;
			margin-bottom: 20px;

			img {
				width: 100px;
			}

			.empty-title {
				text-align: left;
				color: #ccc;
				font-size: 11px;
			}
		}

		.total {
			display: flex;
			justify-content: space-between;
			color: #3d4152;
			font-size: 13px;
			font-weight: 600;
			margin-top: 10px;
		}

		.form-input {
			display: block;
			width: 100%;
			padding: 8px 16px;
			outline: none;
			border: 1px solid #cfcfcf;
			font-weight: 400;
			font-size: 13px;
			border-radius: 4px;
			margin-bottom: 10px;
		}

		.bill-action {
			display: flex;
			justify-content: space-between;



			.btn {
				width: 47%;
				padding: 8px 32px;
				font-family: "Montserrat";
				font-weight: 500;
				font-size: 17px;
				color: #fff;
				letter-spacing: 0;

				&:disabled {
					background-color: gray;
				}
			}

			.btn-cancel {
				background-color: #f21c1c;
			}

			.btn-back {
				background-color: gray;
			}

			.btn-proceed {
				background-color: #131c33;
				margin-right: 0;
			}
		}

		.block-print {
			height: 0;
			overflow: hidden;
			position: relative;
			font-family: "Gill Sans", "Gill Sans MT", Helvetica;
			#print-document {
				position: absolute;
				visibility: hidden;
			}
		}
	}

	.menu-breadcrum-sticky {
		top: 0 !important;
	}

	.category-list {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		flex: 1;
		overflow: hidden;
		width: 100%;

		.horizontal-menu {
			width: 100%;
		}

		.menu-item {
			@include breakpoint(992) {
				background-color: #b1b1c08f;
				color: white;
				margin: 5px 5px 0 0;
			}

			border-radius: 12px;
			padding: 0 8px;
			margin: 5px 0;
			user-select: none;
			font-family: "Montserrat";
			font-weight: 400;
			cursor: pointer;
		}

		.menu-item-wrapper.active {
			.menu-item {
				background-color: rgba($color: $heading, $alpha: 0.8);
				color: $white;
				font-weight: 700;
				padding: 2px 8px;
			}
		}

		.scroll-menu-arrow {
			padding: 0 5px;
			cursor: pointer;
		}

		&:after {
			content: "";
			flex: 1 auto;
		}

		.category-link {
			margin: 5px;
			font-family: "Montserrat";
			font-weight: 400;
			font-size: 13px;
			color: $dark;
			letter-spacing: 0;
			cursor: pointer;

			&.category-active {
				font-weight: bold;
			}
		}
	}

	.adds-block {
		.adds-item {
			.adds-content {
				.adds-norm {
					height: 50px;
				}
			}

			.adds-images {
				height: 150px;
			}
			.btn-order {
				width: 40px;
    			height: 40px;
				font-size: 20px;
			}
		}
	}

	.order-container {
		.change-status {
			.btn-confirm {
				padding: 15px;
				font-size: 15px;
			}
			.orange {
				padding: 16px;
				border-radius: 8px;
			}
		}
	}

	.arrow-prev {
		align-items: center;
		justify-content: space-between;
		background: #131c33;
		cursor: pointer;
		display: flex;
		margin-top: 20px;
		color: $white;
		padding: 5px 5px 5px 10px;
    	border-radius: 8px;
		img {
			width: 25px;
		}
	}

	.pos-categories {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-top: 10px;
		&__item {
			border-radius: 10px;
			background: #bcc0c1;
			cursor: pointer;
			padding: 20px;
			font-size: 15px;
			font-weight: bold;
			flex-basis: 31%;
			margin-right: 10px;
			text-align: center;
			margin-bottom: 10px;
		}
	}
	.search-field {
		position: relative;
		img {
			position: absolute;
			left: 15px;
			top: 8px;
			width: 20px;
		}
		.search-box {
			background-color: #fff;
			border-radius: 20px;
			padding: 8px 40px;
			outline: none;
			border: none;
			font-family: Montserrat;
			font-weight: 300;
			font-size: 14px;
			letter-spacing: 0;
			color: #000;
			width: 100%;
		}
	}
	.tables {
		background-color: #fff;
		border-radius: 8px;
		padding: 1.25rem;
		overflow-y: auto;
		max-height: 500px;
		min-height: 200px;

		&::-webkit-scrollbar {
			width: 8px;
			background-color: transparent;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			box-shadow: inset 0 0 5px unset;
			border-radius: 10px;
			height: 80px;
			background-color: transparent;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			opacity: 0.4;
			background-color: $trading-bg;
			border-radius: 3.5px;
			height: 6px;
		}


		.table-item {
			cursor: pointer;
			height: 85px;
			border: 1px solid #ccc;
			border-radius: 10px;
			padding: 0;
			margin-top: 10px;

			.total-new-orders {
				color: #000;
				text-align: center;
				font-size: 16px;
				font-weight: bold;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 40%;
			}

			.table-body {
				background: #ccc;
				height: 61%;
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 10px;
				padding-top: 2px;
				font-weight: 700;
				color: #000;
				display: flex;
				white-space: nowrap;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				overflow: hidden;
				.table-label {
					font-size: 10px;
				}

				.table-name {
					font-size: 18px;
				}
			}
		}
	}
}
.pos-payment-confirm {
	padding: 20px;
    background-color: #fff;
    width: 450px;
	border-radius: 8px;
	.img-cash {
		width: 35px;
		object-fit: contain;
	}
	.img-credit {
		width: 40px;
		object-fit: contain;
	}
	.btn-claim {
		padding: 10px 15px;
		font-size: 12px;
		font-weight: bold;
		margin: 0 auto;
	}
}