.learn-container {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  z-index: -1;
  min-height: calc(100vh - 80px);
  .learn-more {
    position: relative;
    background-image: url("/assets/images/learn-more/bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: calc(100% - 40%);
    .login-more-img {
      position: absolute;
      width: 250px;
      left: 50%;
      top: 80px;
      transform: translate(-50%, 0);
    }
  }
  .wrap-learn {
    text-align: left;
    width: 40%;
    .learn-title {
      font-family: "Montserrat";
      font-weight: 600;
      font-size: 36px;
      color: $dark;
      letter-spacing: 0;
      padding: 10px 20px;
      line-height: 36px;
    }
    .learn-norm {
      text-align: left;
      font-family: "Montserrat";
font-weight: 400;
      font-size: 16px;
      color: $dark;
      letter-spacing: 0;
      padding: 5px 20px;
      line-height: 24px;
    }
    .learn-button {
      padding: 5px 20px;
      .btn-form {
        color: $white;
        background-color: $primary;
      }
    }
  }
}
