.policy-container {
  .policy-title {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 36px;
    color: $dark;
    letter-spacing: 0;
    text-align: center;
    line-height: 42px;
  }
  h3 {
    line-height: 36px;
    font-family: "Montserrat";
    font-size: 24px;
    margin: 20px 0;
  }
  h4 {
    line-height: 24px;
    font-family: "Montserrat";
    font-size: 18px;
  }
  p {
    line-height: 24px;
    font-family: "Montserrat";
    font-size: 16px;
  }
  h5 {
    line-height: 24px;
    font-family: "Montserrat";
    font-size: 14px;
  }
  a {
    color: $primary;
  }
  .image-testimonials {
    width: 100%;
    height: 200px;
    object-fit: contain;
  }
  .text-norm {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 14px;
    font-style: italic;
    color: $text-online-norm;
    padding: 10px 0;
    text-align: center;
  }
}
