.about-container {
  .about-more {
    height: 400px;
    background-image: url("/assets/images/about/banner.png");
    background-size: cover;
  }
  .explore-content {
    background-color: $white;
    box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    margin-top: -75px;
    padding: 60px 50px;
    .explore-title {
      font-family: "Montserrat";
      font-weight: 700;
      font-size: 34px;
      line-height: 46px;
      color: $dark;
      margin-bottom: 20px;
    }
    .explore-norm {
      font-family: "Montserrat";
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      color: $dark;
      margin-bottom: 20px;
    }
  }
  .business-container {
    width: 100%;
    position: relative;
    .business-our {
      &.business-our-comnunity {
        .business-img {
          margin-right: -70px;
          margin-left: 0px;
          margin-top: -30px;
        }
      }
      .business-content {
        height: 400px;
      }
      .business-img {
        position: relative;
        z-index: 999;
        margin-right: 0;
        margin-left: -70px;
        margin-top: -30px;
        box-shadow: 0 10px 10px -2px rgba(0, 0, 0, 0.3);
        .img {
          width: 100%;
        }
      }
    }
    .business-content {
      background-color: rgba($color: $primary, $alpha: 0.1);
      display: flex;
      flex-direction: column;
      padding: 60px;
      justify-content: center;
      text-align: left;
      height: 600px;
      .business-title {
        position: relative;
        padding-left: 100px;
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 26px;
        line-height: 40px;
        color: $dark;
        &:after {
          content: "";
          position: absolute;
          left: 0;
          top: 20px;
          width: 100%;
          height: 1.5px;
          background-color: $primary;
          width: 90px;
        }
      }
      .business-title-main {
        position: relative;
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 26px;
        line-height: 40px;
        color: $dark;
      }
      .business-norm {
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        color: $dark;
        margin-bottom: 20px;
      }
    }
    .business-img {
      position: relative;
      z-index: 999;
      margin-right: -70px;
      box-shadow: 0 10px 10px -2px rgba(0, 0, 0, 0.3);
      .img {
        width: 100%;
      }
    }
  }
  .choose-container {
    height: 420px;
    background-image: url("/assets/images/about/banner-choose.png");
    background-size: cover;
    background-repeat: no-repeat;
    .choose-content {
      padding: 10px;
      .choose-title {
        position: relative;
        font-family: "Montserrat";
        font-weight: 700;
        font-size: 34px;
        line-height: 50px;
        color: $white;
        margin-bottom: 20px;
        &::after {
          content: "";
          position: absolute;
          left: 50%;
          top: 100%;
          height: 3px;
          width: 100px;
          background-color: $primary;
          transform: translateX(-50%);
          border-radius: 4px;
        }
      }
      .choose-norm {
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: $white;
        margin-bottom: 20px;
      }
    }
  }
  .customer-container {
    padding: 100px 50px;
    background-color: rgba($color: $primary, $alpha: 0.1);
    .slick-arrow {
      height: 50px;
      width: 50px;
      border-radius: 100%;
      background-color: $white;
      padding: 10px;
      z-index: 9999;
      box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.3);
      &.slick-prev {
        left: -25px;
        &::before {
          content: url("/assets/images/about/prev.png");
        }
      }
      &.slick-next {
        right: -25px;
        &::before {
          content: url("/assets/images/about/next.png");
        }
      }
    }
    .slider-item {
      display: flex;
      flex-wrap: nowrap;
      box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.3);
      .customer-img {
        width: 50%;
        .img {
          width: 100%;
          height: 500px;
          object-fit: cover;
        }
      }
      .customer-content {
        background-image: url("/assets/images/about/questions.png");
        background-repeat: no-repeat;
        background-position: center center;
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: $white;
        justify-content: center;
        padding: 50px;
        width: 50%;
        .customer-title {
          position: relative;
          font-family: "Montserrat";
          font-weight: 700;
          font-size: 34px;
          line-height: 50px;
          color: $dark;
          margin-bottom: 20px;
        }
        .customer-norm {
          font-family: "Montserrat";
          font-weight: 400;
          font-size: 18px;
          line-height: 27px;
          color: $dark;
          margin-bottom: 20px;
        }
        .customer-name {
          font-family: "Montserrat";
          font-weight: 400;
          font-size: 18px;
          line-height: 27px;
          color: $dark;
          margin-bottom: 20px;
        }
      }
    }
  }
  .stay-container {
    padding: 20px;
    .stay-title {
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 25px;
      line-height: 46px;
      color: $dark;
      padding-bottom: 0px;
    }
  }
}

.slideshow-container {
  position: relative;
  height: 100vh;
  .slick-slide {
    transition: opacity 2s ease-in-out !important;
  }
  .slick-track {
    height: 100vh;
  }
  .banner-img {
    width: 100%;
    height: 100%;
  }
  .banner-item {
    object-fit: cover;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    &.banner-item-vertical {
      height: 100vw;
      transform: rotate(-90deg) !important;
      transform: rotate(-90deg) translateY(0%) translateX(-100%) !important;
      transform-origin: top left !important;
      height: 100vw;
      width: 100vh;
    }
  }
  &.banner-category {
    .banner-image {
      max-height: 260px;
      min-height: 260px;
    }
  }
  .btn-store {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translate(-50%, 0);
    background: $yellow;
    border: 1px solid $yellow;
    &:hover {
      background-color: $hover-button-banner;
    }
  }
  .slick-dots {
    position: absolute;
    bottom: 10px;
    li {
      &.slick-active {
        button {
          &::before {
            color: $dot-black;
          }
        }
      }
      button {
        &::before {
          font-size: 9px;
          color: $dot-white;
          opacity: 1;
        }
      }
    }
  }
  .slick-dotted {
    margin-bottom: 0;
    li {
      &.slick-active {
        button {
          &::before {
            color: $dot-black;
          }
        }
      }
      button {
        &::before {
          font-size: 9px;
          color: $dot-white;
          opacity: 1;
        }
      }
    }
  }
  .banner-image {
    width: 100%;
    min-height: auto;
  }
}