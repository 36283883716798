.popup-qrcode {
  .styles_closeButton__20ID4 {
    top: 19px;
    right: 19px;
  }
}
.profile-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 400px;
  overflow: hidden;
  img {
    opacity: 0;
    visibility: hidden;
  }
}
.overview-container {
  .userImg {
    float: left;
  }
  .info {
    display: block;
    @include rem('font-size', 14);
    padding-left: 10px;
    overflow: hidden;
    span {
      display: block;
    }
    .email {
      @include rem('font-size', 13);
    }
  }
  .products-grids {
    background-color: #fff;
    position: relative;
    min-height: 150px;
    .icon-clock::before {
      text-align: left;
      margin: 0 8px 0 0;
    }
  }
  .sharemystore {
    padding: 5px 10px;
    text-transform: uppercase;
  }
}

.business-info {
  &.dl-horizontal dt {
    margin-top: 0;
  }
  p {
    padding: 0;
    margin: 0;
  }
  span {
    // display: inline-block;
    // padding-top: 5px;
    min-height: 25px;
  }
}

.slideshow-container {
  .slick-prev {
    left: 10px;
    z-index: 10;
  }
  .slick-next {
    right: 10px;
    z-index: 10;
  }
  .slick-dots {
    bottom: 30px;
  }
  .slick-dots li {
    width: 15px;
    margin: 0 3px;
  }
  .slick-dots li button {
    padding: 5px 0;
    width: 15px;
  }
  .slick-dots li button:before {
    color: #fff;
    opacity: .7;
  }
  .slick-dots li.slick-active button:before {
    opacity: 1;
  }
}
.slideshow-container .slide-item {
  background-color: #fff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.slideshow-container .slide-content {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
}
.slideshow-container .slide-content .slide-title {
  color: #fff;
  font-size: 15px;
  padding-bottom: 5px;
}
.slideshow-container .slide-content {
  color: #fff;
  font-size: 13px;
}
.block-no-content {
  padding-top: 30px;
  text-align: center;
}

.slideshow-container {
  font-family: "Comforta";

  .slide-caption {
    background-color: rgba(51, 50, 50, 0.2);
    position: absolute;
    top: 0;
    left: 0;

    color: #fff;
    font-size: 1.7em;
    font-family: "Comforta";
    padding: 20px;
  }

  .slide-navbar {
    background-color: rgba(0, 0, 0, 0);
    position: absolute;
    bottom: 40px;
    width: 100%;

    .nav-container {
      display: inline;
      background-color: rgba(51, 50, 50, 0.3);
      border: 1px solid #6b6868;
      color: #fff;
      font-size: 1.3em;
      padding: 10px 30px 10px 30px;
    }
  }
}
.settings-container {
  .text-note {
    color: #555;
    display: block;
    font-size: 12px;
    padding-top: 3px;
  }
  .slider-content-main {
    img {
      margin-top: 10px;
      height: 200px !important;
      width: auto !important;
      max-width: 300px;
      object-fit: contain;
    }
  }
}
