.booking-conainer {
  .booking-content {
    background-color: $white;
    border-radius: 4px;
    padding: 20px;
  }
  .booking-title {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 18px;
    color: $dark;
    padding: 10px 0;
  }
  .booking-form {
    .form-item {
      display: block;
      margin: 5px 0;
      .form-label {
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 14px;
        color: $dark;
        font-weight: 700;
      }
      .form-datepicker {
        width: 100%;
      }
      .form-input {
        display: block;
        width: 100%;
        padding: 8px 16px;
        outline: none;
        border: 1px solid #cfcfcf;
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 15px;
        border-radius: 4px;
      }
    }
  }
  .btn-process {
    background-color: transparent;
    color: $dark;
    font-family: "Montserrat";
    font-weight: 500;
    border: 1px solid $dark;
    border-radius: 26px;
    padding: 6px 16px;
    &.btn-continue {
      background-color: $heading;
      border: 1px solid $heading;
      color: $white;
      border-radius: 26px;
      padding: 6px 36px;
    }
    .icon {
      width: 16px;
      margin-right: 5px;
      margin-top: -5px;
    }
  }
}
