@media (min-width: 1200px) {
    .collections {
        .adds-images {
            height: 300px !important;
        }
    }
}

.list-comment {
    .comment-item {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 5px;
      .comment-avatar {
        padding-left: 0;
        .comment-img {
          border-radius: 100%;
          width: 50px;
          height: 50px;
          object-fit: cover;
        }
      }
      .comment-content {
        border-radius: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
        background-color: #fff;
        .comment-name {
          padding: 0;
          font-size: 15px;
          line-height: unset;
          font-weight: bold !important;
        }
        .comment-norm {
          white-space: pre-line;
          word-break: break-all;
          font-size: 12px;
          margin: 0;
        }
    }
  }
}

.collection-list {
    .product-container {
        margin-top: 10px;
    }
    
    .collection-name {
        font-size: 13px;
        font-weight: bold;
        margin-top: 5px;
    }

    .collection-action {
        margin: 10px 0;
    }

    .collection-time {
        font-size: 11px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .adds-images,
    .adds-item-video {
        cursor: pointer;
        overflow: hidden;
    }

    .adds-norm {
        padding: 0;
    }

    .adds-item {
        border-bottom: 1px solid lightgray;
        margin-bottom: 10px !important;
    }

    .selection {
        width: 100%;
    }

    .menu-item-active {
        background-color: rgba(19, 28, 51, 0.8);
        color: #fff;
        border-radius: 12px;
        font-weight: 700;
        padding: 2px 8px;
    }

    .menu-item {
        padding: 0 8px;
        margin: 5px 0px;
        -webkit-user-select: none;
        user-select: none;
        font-family: "Roboto";
        font-weight: 400;
        cursor: pointer;
    }

    .scroll-menu-arrow {
        cursor: pointer;
    }
}