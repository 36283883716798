.cart-container {
  .view-options {
    margin-top: 4px;
    span {
      color: #028ab8;
      font-size: 12px;
      font-style: italic;
      cursor: pointer;
    }
    p {
      color: #028ab8;
      font-size: 12px;
      font-style: italic;
      cursor: pointer;
    }
  }
  .btn-continue {
    width: 100%;
    background-color: rgba(19, 28, 51, 0.8);
    font-family: "Montserrat";
    font-weight: 600;
    border-radius: 26px;
    font-size: 15px;
    padding: 8px 16px;
    .icon {
      margin-right: 10px;
      width: 20px;
    }
  }
  .text-description {
    font-family: "Montserrat Condensed";
    color: #a9a9a9;
    font-size: 12px;
  }
  .process-checkout {
    // background-color: $white;
    // padding: 20px 10px 10px;
    border-radius: 4px;
    .text-total {
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 21px;
      color: $dark;
      &.text-price {
        color: $danger;
      }
    }
    .text-norm {
      font-family: "Montserrat";
      font-weight: 500;
      white-space: nowrap;
      font-size: 15px;
      color: $dark;
    }
    .block-process {
      max-width: 500px;
      margin: 0 auto;
      .btn-process {
        font-size: 14px;
        background-color: #f6cf37;
        color: $dark;
        font-family: "Montserrat";
        font-weight: 500;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        .icon {
          width: 11px;
          margin-right: 10px;
          margin-bottom: 2px;
        }
      }
    }
  }
  .cart-content {
    display: flex;
    justify-content: start;
    align-items: center;
    .icon {
      margin-right: 20px;
    }
    .total-name {
      font-family: "Montserrat";
      font-weight: 400;
      font-size: 15px;
      margin-bottom: 0;
    }
  }
  .float_checkout {
    margin-bottom: 20px;
    position: -webkit-sticky;
    position: sticky;
    top: 60px;
    contain: layout;
  }
}

.cart-table {
  overflow-x: auto;
  &.cart-table-responsive {
    display: none;
    .cart-item {
      display: block;
      padding: 20px;
      background-color: $white;
      &:not(:first-child) {
        border-top: 1px solid rgba($color: $border-bussiness, $alpha: 0.35);
      }
      .cart-image {
        display: flex;
        .img-table {
          width: 150px;
        }
        .cart-norm {
          padding-left: 18px;
          font-family: "Montserrat";
          font-weight: 400;
          font-weight: 700;
          color: rgba($color: $dark, $alpha: 0.65);
          &.cart-norm-left {
            padding-left: 0;
          }
        }
        .price {
          padding-left: 18px;
        }
      }
      .total-quanlity {
        .quanlity-norm {
          font-family: "Montserrat";
          font-weight: 400;
          font-size: 14px;
          color: $dark;
        }
        .quanlity-number {
          font-family: "Montserrat";
          font-weight: 400;
          font-weight: 700;
          font-size: 14px;
          color: $dark;
        }
      }
      .cart-quanlity {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .quanlity {
          display: flex;
          .operator {
            border: 1.5px solid #707070;
            font-family: "Montserrat";
            font-weight: 400;
            font-weight: 700;
            padding: 5px 16px;
            cursor: pointer;
            color: $dark;
            &::selection {
              background-color: transparent;
            }
            &:hover {
              background-color: $grey;
            }
            &:first-child {
              border-right: unset;
            }
            &:nth-child(2) {
              border-right: unset;
              border-left: unset;
              color: #028ab8;
            }
            &:last-child {
              border-left: unset;
              color: #028ab8;
            }
          }
        }
        .total {
          .price {
            display: block;
            font-family: "Montserrat";
            font-weight: 400;
            font-size: 18px;
            font-weight: 600;
            &.total-price {
              color: $danger;
            }
          }
        }
      }
      .group-button {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: space-between;
        .btn-table {
          width: 49%;
          display: inline-block;
          margin: 0 5px;
          background-color: transparent;
          border-radius: 26px;
          padding: 10px 16px;
          border: 2px solid #707070;
          outline: none;
          &.btn-pickup {
            background-color: rgba(19, 28, 51, 0.8);
            border: unset;
            color: #fff;
          }
        }
      }
    }
  }
  &.cart-service {
    overflow: unset;
  }
  .cart-title {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: $dark;
    text-transform: uppercase;
    padding-bottom: 0;
  }
  table {
    background-color: $white;
    width: 100%;
    border-radius: 4px;
    padding: 10px;
    overflow-y: scroll;
    thead {
      border-bottom: 3px solid #edeff0;
      th {
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 13px;
        padding: 10px;
        color: $dark;
      }
    }
    tbody {
      td {
        padding: 10px;
        font-family: "Montserrat";
        font-weight: 400;
        font-weight: 700;
        color: $dark;
        .img-table {
          width: 130px;
        }
        &.col-info {
          display: flex;
          flex-direction: column;
          .price {
            margin: 10px 0;
            font-size: 12px;
          }
          .table-name {
            color: $dark;
          }
        }
        &.col-name {
          max-width: 300px;
          .table-name {
            color: $dark;
          }
        }
        &.col-quanlity {
          min-width: 150px;
          .table-quantily {
            display: flex;
            .operator {
              border: 1.5px solid #707070;
              padding: 2px 11px;
              cursor: pointer;
              color: $dark;
              &::selection {
                background-color: transparent;
              }
              &:hover {
                background-color: $grey;
              }
              &:first-child {
                border-right: unset;
                color: #bcaeae;
              }
              &:nth-child(2) {
                border-right: unset;
                border-left: unset;
                color: #028ab8;
              }
              &:last-child {
                border-left: unset;
                color: #028ab8;
              }
            }
          }
        }
        &.col-button {
          width: 230px;
          .btn-table {
            display: inline-block;
            margin: 0 5px;
            background-color: transparent;
            border-radius: 26px;
            padding: 10px 16px;
            border: 2px solid #707070;
            outline: none;
            color: $dark;
            &.btn-pickup {
              background-color: rgba(19, 28, 51, 0.8);
              border: unset;
              color: $white;
              &:hover {
                background-color: rgba(19, 28, 51, 0.8);
              }
            }
            // &:hover {
            //   background-color: $grey;
            // }
          }
          .cart_trash {
            border-radius: 50%;
            box-shadow: 0 0 2px #000;
            cursor: pointer;
            padding: 10px 12px;
          }
        }
        .price {
          font-family: "Montserrat";
          font-weight: 400;
          font-weight: 400;
          &.total-price {
            font-weight: bold;
            white-space: nowrap;
            color: $danger;
          }
        }
        .table-quantily {
          display: flex;
          .operator {
            border: 1.5px solid #707070;
            padding: 2px 11px;
            cursor: pointer;
            color: $dark;
            &::selection {
              background-color: transparent;
            }
            &:hover {
              background-color: $grey;
            }
            &:first-child {
              border-right: unset;
              color: #bcaeae;
            }
            &:nth-child(2) {
              border-right: unset;
              border-left: unset;
              color: #028ab8;
            }
            &:last-child {
              border-left: unset;
              color: #028ab8;
            }
          }
        }
      }
    }
  }
}

.map-delivery {
  background-color: $white;
  .map-container {
    width: 100%;
    height: 500px;
  }
}

.form-delivery {
  position: relative;
  padding: 0 50px;
  margin-top: -70px;
  .driver-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2px;
    .item {
      background-color: rgba($color: $dark, $alpha: 0.85);
      padding: 10px 30px 5px 30px;
      border-radius: 10px 10px 0px 0px;
      z-index: 999;
      &.item-parcels {
        background-color: #f6cf37;
        .title {
          color: rgba($color: $dark, $alpha: 0.85);
        }
        .norm {
          color: rgba($color: $dark, $alpha: 0.85);
        }
      }
      .title {
        color: #f6cf37;
        font-size: 20px;
        font-family: "Montserrat";
        font-weight: 600;
      }
      .description {
        display: flex;
        justify-content: space-between;
      }
      .norm {
        font-size: 13px;
        font-family: "Montserrat";
        font-weight: 500;
        color: #f6cf37;
        margin-bottom: 0px;
      }
    }
  }
  .heading {
    position: relative;
    text-align: center;
    background-color: rgba($color: $dark, $alpha: 0.85);
    .title {
      font-size: 18px;
      font-family: "Montserrat";
      font-weight: 600;
      padding: 16px;
      color: #f6cf37;
      margin-bottom: 0;
    }
  }
}
.delivery {
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  border: 1px solid $yellow;
  border-radius: 0px 0px 8px 8px;
  .item {
    width: 25%;
    .estimate {
      position: relative;
      display: flex;
      align-items: center;
      padding: 10px;
      .norm {
        margin-bottom: 0;
      }
      .button-estimate {
        position: absolute;
        border: none;
        outline: none;
        right: 0;
        width: 30px;
        top: 0;
        height: 100%;
        background-color: #f6cf37;
        border-radius: 0 0px 8px 0;
        .icon {
          color: $white;
          position: absolute;
          left: 50%;
          transform: translate(-50%, -50%);
          top: 50%;
          padding-right: 0;
          height: auto;
        }
      }
      .icon {
        padding-right: 20px;
      }
    }
    .form-input {
      padding: 9px;
      input {
        border: none;
        outline: none;
      }
    }
    .category-select {
      padding: 9px;
      border: none;
      outline: none;
    }
  }
}

.tabs-container {
  display: flex;
  margin-bottom: 20px;
  .tabs-item {
    white-space: nowrap;
    position: relative;
    display: flex;
    align-items: center;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: #bcc0c1;
    padding-bottom: 0;
    margin: 0 20px;
    cursor: pointer;
    &.active {
      color: #000000;
      &::after {
        width: 100%;
        transition: 0.3s ease-in-out;
      }
    }
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 0%;
      height: 2px;
      background-color: #000000;
      border-radius: 100px;
      transition: .3s ease-in-out;
    }
    &:first-child {
      margin-left: 0;
    }
    span[class*="ic-common"] {
      padding-left: 10px;
    }
  }
  .number_items {
    position: absolute;
    right: -10px;
    top: -8px;
    width: 14px;
    height: 14px;
    display: flex;
    border-radius: 100%;
    background-color: #e22121;
    color: #fff;
    font-size: 8px;
    font-family: "Montserrat";
    font-weight: 600;
    justify-content: center;
    align-items: center;
  }
}

.best_matched {
  .heading-container {
    .heading-norm {
      font-size: 16px;
    }
  }
  .adds-block {
    .adds-item {
      .adds-images {
        height: 120px;
      }
    }
  }
}
