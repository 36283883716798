
.dl-horizontal {
    dt {
        clear: left;
        color: #777;
        float: left;
        font-weight: normal;
        overflow: hidden;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        /*width: 170px;*/
        word-wrap: normal;
        margin-top: 5px;
        min-height: 25px;
        line-height: 25px;
    }
    dd {
        margin-top: 5px;
        min-height: 25px;
        line-height: 25px;
    }
}