.notice-container {
  position: relative;
  background-image: url("/assets/images/bg-notice.png");
  background-size: 100% 100%;
  height: 100vh;
  background-repeat: no-repeat;
  .notice-content {
    position: absolute;
    left: 23%;
    top: 50%;
    transform: translateY(-50%);
    text-align: left;
    .notice-title {
      color: #fff;
      font-size: 52px;
      padding: 30px 0;
    }
    .notice-norm {
      color: #e6e6e6;
      font-size: 26px;
      line-height: 26px;
    }
  }
  .notice-logo {
    position: absolute;
    right: 30%;
    top: 49%;
    transform: translateY(-50%);
    text-align: left;
    max-width: 200px;
  }
}
