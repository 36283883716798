.product-list-container {
  .product-list {
    background-color: #fff;
    min-height: 200px;
    position: relative;
    @include breakpoint(992) {
      background-color: transparent;
      margin-top: 10px;
    }
  }
  .list-filter ul li a {
    line-height: 1.5;
    padding: 6px 15px 6px 5px;
  }
  .list-filter ul li a.active {
    color: #000;
    font-weight: 700;
  }
  .item-list .make-favorite,
  .item-list:hover .make-favorite span {
    border: none;
    font-size: 11px;
    margin: 0;
    padding: 5px 9px;
  }
  .tab-sort {
    min-width: 220px;
    position: relative;
    z-index: 10;
    .text-label {
      color: #222;
      padding-top: 7px;
    }
    .Select {
      float: right;
      .Select-control {
        box-shadow: none;
        outline: none;
      }
      .Select-value-label {
        font-size: 13px;
      }
    }
    .Select-menu-outer {
      .Select-option {
        font-size: 13px;
      }
    }
  }
  .radio {
    label {
      @include rem("font-size", 13);
    }
  }
  // .item-list:hover .make-favorite span {
  //   margin-left: 5px;
  // }
}
.product-detail-container {
  .list-title {
    font-size: 22px;
  }
  button.make-favorite {
    display: block;
    width: 100%;
    .text {
      padding-left: 15px;
    }
  }
  .image-slider {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .product-slider {
    .img-responsive {
      opacity: 0;
      visibility: hidden;
    }
  }
  .product-slider-content {
    .image-slider {
      min-height: 350px;
      max-height: 350px;
    }
  }
  .product-nav-content {
    .image-slider {
      width: 100px;
      min-height: 100px;
    }
  }
  .slick-dots {
    position: static;
    padding-top: 20px;
  }
  .slick-dots li {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    width: 70px;
    height: 70px;
    overflow: hidden;
    .image-dot {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      border-bottom: 3px solid transparent;
      width: 70px;
      height: 68px;
      overflow: hidden;
      img {
        opacity: 0;
        visibility: hidden;
      }
    }
    &.slick-active {
      .image-dot {
        border-bottom-color: $red;
      }
    }
  }
  .product-price {
    font-size: 20px;
    line-height: 1.2;
    .text-red.text-underline {
      font-size: 17px;
      color: #d9534f;
      text-decoration: line-through;
    }
  }
  .seller-info {
    .avatar {
      box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
      position: relative;
      .background-image {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        display: block;
        height: 200px;
        overflow: hidden;
        img {
          opacity: 0;
          visibility: hidden;
        }
      }
      .qrcode {
        background-color: #fff;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 2px;
        width: 30px;
        height: 30px;
        font-size: 18px;
      }
    }
    .info {
      span {
        font-size: 13px;
      }
    }
  }
}
.review-container {
  .review-form-content {
    border-top: 1px solid #ddd;
  }
}

.service-container {
  .trading-hour-item .rdtPicker {
    width: 143px;
  }
  .trading-hour-item .rdtCounter {
    height: 75px;
  }
  .trading-hour-item .rdtCounterSeparator {
    line-height: 85px;
  }
  .trading-hour-item label {
    text-transform: capitalize;
  }
  .form-group {
    position: relative;
    .form-control:disabled {
      background-color: #fff;
    }
  }
  .time-slot-item {
    padding: 7px 0;
  }
  .time-slot-item .checkbox {
    margin: 3px 0 0 0;
  }
}

.time-slot-content {
  height: 400px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px unset;
    border-radius: 10px;
    height: 80px;
    background-color: transparent;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    opacity: 0.4;
    background-color: $trading-bg;
    border-radius: 3.5px;
    height: 6px;
  }
}

.booking-container {
  .list-title {
    font-size: 22px;
  }
  .checkbox-time {
    padding-top: 2px;
  }
  .text-red.text-underline {
    font-size: 15px;
    color: #d9534f;
    text-decoration: line-through;
  }
  .f-two-column {
    column-count: 2;
    @include breakpoint($sm-only) {
      column-count: 1;
    }
  }
}

.booking-success {
  padding: 50px 0;
  .success {
    font-size: 40px;
  }
}

.product-detail-quantity {
  width: 100px;
  display: flex;
  border: 1px solid black;
  padding: 2px 0px;
  margin-bottom: 8px;
  .operator {
    color: #028ab8;
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 1;
    font-size: 16px;
    font-weight: bold;
    
  }
  .pointer {
    cursor: pointer;
  }
}

.product-detail-content,
.booking-page-content {
  min-height: 250px;
  position: relative;
}
.page-not-found-content {
  min-height: 300px;
  padding: 50px 15px;
  .main-title {
    @include rem("font-size", 200);
    font-weight: 700;
    line-height: 1;
    @include breakpoint(max-width 767px) {
      @include rem("font-size", 120);
      font-weight: 400;
    }
  }
  .main-description {
    @include rem("font-size", 26);
    padding-bottom: 20px;
    @include breakpoint(max-width 767px) {
      @include rem("font-size", 16);
    }
  }
}

.review-container {
  .ui.comments .comment .text {
    font-size: 0.8em;
    font-weight: 400;
  }
}
.innerbanner {
  height: 350px;
}
.border-promotions {
  border: 1px solid #d3d3d3;
}
.promotions-center {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0 auto;
  margin-top: -30px;
}
.banner-promotions {
  max-height: 450px;
}
.showmore {
  width: 100%;
  align-items: center;
  text-align: center;
  a {
    display: inline-block;
  }
}

.titlepromotions {
  color: #222222;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: Montserrat Condensed, Helvetica, Arial, sans-serif;
}
.popup-confirm-container {
  width: 300px;
  background-color: #ffffff;
  padding: 30px 20px 10px 20px;
  border-radius: 10px;
  .form {
    display: flex;
    justify-content: space-between;
  }
  button {
    background-color: #028ab8;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-align: center;
    font-size: 13px;
    color: #ffffff;
    height: 30px;
    margin: 0 auto;
  }
}
