// BS3 ==> BS4
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}

.float-right,.pull-right {
  float: right;
}

.float-left,.pull-left {
  float: left;
}

.d-inline-b {
  display: inline-block;
}

.btn-block{
  &-sm {
    @include breakpoint($sm-only) {
      display: block !important;
      width: 100%;
    }
  }

  &-md {
    @include breakpoint($md-max) {
      display: block !important;
      width: 100%;
    }
  }

}

.hidden, .hide {
  display: none;
  @include breakpoint($md-max) {
    &-md {
      display: none !important
    }
  }
  @include breakpoint($sm-max) {
    &-sm {
      display: none !important
    }
  }
  @include breakpoint($sm-max) {
    &-xs {
      display: none !important
    }
  }
}


.list-inline {
  padding-left: 0;
  margin-left: -5px;
  list-style: none;
  >  li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
  }
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.label {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
  &-default {
    background-color: #777;
  }
}

.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  &-body {
    padding: 15px;
  }
  &-details {
    border: solid 1px #ddd;
    background: #f8f8f8;
    position: relative;
    font-size: 13px;
    ul li {
      margin-bottom: 3px;
      font-family: $base-font-condensed;
      strong {
        font-weight: bolder;
        text-transform: uppercase;
        margin-right: 3px;
      }
    }
  }
}


@include breakpoint(577px 980px) {
  .container {
    max-width: 100%;
  }
}


// BS Slider
.tooltip-inner {
  font-family: $base-font-condensed !important;
  font-size: 12px !important;
}

// TEMPLATE FIX FOR Bootstrap 4
.cat-pag {
  display: inline-block;
}

/* TERMS & CONDITION */

.container-content {
    display: block;
    clear: both;
}
ul.list-number {
    margin-left: 30px;
    li {
        list-style: unset none decimal;
    }
}
