.contact-container {
  .contact-more {
    height: 300px;
    background-image: url("/assets/images/contact/bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .contact-content {
    padding: 80px 0;
    .contact-title {
      font-family: "Montserrat";
      font-weight: 600;
      font-size: 36px;
      color: $dark;
      letter-spacing: 0;
      line-height: 42px;
    }
    .contact-norm {
      font-family: "Montserrat";
      font-weight: 400;
      font-size: 16px;
      color: rgba($color: $dark, $alpha: 0.6);
      letter-spacing: 0;
      line-height: 26px;
    }
    .contact-profile {
      margin-top: 10px;
      .profile-name {
        display: block;
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 18px;
        padding: 6px 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .icon {
          padding-right: 10px;
        }
      }
    }
  }
  .form-item {
    margin: 16px 0;
    &.form-button {
      text-align: left;
      .btn-form {
        font-family: "Montserrat";
        font-weight: 500;
        background-color: $dark;
        padding: 8px 50px;
        font-size: 13px;
        color: $white;
      }
    }
    .form-label {
      font-family: "Montserrat";
      font-weight: 400;
      font-size: 15px;
      color: $dark;
      letter-spacing: 0;
      font-weight: 700;
      line-height: 26px;
      &.form-label-required {
        position: relative;
        &::after {
          content: "*";
          position: absolute;
          right: -7px;
          top: 0;
        }
      }
    }
    .form-input {
      display: block;
      width: 100%;
      padding: 8px 16px;
      border-radius: 25px;
      outline: none;
      border: 1px solid #cfcfcf;
      font-family: "Montserrat";
      font-weight: 400;
      font-size: 15px;
    }
  }
}
