.dashboard {
	.menu {
		display: flex;
		flex-flow: wrap;
		margin-bottom: 20px;
		justify-content: space-between;
		.item {
			background-color: lightgray;
			border-radius: 8px;
			text-align: center;
			width: 30%;
			position: relative;
			font-family: "Montserrat";
			font-weight: 600;
			font-size: 12px;
			color: $dark;
			letter-spacing: 0;
			line-height: 46px;
			margin-bottom: 10px;
			&:last-child {
				margin-right: 0;
			}
			&.active {
				color: $white;
				background-color: $heading;
			}
			@include breakpoint(576) {
				font-size: 10px;
			}
		}
	}
	.card {
		background-color: rgb(255, 255, 255);
		color: rgb(52, 49, 76);
		transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		overflow: hidden;
		border-radius: 8px;
		display: flex;
		padding: .5rem;
		-webkit-box-align: center;
		align-items: center;
		flex-direction: column;
		-webkit-box-pack: justify;
		justify-content: space-between;
		box-shadow: rgba(0, 129, 255, 0.17);
		min-height: 177px;

		@include breakpoint(576) {
			margin-bottom: 10px;
		}

		.card-icon {
			width: 40px;
			height: 40px;
			font-size: 1.2rem;
			display: flex;
			-webkit-box-align: center;
			align-items: center;
			-webkit-box-pack: center;
			justify-content: center;
			border-radius: 50%;
			margin-right: 10px;
		}

		.blue {
			color: rgb(0, 129, 255);
			background: rgba(0, 129, 255, 0.17);
		}

		.pink {
			color: rgb(255, 61, 87);
			background: rgba(255, 61, 87, 0.17);
		}

		.green {
			color: rgb(9, 182, 109);
			background: rgba(9, 182, 109, 0.17);
		}

		.card-statistic {
			margin-top: 10px;
			text-align: center;
			display: flex;
			width: 100%;
			justify-content: space-around;

			.num {
				margin-bottom: 0px;
				margin-top: 0px;
				font-size: 18px;
				font-weight: 700;
				line-height: 1.5;
				text-transform: none;
				white-space: normal;
				color: $black;
			}

			.title {
				text-transform: none;
				white-space: normal;
				font-size: 0.6875rem;
				color: rgba(52, 49, 76, 0.54);
			}
		}

		.icon-bell {
			font-size: 26px;
			color: #fff;
			background-color: darkgray;
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			padding-left: 5px;
			margin-right: 10px;
		}

		#noti-new {
			text-align: center;
			position: absolute;
			padding-top: 4px;
			right: 5px;
			top: -2px;
			width: 18px;
			height: 18px;
			border-radius: 100%;
			background-color: #e22121;
			color: #fff;
			font-size: 6px;
			font-family: "Montserrat";
			font-weight: 600;
			z-index: 9;
		}
	}

	.tables {
		background-color: #fff;
		border-radius: 8px;
		padding: 1.25rem;
		overflow-y: auto;
		max-height: 500px;

		&::-webkit-scrollbar {
			width: 8px;
			background-color: transparent;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			box-shadow: inset 0 0 5px unset;
			border-radius: 10px;
			height: 80px;
			background-color: transparent;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			opacity: 0.4;
			background-color: $trading-bg;
			border-radius: 3.5px;
			height: 6px;
		}


		.table-item {
			height: 85px;
			border: 1px solid #ccc;
			border-radius: 10px;
			padding: 0;
			margin-top: 10px;

			.total-new-orders {
				color: #000;
				text-align: center;
				font-size: 16px;
				font-weight: bold;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 40%;

				img {
					width: 25px;
					margin-left: 5px;
				}
			}

			.table-body {
				background: #ccc;
				height: 61%;
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 10px;
				padding-top: 2px;
				font-weight: 700;
				color: #000;
				display: flex;
				white-space: nowrap;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				overflow: hidden;
				.table-label {
					font-size: 10px;
				}

				.table-name {
					font-size: 18px;
				}
			}
		}

	}

	.dashboard-loading {
		position: relative;
		min-height: 80px;
	}

	.block-status {
		margin-top: 20px;
		font-size: 12px;

		.dot-status {
			color: #e1dbdb;
			font-size: 10px;
		}

		.text-danger-glow {
			color: #ff4141;
			text-shadow: 0 0 20px #f00, 0 0 30px #f00, 0 0 40px #f00, 0 0 50px #f00, 0 0 60px #f00, 0 0 70px #f00, 0 0 80px #f00;
		}

		.blink {
			animation: blinker 1s cubic-bezier(.5, 0, 1, 1) infinite alternate;
		}

		@keyframes blinker {
			from {
				opacity: 1;
			}

			to {
				opacity: 0;
			}
		}
	}

	.ui.selection.dropdown {
		min-width: unset;
		max-width: 120px;
		width: 120px;
		font-size: 12px;

		.item {
			font-size: 12px;
		}
	}
	.screen{
		.link:hover {
			color: #16A085;
			cursor: pointer;
		}
	}
	.card-2 {
		margin-top: 20px;
		min-height: unset !important;
	}
}
.modal-pos {
	background: white;
    padding: 20px;
    border-radius: 8px;
	&__label {
		font-weight: 600;
		margin-bottom: 10px;
		font-size: 16px;
	}
	a {
		color: #3232f1;
		text-decoration: underline;
	}
}
.popup-ana {
	width: 500px;
}

.template-title {
	font-weight: bold;
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
}

.template-item {
	background-color: $white;
	display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    margin-bottom: 10px;
    min-height: 100px;
    font-weight: bold;
	cursor: pointer;
}