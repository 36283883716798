.header-container {
  display: flex;
  align-items: center;
  background-color: $heading;
  position: relative;
  z-index: 9998;
  height: 80px;
  transition: all 0.3s ease-in-out;
  &.header-landing {
    padding: 0 20px;
  }
  .mega-container {
    top: calc(80px + 38px);
  }
  .container-category {
    top: 80px;
  }
  &.header-sticky {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background-color: rgba($color: $heading, $alpha: 0.8);
    z-index: 99999;
    height: 50px;
    .mega-container {
      top: calc(50px + 38px);
    }
    .container-category {
      top: 50px;
    }
  }
  .header-landing {
    align-items: stretch;
    height: 70px;
    transition: all 0.3s ease-in-out;
    .navbar-menu {
      height: 100%;
      align-items: center;
      .menu-item {
        position: relative;
        height: 100%;
        .nav-link {
          height: 100%;
          display: flex;
          align-items: center;
          .icon-down {
            width: 8px;
            margin-left: 5px;
          }
        }
        &:hover {
          .dropdown-navbar {
            visibility: visible;
            opacity: 1;
            top: 100%;
          }
        }
        .dropdown-navbar {
          position: absolute;
          right: 0;
          top: 80%;
          z-index: 9999;
          min-width: 200px;
          background-color: $heading;
          visibility: hidden;
          opacity: 0;
          transition: top 0.3s ease-in-out;
        }
      }
    }
  }
  .header-logo {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding-left: 20px;
    .navbar-brand {
      padding: 0;
    }
    .toggle-menu {
      font-size: 18px;
      cursor: pointer;
      &.icon {
        height: auto;
      }
    }
    .image-logo {
      height: 36px;
      width: 130px;
      object-fit: contain;
    }
  }
  .search-box {
    position: fixed;
    left: 0;
    top: 60px;
    right: 0;
    width: 100%;
    display: flex;
    align-items: stretch;
    flex-wrap: nowrap;
    z-index: 9999;
    background-color: $heading;
    border-top: 1px solid #979797;
    padding: 20px;
    visibility: hidden;
    opacity: 0;
    &.search-box-desktop {
      visibility: visible;
      opacity: 1;
      display: flex;
      align-items: stretch;
      flex-wrap: nowrap;
      z-index: 9999;
      position: relative;
      background-color: transparent;
      border: none;
      top: 0;
      width: 400px;
      padding: 0 20px;
      .search-field {
        .search-input {
          min-width: 130px;
        }
      }
    }
    .search-checkbox {
      display: none;
    }
    .search-dropdown {
      position: relative;
      background-color: $white;
      border-right: 1px solid $border-bussiness;
      border-radius: 20px 0 0 20px;
      .default-option {
        min-width: 120px;
        text-align: center;
        cursor: pointer;
        height: 100%;
        align-items: center;
        display: flex;
        justify-content: space-between;
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 13px;
        color: $dark;
        letter-spacing: 0;
        padding: 0 16px;
        img {
          &::selection {
            background-color: transparent;
          }
        }
        &::selection {
          background-color: transparent;
        }
      }
      #search-dropdown:checked ~ .list-dropdown {
        visibility: visible;
        opacity: 1;
        top: 103%;
        transition: all 0.3s ease-in-out;
      }
      #search-dropdown-responsive:checked ~ .list-dropdown {
        visibility: visible;
        opacity: 1;
        top: 103%;
        transition: all 0.3s ease-in-out;
      }
      .list-dropdown {
        position: absolute;
        top: 80%;
        left: 0;
        background-color: $white;
        width: 100%;
        text-align: center;
        visibility: hidden;
        opacity: 0;
        .item-dropdown {
          padding: 5px;
          font-family: "Montserrat";
          font-weight: 600;
          font-size: 13px;
          color: $dark;
          cursor: pointer;
          &::selection {
            background-color: transparent;
          }
        }
      }
    }
    .search-field {
      position: relative;
      width: 100%;
      .search-input {
        background-color: $white;
        padding: 8px 16px;
        outline: none;
        border: none;
        font-family: "Montserrat";
        font-weight: 300;
        font-size: 14px;
        letter-spacing: 0;
        color: $dark;
        border-radius: 0px 20px 20px 0px;
        width: 100%;
      }
      .icon-search {
        position: absolute;
        top: 10px;
        right: 12px;
        cursor: pointer;
        width: 20px;
      }
    }
  }
  .menu-desktop {
    display: flex;
    margin-left: auto;
    height: 100%;
    .navbar-menu {
      display: flex;
      align-items: center;
      height: 100%;
      &.navbar-authenticated {
        > .menu-item {
          margin: 0 5px;
          &:last-child {
            margin-right: 0;
          }
          &.menu-last {
            margin: 0 10px;
          }
          &.bell-item {
            width: 26px;
            max-width: 26px;
            div {
              color: #fff;
              font-size: 23px;
            }
          }
          > .nav-link > .nav-link {
            font-size: 13px;
            font-weight: 500;
          }
        }
      }
      .menu-item {
        position: relative;
        height: 100%;
        align-self: stretch;
        display: flex;
        align-items: center;
        .menu-item {
          &:hover {
            .dropdown-navbar-children {
              visibility: visible;
              opacity: 1;
            }
          }
        }
        .dropdown-navbar-children {
          position: absolute;
          left: 100%;
          top: 0;
          background-color: $heading;
          visibility: hidden;
          opacity: 0;
          transition: top 0.3s ease-in-out;
          z-index: 99;
          .nav-link {
            position: relative;
            font-size: 12px;
            font-weight: 500;
            text-transform: capitalize;
          }
        }
        > .nav-link {
          position: relative;
          font-size: 13px;
          font-family: "Montserrat";
          font-weight: 600;
          color: $white;
          text-decoration: none;
          white-space: nowrap;
          text-transform: uppercase;
          height: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;
          .icon-down {
            width: 8px;
            margin-left: 5px;
          }
          &:hover {
            .dropdown-navbar {
              visibility: visible;
              opacity: 1;
            }
          }
          .dropdown-navbar {
            position: absolute;
            right: 0;
            z-index: 9999;
            background-color: $heading;
            visibility: hidden;
            min-width: 150px;
            opacity: 0;
            transition: top 0.3s ease-in-out;
            top: 100%;
            .nav-link {
              width: 100%;
              font-size: 13px;
              font-family: "Montserrat";
              text-transform: capitalize;
            }
          }
        }
        .build-shop {
          align-self: center;
          padding-right: 20px;
        }
        .btn-build {
          background-color: $primary;
          border: none;
          border-radius: 20px;
          font-family: "Montserrat";
          font-weight: 500;
          text-align: center;
          font-size: 13px;
          color: $white;
          padding: 10px 18px;
          outline: none;
          min-width: 140px;
          max-height: 40px;
          margin-left: 0px !important;
          cursor: pointer;
          white-space: nowrap;
          &:hover {
            background-color: $primary;
          }
        }
        .profile {
          cursor: pointer;
          height: 100%;
          display: flex;
          align-items: center;
          position: relative;
          padding-right: 20px;
          &:hover {
            .dropdown-profiles {
              visibility: visible;
              opacity: 1;
            }
          }
          .dropdown-profiles {
            position: absolute;
            background-color: $heading;
            top: 100%;
            right: 0px;
            min-width: 130px;
            z-index: 99;
            visibility: hidden;
            opacity: 0;
            transition: all 0.3s ease-in-out;
            .item {
              padding: 10px;
              width: 100%;
              display: flex;
              cursor: pointer;
              align-items: center;
              &:hover {
                color: $primary;
                .norm {
                  color: $primary;
                }
              }
              &:not(:first-child) {
                border-top: 1px solid
                  rgba($color: $border-bussiness, $alpha: 0.4);
              }
              .norm {
                font-family: "Montserrat";
                font-weight: 500;
                color: $white;
                font-size: 14px;
                text-transform: capitalize;
              }
              .icon {
                padding-right: 16px;
              }
            }
          }
          .profile-name {
            white-space: nowrap;
            font-size: 13px;
            font-family: "Montserrat";
            font-weight: 600;
            color: $white;
          }
          .icon-down {
            width: 10px;
          }
        }
        .build-cart {
          position: relative;
          img {
            cursor: pointer;
          }
          .cart-animation {
            animation: scale_cart 0.7s;
            animation-direction: alternate;
          }
          .cart-total {
            position: absolute;
            right: -10px;
            top: -8px;
            width: 18px;
            height: 18px;
            border-radius: 100%;
            background-color: $danger;
            color: $white;
            font-size: 10px;
            font-family: "Montserrat";
            font-weight: 600;
            z-index: 9;
            .number {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
        .notifications {
          .noti-new {
            text-align: center;
            padding-top: 1px;
            top: -2px;
            right: -5px;
            font-size: 12px;
          }
        }
        .icon-search {
          width: 25px;
          cursor: pointer;
        }
        .type-active span {
          padding: 5px 10px;
          background: #fff;
          color: #131c33;
          border-radius: 8px;
        }
        .ui.selection.dropdown {
          min-width: unset;
          max-width: 81.4px;
          width: 81.4px;
          font-size: 11px;
          .item {
            font-size: 11px;
          }
        }
      }
    }
  }
  .notifications {
    .noti-order {
      cursor: pointer;
      position: relative;
    }
    .noti-list {
      div {
        color: $black !important;
        font-size: 12px !important;
      }
      font-size: 12px;
      position: absolute;
      padding: 10px;
      right: -50px;
      background: #fff;
      border-radius: 10px;
      box-shadow: grey 0px 7px 29px 0px;
      max-height: 350px;
      width: 250px;
      max-width: 250px;
      overflow-y: auto;
      z-index: 999999;
      &::-webkit-scrollbar {
        width: 8px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px unset;
        border-radius: 10px;
        height: 80px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        opacity: 0.4;
        background-color: $scrollbars;
        border-radius: 3.5px;
        height: 6px;
      }
      .unread {
        background-color: rgba(217, 228, 255, 0.8);
        border-bottom: 1px solid #d0caca;
        &:last-child {
          border-bottom: none;
        }
      }
      .noti-list__item {
        padding: 5px 10px;
        color: #000;
        .noti-list__item-content {
          color: #028ab8;
          cursor: pointer;
          margin-bottom: 0;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          /* autoprefixer: off */
          .dot {
            height: 10px;
            width: 10px;
            margin-right: 5px;
            background-color: #7ba0ff;
            border-radius: 50%;
            display: inline-block;
          }
        }
        .noti-list__item-date {
          font-size: 10px;
        }
      }
    }
  }
  .nav-responsive {
    position: fixed;
    left: 0;
    top: 80px;
    right: 0;
    height: 0vh;
    z-index: 999;
    background-color: $heading;
    border-top: 1px solid #979797;
    visibility: 0;
    opacity: 0;
    display: none;
    .nav-item {
      &:not(:first-child) {
        .nav-link {
          border-top: 1px solid #979797;
        }
      }
    }
    .nav-link {
      position: relative;
      font-size: 13px;
      font-family: "Montserrat";
      font-weight: 600;
      color: $white;
      text-decoration: none;
      padding: 10px 20px;
      text-transform: uppercase;
      .icon {
        padding-right: 16px;
      }
      &:hover {
        color: $primary;
      }
    }
    .dropdown-language {
      margin: 10px 0;
      margin-left: 20px;
    }
    .btn-build {
      background-color: $primary;
      border: none;
      border-radius: 20px;
      font-family: "Montserrat";
      font-weight: 500;
      text-align: center;
      font-size: 13px;
      color: $white;
      padding: 10px 18px;
      outline: none;
      min-width: 140px;
      max-height: 40px;
      margin-left: 20px;
      cursor: pointer;
      white-space: nowrap;
      &:hover {
        background-color: $primary;
      }
    }
  }
  .menu-responsive {
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
    display: none;
    list-style-type: none;
    .menu-item {
      padding: 0 10px;
      .icon-search {
        width: 25px;
        cursor: pointer;
      }
    }
    .build-cart {
      position: relative;
      align-self: center;
      .cart-total {
        position: absolute;
        right: -10px;
        top: -8px;
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        background-color: $danger;
        color: $white;
        font-size: 10px;
        font-family: "Montserrat";
        font-weight: 600;
      }
    }
    .notifications {
      .noti-new {
        top: -4px;
        font-size: 10px;
        padding-top: 0px;
      }
    }
    .checkbox-search,
    .menu-btn {
      cursor: pointer;
      display: none;
    }
    #search-menu:checked ~ .search-box {
      visibility: visible;
      opacity: 1;
      top: 50px;
      transition: top 0.3s ease-in-out;
    }
    #menu-btn:checked {
      ~ .menu-icon {
        cursor: pointer;
        .icon {
          transform: rotate(180deg);
          transition: transform 0.3s ease-in-out;
        }
      }
    }
    #menu-btn:checked ~ .nav-responsive {
      visibility: visible;
      opacity: 1;
      transition: height 0.3s ease-in-out;
      height: 100vh;
      display: block;
      list-style-type: none;
    }
  }
  .overlay {
    height: 100%;
    width: 100%;
    display: none;
    position: fixed;
    z-index: 999999999;
    top: 0;
    left: 0;
  }
  .overlay-content {
    text-align: center;
    margin: auto;
    .block-search {
      display: flex;
      background-color: transparent;
      top: 0;
      width: 548px;
      margin: 0 auto;
    }
    .search-title {
      font-size: 2.63888889vw;
      margin-bottom: 20px;
      line-height: 1.2;
      text-align: center;
      color: #fff;
    }
    .search-checkbox {
      display: none;
    }
    .drop-exists {
      border-radius: 0px 20px 0px 0px !important;
    }
    .search-dropdown {
      position: relative;
      background-color: $white;
      border-right: 1px solid $border-bussiness;
      border-radius: 0px 20px 20px 0px;
      .default-option {
        min-width: 120px;
        text-align: center;
        cursor: pointer;
        height: 100%;
        align-items: center;
        display: flex;
        justify-content: space-between;
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 13px;
        color: $dark;
        letter-spacing: 0;
        padding: 0 16px;
        img {
          &::selection {
            background-color: transparent;
          }
        }
        &::selection {
          background-color: transparent;
        }
      }
      #search-dropdown:checked ~ .list-dropdown {
        visibility: visible;
        opacity: 1;
        top: 103%;
        transition: all 0.3s ease-in-out;
      }
      #search-dropdown-responsive:checked ~ .list-dropdown {
        visibility: visible;
        opacity: 1;
        top: 103%;
        transition: all 0.3s ease-in-out;
      }
      .list-dropdown {
        position: absolute;
        top: 80%;
        left: 0;
        background-color: $white;
        width: 100%;
        text-align: center;
        visibility: hidden;
        opacity: 0;
        .item-dropdown {
          padding: 5px;
          font-family: "Montserrat";
          font-weight: 600;
          font-size: 13px;
          color: $dark;
          cursor: pointer;
          &::selection {
            background-color: transparent;
          }
        }
      }
    }
    .search-field {
      width: 100%;
      position: relative;
      .icon-search {
        position: absolute;
        left: 15px;
        top: 8px;
        width: 20px;
      }
      input[type="text"] {
        background-color: #fff;
        border-radius: 20px 0 0 20px;
        padding: 8px 40px;
        outline: none;
        border: none;
        font-family: "Montserrat";
        font-weight: 300;
        font-size: 14px;
        letter-spacing: 0;
        color: #000;
        width: 100%;
      }
      .exists {
        border-radius: 20px 0 0 0 !important;
      }
    }
    .search-list {
      width: 548px;
      margin: 0 auto;
      background-color: white;
      border-radius: 0 0 20px 20px;
      display: flex;
      flex-direction: column;
      border-top: 1px solid #ccc;
      padding: 10px 0;
      max-height: 350px;
      overflow: auto;
      font-size: 17px;
      .search-item {
        padding: 5px 0 5px 20px;
        .search-business {
          cursor: pointer;
          display: flex;
          text-align: left;
          .box-active {
            width: 10px;
            height: 10px;
            background-color: $blue;
            border-radius: 100%;
            position: absolute;
            bottom: 5px;
            right: 5px;
          }
          .box-inactive {
            width: 15px;
            height: 15px;
            background-color: #b7b7b7;
            border-radius: 100%;
            position: absolute;
            top: -5px;
            left: -5px;
          }
          &:hover {
            background-color: #ebebeb;
          }
          &__image {
            position: relative;
            img {
              width: 80px;
              object-fit: cover;
            }
          }
          &__info {
            padding-left: 15px;
            .list-star {
              font-size: 13px;
              margin-left: -2px;
            }
            &-name {
              color: $black;
              font-weight: 600;
              font-size: 14px;
            }
            &-addr {
              font-weight: 400;
              font-size: 12px;
              color: grey;
            }
          }
        }
        .search-products {
          padding-left: 80px;
          .sub-item {
            a {
              color: $black;
              display: flex;
              .item-image {
                width: 40px;
                height: 40px;
                object-fit: cover;
              }
              .item-info {
                text-align: left;
                padding-left: 10px;
                .item-name {
                  margin: 0;
                  font-size: 12px;
                }
                .adds-price {
                  line-height: unset;
                  font-size: 10px;
                }
              }
            }
          }
        }
      }
    }
  }

  .overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
    cursor: pointer;
    color: white;
  }

  .block-search-mobile {
    display: none !important;
    @include breakpoint(768) {
      display: flex !important;
    }
  }
  .nav-search-content {
    @include breakpoint(768) {
      display: none;
    }
    .search-title {
      display: none;
    }
    .closebtn {
      display: none;
    }
    .overlay {
      background-color: transparent;
      height: auto;
      width: auto;
      display: block;
      position: static;
    }
    .overlay-content {
      width: auto;
    }
    .block-search {
      width: 100%;
    }
    .search-dropdown .default-option {
      min-width: 100px;
    }
    .search-content {
      background-color: #fff;
      border-radius: 8px;
      padding: 10px;
      position: absolute;
      min-width: 100%;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      min-height: 200px;
    }
    .search-field .exists {
      border-radius: 20px 0 0 20px !important;
    }
    .search-dropdown {
      border-radius: 0px 20px 20px 0px !important;
    }
  }
}
.header-sp {
  display: none;
}
@media (min-width: 993px) {
  .overlay {
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.9);
  }
  .overlay-content {
    top: 25%;
    position: relative;
    margin-top: 30px;
    width: 50%;
  }
}
.search-icon {
  display: none;
}
@include breakpoint(992) {
  .overlay {
    background-color: $white;
    .search-title,
    .closebtn {
      display: none;
    }
    .block-search {
      margin-top: 5px !important;
      width: 100% !important;
      .search-icon {
        display: block;
        width: 5%;
        font-size: 25px;
        padding-right: 5px;
        margin-left: 5px;
      }
      .search-field {
        width: 70%;
        input[type="text"] {
          background-color: #f7f7f7 !important;
          padding: 8px 0px 8px 40px;
        }
      }
      .drop-exists {
        border-radius: 0 20px 0px 0px !important;
      }
      .search-dropdown {
        width: 20%;
        .default-option {
          min-width: 85px;
          background: #f7f7f7 !important;
          font-size: 10px;
          border-radius: 0 20px 20px 0px;
          padding: 0 5px;
          img {
            width: 8px;
            margin-left: 3px;
          }
        }
        .list-dropdown {
          .item-dropdown {
            font-size: 10px;
            padding: 10px 5px;
          }
        }
      }
    }
    .search-list {
      max-height: 95vh !important;
      border-top: none !important;
    }
  }
  .header-sp {
    display: flex;
    align-items: center;
    background-color: #131c33;
    position: relative;
    z-index: 2;
    height: 30px;
    transition: all 0.3s ease-in-out;
    .navbar-menu {
      display: flex;
      color: $white;
      width: 100%;
      max-height: 30px;
      height: 30px;
      .menu-item {
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        .nav-link {
          width: 100%;
          text-align: center;
          &.type-active {
            text-decoration: underline;
          }
        }
        .ui.selection.dropdown {
          min-width: unset !important;
          height: 30px;
          max-height: 30px;
          box-sizing: border-box;
          border-radius: 0;
          width: 100%;
          margin: 0 !important;
          padding: 9px 0 0px 5px;
          font-size: 11px;
          font-weight: 500;
          outline: none;
          .item {
            font-size: 11px;
          }
        }
      }
    }
    &.header-sticky {
      position: fixed;
      top: 49.9px;
      right: 0;
      left: 0;
      background-color: rgba($color: $heading, $alpha: 0.8);
      z-index: 2;
      height: 30px;
      .ui.selection.dropdown {
        background-color: $white;
        color: $black;
        .text {
          color: $black !important;
        }
        .transition {
          left: 0.5px;
          background-color: $white !important;
          width: calc(100% + 1px) !important;
          .item {
            color: $black;
          }
        }
      }
    }
  }
}
@keyframes scale_cart {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.5);
  }

  50% {
    transform: scale(1.6);
  }

  75% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}
